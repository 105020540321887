.imageResizerBackground {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  /* height: 100vh; */
  /*** safari browser footer hide bottom ***/
  min-height: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.imageResizerBackground.back-white {
  background-color: rgba(254, 254, 254, 0.6);
}

.imageResizerContainer {
  background-color: white;
  border-radius: 10px;
  margin-left: 25%;
  margin-top: 2.5%;
  width: 40vw;
  display: flex;
  flex-direction: column;
}

.imageResizer {
  width: 98%;
  height: auto;
  margin-top: 1%;
  margin-left: 1%;
  position: relative;
}

.imageResizer img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.imageResizer canvas {
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
}

.imageResizerButtonsContainer {
  display: flex;
  flex-direction: row;
  direction: rtl;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

#main > .imageResizerBackground ~ div {
  filter: blur(3px);
}

@media (max-width: 488px) {
  .imageResizerBackground {
    background-color: rgb(249, 249, 249);
  }
}
