$max-width: 1020px;
$primary-color: #00bcd4;
$background-gradient: transparent
  linear-gradient(81deg, #1578fb 0%, #17ead7 100%) 0% 0% no-repeat padding-box;
.lwrapper-global {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    //display: none;
    width: 10px;
  }
}
.link-expert-main-container {
  img {
    max-width: none !important;
  }
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
  .features-descr-img-container {
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 1020px;
    position: relative;
  }
  .feature-item-descr {
    display: flex;
    transition: visible 0.2s;
    position: absolute;
    top: 5px;
    left: 5px;
    max-width: 400px;
    visibility: hidden;
    font: normal normal medium 12px/20px Montserrat;
    margin: 20px auto;
    padding: 20px;
  }
  .link-expert-container {
    .expert-oval-image {
      object-fit: cover;
      height: 500px;
      width: 500px;
      max-width: auto !important;
      margin-left: -274px;
      margin-top: -274px;
    }
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    &.no-mt {
      margin-top: 0px;
    }
    &.reverse {
      flex-direction: row-reverse;
    }
    &.column {
      flex-direction: column;
    }
    z-index: 20;
    max-width: $max-width;
    width: 100%;
    align-self: center;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: start;
    margin-top: -100px;
    &.with-mt-small {
      margin-top: 5px;
    }
    &.mt-120px {
      margin-top: 120px;
    }
    &.simplicity {
      margin-top: 80px;
      background: transparent
        url('../../../resources/images/link-expert-simplicity-bg.svg') 0% 0%
        no-repeat;
      min-height: 165px;
      object-fit: contain;
      justify-content: center;
      align-items: center;
    }
    &.features {
      margin: 200px auto auto;
      background: transparent
        url('../../../resources/images/link-expert-features-bg.svg') 0% 0%
        no-repeat;
      max-width: 950px;
      min-height: 165px;
      justify-content: center;
      align-items: center;
    }
    &.no-wrap-reverse {
      flex-wrap: wrap;
    }
    &.unfold-science {
      margin-top: -1000px;
    }
    &.unfold-joint {
      margin-top: -900px;
    }
    &.unfold-bottom {
      margin-top: -700px;
      position: absolute;
    }
    .details-container {
      display: flex;
      &.with-ml {
        margin-left: 20px;
      }
      &.features {
        margin-top: -80px;
      }
      &.with-mt-large {
        margin-top: 145px;
      }
      &.fixed-200 {
        min-width: 150px;
      }
      &.full {
        width: 100%;
      }
      &.map-image-list {
        max-width: 175px;
        justify-content: center;
      }
      flex: 1;
      justify-content: center;
      flex-direction: column;
      .center {
        text-align: center;
        align-items: center;
        justify-content: center;
      }
      .ctas-container {
        display: flex;
        flex-direction: row;
        &.with-mt {
          margin-top: 50px;
        }
        justify-content: space-between;
        max-width: 500px;
        align-items: center;
        margin-top: 20px;
      }
      .title {
        font-size: 40px;
        max-width: 650px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 30px;
        &.joint {
          font-size: 40px;
          text-align: center;
          max-width: 100%;
        }
        &.expert-mission {
          max-width: 730px;
        }
        &.model {
          max-width: 815px;
        }
        &.join {
          font-size: 40px;
        }
        &.center {
          text-align: center;
        }
        &.full {
          max-width: 100%;
        }
        &.no-mb {
          margin-bottom: 0px;
        }
        &.capitalize {
          text-transform: capitalize;
        }
        &.with-mb-small {
          margin-bottom: 10px;
        }
        .subtitle {
          font-weight: 100;
        }
        &.max-w-large {
          max-width: fit-content;
        }
        &.great-stages {
          font-size: 40px;
          margin-bottom: 10px;
        }
      }
      .note {
        text-align: left;
        font: normal normal 300 14px/25px Montserrat;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        margin-bottom: 50px;
      }
      .note.small-margin {
        margin-bottom: 20px;
      }
      .title-small {
        font-size: 16px;
        font-weight: 700;
        color: #333333;

        &.with-mb {
          margin-bottom: 15px;
          &.small {
            margin-bottom: 5px;
          }
        }
        &.with-mt {
          margin-top: 46px;
        }
        &.great-stages {
          font-size: 14px;
        }
      }

      .details-list {
        display: flex;
        flex-direction: column;
        .map-image-item {
          img {
            width: 174px;
            height: auto;
            object-fit: contain;
            margin-bottom: 30px;
          }
        }
        &.row {
          flex-direction: row;
          flex-wrap: wrap;
          &.features {
            flex-wrap: nowrap;
            overflow: hidden;
          }
          justify-content: space-between;
          margin-bottom: 30px;
        }

        .advantage-item {
          display: flex;
          flex-direction: column;
          padding: 10px;
          min-height: 268px;
          max-width: 221px;
          align-items: center;
          img {
            width: 80px;
            object-fit: contain;
            height: 75px;
          }
          .advantage-item-title {
            margin-top: 25px;
            text-align: center;
            font: normal normal 600 18px/22px Montserrat;
            letter-spacing: 2.38px;
            color: #333333;
            opacity: 1;
          }
          .advantage-item-subtitle {
            margin-top: 12px;
            text-align: left;
            font: normal normal bold 12px/22px Montserrat;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
          }
          .advantage-item-detail {
            margin-top: 17px;
            text-align: left;
            font: normal normal 300 11px/22px Montserrat;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
          }
        }

        .feature-item {
          display: flex;
          flex-direction: column;
          max-height: 120px;
          max-width: 170px;
          align-items: center;
          img {
            width: 60px;
            object-fit: cover;
            height: 60px;
          }
          .feature-item-title {
            margin-top: 15px;
            text-align: center;
            font: normal normal bold 15px/20px Montserrat;
            letter-spacing: -0.6px;
            color: #333333;
            opacity: 1;
          }
          .feature-item-subtitle {
            text-align: center;
            font: normal normal medium 12px/20px Montserrat;
            letter-spacing: -0.48px;
            color: #333333;
          }
        }
        .item {
          display: flex;
          &.with-mb {
            margin-bottom: 19px;
          }
          flex-direction: row;
          align-items: flex-start;
          .icon {
            margin-right: 10px;
            img {
              width: 15px;
              height: 15px;
            }
          }
          .detail {
            font-size: 18px;
            font-weight: 100;
            color: #333333;
            margin-right: 10px;
            flex: 1;
          }
        }
      }
      .categories {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .category-item:hover .category-nonHover {
          display: none;
        }
        .category-item .category-descrOnHover {
          display: none;
          font-size: 12px;
          padding: 20px 30px;
        }
        .category-item:hover .category-descrOnHover {
          display: flex;
        }
        .category-item {
          position: relative;
          background-color: #fff;
          border-radius: 20px;
          width: 100%;
          max-width: 285px;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 59px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          img {
            width: 105%;
            height: 170px;
            margin-top: -5px;
            object-fit: cover;
          }
          .category-title-container {
            width: 100%;
            min-height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .category-title {
              font-size: 16px;
              text-align: center;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .cta-btn {
        display: flex;
        cursor: pointer;
        &.secondary {
          color: #59c1e5;
          border: 1px solid #59c1e5;
          background: transparent;
        }
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        min-height: 40px;
        background: $background-gradient;
        border-radius: 25px;
        text-align: center;
        min-width: 40px;
        width: 240px;
        color: #ffffff;
      }
      .complete-form {
        margin-top: 10px;
      }
    }
    .detail-image {
      margin-bottom: 30px;
      &.with-mr {
        margin-right: 60px;
      }
      img {
        &.map {
          max-width: 735px !important;
          max-height: none !important;
          margin-right: 10px;
        }
        max-width: 487px !important;
        &.model {
          max-width: 433px !important;
          max-height: 433px !important;
        }
        width: 100%;
        max-height: 487px;
      }
    }
  }
  .corner-image-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
    overflow: hidden;
    margin-top: -340px;
    &.deep {
      margin-top: -450px;
    }
    &.bottom-image {
      margin-top: 0px;
      margin-bottom: 0px;
      object-fit: contain;
      height: 600px;
    }

    .corner-image {
      &.bottom-image {
        margin-right: -180px;
      }
      margin-right: -174px;
      &.deep {
        margin-right: -200px;
      }
      height: 1468px;
      width: 465px;
    }
  }
}
@media screen and (max-width: 1100px) {
  .link-expert-main-container {
    .content-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .link-expert-main-container {
    .link-expert-container {
      .expert-oval-image {
        height: 300px;
        width: 300px;
        margin-left: -150px;
        margin-top: -150px;
      }
    }
    .content-container {
      .details-container {
        .cta-btn {
          margin-bottom: 10px;
        }
        .ctas-container {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        .title {
          text-align: center;
        }
        .details-list {
          &.row {
            justify-content: center;
          }
        }
      }
      .categories {
        justify-content: center !important;
      }
      .detail-image {
        &.with-mr {
          margin-right: 0px;
        }
      }
    }
  }
}
