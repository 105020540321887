/* - - - - - MAING GRID SYSTEM - - - - - -  */

.ctnr {
    /* width: 1024px; */
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 10px;
    padding-right: 10px; */
}

@media (min-width: 1200px) {
    .ctnr {
        /* width: 1110px; */
    }
}

.rw {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;
    /* margin-left: -10px;
    margin-right: -10px; */
}

.cl-1,
.cl-2,
.cl-3,
.cl-4,
.cl-5,
.cl-6,
.cl-7,
.cl-8,
.cl-9,
.cl-10,
.cl-11,
.cl-12 {
    min-height: 1px;
    /* padding-left:10px;
    padding-right: 10px; */
    position: relative;
    flex: 70%;
}

.cl-12 {
    width: 100%;
}

.cl-11 {
    width: 91.66666667%;
}

.cl-10 {
    width: 83.33333333%;
}

.cl-9 {
    width: 75%;
}

.cl-8 {
    width: 66.66666667%;
}

.cl-7 {
    width: 58.33333333%;
}

.cl-6 {
    width: 50%;
}

.cl-5 {
    width: 41.66666667%;
}

.cl-4 {
    width: 33.33333333%;
}

.cl-3 {
    width: 25%;
}

.cl-2 {
    width: 16.66666667%;
}

.cl-1 {
    width: 8.33333333%;
}

@media (max-width: 991px) {
    .ctnr {
        width: 100%;
    }
}