:root {
  --main-color: rgb(57, 180, 191);
}

body {
  margin: 0;
  padding: 0;
  /*background-color: rgb(245, 245, 245);*/
}

.topBar {
  width: 100%;
  height: 50px;
  background-color: var(--main-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}

.profileBox {
  width: 200px;
  height: 300px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  top: 60px;
  left: 30px;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, .125);
  border-width: 1px;
  border-style: solid;
  z-index: 999;
}

.profilePicContainer {
  width: 100%;
  text-align: center;
}

.profilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-color: var(--main-color);
  border-width: 5px;
  border-style: solid;
}


.feed {
  border-radius: 10px;
  border-color: rgba(0, 0, 0, .125);
  border-width: 1px;
  border-style: solid;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.feedAuthor {
  font-family: calibri;
  font-size: 20px;
  color: var(--main-color);
}

.feedContent {
  font-family: calibri;
  font-size: 16px;
  color: black;
  margin-top: 10px;
  display: inline-block;
}



.feedPicture {
  width: 100%;
  height: auto;
}

.feedPicture img,
.feedPicture > span {
  display: block;
  height: auto;
  width: 100%;
}

.postButtons {
  display: flex;
  flex-direction: row;
}

.genericDialog {
  border-radius: 5px;
  background-color: white;
  /* margin: auto; */
  /* width: 25%; */
  display: flex;
  /* margin-top: 20%; */
  flex-direction: column;
}

.genericDialog span {
  margin: 10px;
}

.postRightButtons .error-msg {
  margin: 0 10px 0 0;
}


.profilBackground {
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.profilInfoDialog {
  border-radius: 10px;
  border-color: var(--main-color);
  border-width: 1px;
  border-style: solid;
  background-color: white;
  padding: 5px;
  margin: auto;
  width: 40%;
  display: flex;
  margin-top: 5%;
  flex-direction: column;
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 250px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  padding:0 !important;
}
.dropdown-MenuProfil{
  margin-top: 100px;
  position: absolute;
  background-color: #ffffff;
  min-width: 250px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

}

.training-creatable-select div[class*="-multiValue"]{
  display: none;
}