[class*='m-hide_'] {
  display: initial;
}

.sm-show,
[class*='m-show_'] {
  display: none;
}

/* Header mobile max-width=991 */
@media screen and (max-width: 1280px) {
  .landing .tab-standard-header .blc-tab-title {
    margin-left: 20px;
    margin-right: 20px;
  }

  .annonce-form .tab-standard-header .blc-tab-title {
    width: 100%;
  }

  .list-invitation .blc-contact-item {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1245px) {
  .gbl-header .usr-info .user-info {
    max-width: 160px;
  }
}

@media screen and (max-width: 1000px) {
  .main-nav .nav .nav-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1024px) {

  /** --- global --- **/
  .m-show_1024 {
    display: initial;
  }

  .m-hide_1024 {
    display: none;
  }

  /** --- end global --- **/

  .blc-profil-info {
    /* margin-left:50px;
        margin-right:50px; */
  }

  .profil-page .profil-info-center,
  .profil-enterprise .wrap-left-container.entreprise {
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }

  .blc-profil-actions button:nth-of-type(1) {
    /* padding: 0 14px; */
  }

  .search-wrap .search-content {
    width: 100%;
    position: relative;
    margin: 0;
  }

  .wall-right,
  .wall-right-spec .lprofil-PM {
    display: none;
  }

  .wall .wall-center {
    margin-right: 20px;
    width: calc(100% - 345px);
  }

  .wall .detailBrevet .wall-center {
    width: 100%;
  }

  .blc-dashboard-right .wall-right,
  .wall-right-spec.wall-right {
    display: block;
    margin-left: 0;
    width: 100%;
  }

  .dashboard .blc-dashboard-right {
    margin: 0 10px 0 20px;
  }

  .blc-annonce-right .wall-right-spec.wall-right,
  .dashboard .blc-dashboard-right .wall-right {
    margin: auto;
    width: 285px;
    overflow: hidden;
  }

  .dashboard .blc-dashboard-right .wall-right {
    overflow: unset;
  }

  .wrapper-annonce .blc-annonce-center {
    flex: 0 0 100%;
    width: 100%;
  }

  .wrapper-annonce .container,
  .detailAnnonce .container {
    align-items: center;
    flex-direction: column;
  }

  .detailAnnonce .container {
    top: -125px;
  }

  .detailAnnonce .bloc-annonce-body {
    margin: 0 30px;
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .wall {
    justify-content: center;
  }

  .wall-center {
    width: 580px;
    margin: 0;
  }

  /** ---- relation  ---- **/
  .p-relation .wall-center {
    margin-right: 0;
  }

  .p-relation .wall-right {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .invitation-group .layout-medium {
    margin: auto;
  }

  .detailAnnonce .bloc-annonce-body {
    /* padding-left: 20px !important;
        padding-right: 20px !important; */
  }

  .detailAnnonce .bloc-annonce-header {
    /* height: 140px; */
  }

  /* release note*/
  .releaseNt .tab-standard-header {
    padding: 0 20px;
  }

  /* PROFIL */
  .blc-profil-name .btn-edit button {
    right: -70px;
  }

  .profil-page .profil-info-center,
  .profil-enterprise .wrap-left-container.entreprise {
    margin-left: 30px;
    margin-right: 30px;
  }

  .profil-enterprise .layout-entreprise .layout-medium {
    margin: 0;
  }

  .usr-info::before {
    background-color: rgba(0, 0, 0, 0);
  }

  /* .blc-profil-top .blc-coord li{
        padding-right: 4px;
        overflow: hidden;
    } */

  /* PROFIL PUBLICATION & DETAIL BREVET*/
  /* .blc-medium-item, .blc-small-item{
        width: 100% !important;
    } */
  .brevet-action {
    width: 60px;
  }

  .detailBrevet.blc-brevet {
    margin-top: 15px;
  }

  /* .profil-info-center, .wrap-left-container.entreprise{
        width: 100%;
    } */
  .blc-profil-info {
    margin: 140px 0 0;
    padding: 120px 70px 30px 70px;
    width: auto;
  }

  .blc-menu-group>.put-inline {
    flex-wrap: wrap;
  }

  .blc-menu-group>.put-inline button {
    margin-bottom: 10px;
  }

  .group-view-actu .sub-menu-group .btn {
    margin: 0 8px 10px;
  }

  /* GLOBAL */
  .container {
    padding: 0;
  }

  /* LANDING INSCRIPTION step 1 , 2 , 3*/
  .rowForm.invit {
    display: flex;
    flex-direction: row;
  }

  i.check-step::before {
    bottom: -7px;
    height: 14px;
    left: 14px;
  }

  i.check-step::after {
    bottom: -5px;
    height: 6px;
    left: 8px;
  }

  .lradio-text {
    font-size: 12px;
  }

  .adduser.bkg-gradient-2 {
    background: none;
    border: #59c1e5 solid 2px;
    background-image: linear-gradient(221deg, #fff, #fff) !important;
  }

  .list-step .step-connex .btn.adduser {
    width: 38px;
    height: 24px;
    position: relative;
    padding: 0;
  }

  .list-step .step-connex .btn.adduser span {
    display: none;
  }

  .list-step .step-connex .btn.adduser::before {
    content: '';
    position: absolute;
    display: block;
    height: 8px;
    width: 2px;
    background-color: #59c1e5;
  }

  .list-step .step-connex .btn.adduser::after {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 2px;
    background-color: #59c1e5;
  }

  .signin-submit .container button {
    margin-top: 10px;
    padding: 0 10px;
  }

  .landing .signin-submit {
    height: auto;
  }

  .form-signin-ctnr .rowForm {
    flex-direction: column;
    padding-right: 60px;
    padding-left: 60px;
  }

  .mtmobile {
    height: auto;
    margin: 0 0 30px 0;
    text-align: center;
  }

  .footer-home .mtmobile {
    width: auto;
  }

  .footer-home .footer-list-container.mtmobile {
    width: 95%;
  }

  .footer-home .logo,
  .landing .header .header-top .logo {
    max-width: none;
    width: 105px;
  }

  .btn-short_signin.btn {
    margin-bottom: 10px;
  }

  .landing .header-top .rw {
    align-items: center;
  }

  .landing .tab-standard-header .blc-tab-title {
    margin: 0;
  }

  .landing .blc-content-tab ul {
    margin: 0;
  }

  .landing .blc-content-tab .blc-tab-title ul>li .step-title,
  .landing .blc-content-tab .blc-tab-title ul>li.active .step-title {
    display: none;
  }

  .landing .steps {
    visibility: hidden;
    height: 35px;
    /* display: flex;
        align-items: center; */
  }

  .landing .steps i {
    visibility: visible;
  }

  .landing .steps span {
    visibility: visible;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .landing .blc-content-tab .blc-tab-title ul>li {
    padding: 0 5px;
    line-height: 14px;
    height: 45px;
  }

  .tab-standard-header .blc-tab-title {
    width: 100%;
    padding: 0;
  }

  .must {
    margin: 0 0 20px 0;
  }

  /* FOOTER */
  .footer-home .nav-text {
    font-size: 11px;
  }

  .cl-8.flex-right {
    width: 100%;
  }

  .footer-home .nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .footer-home .nav li {
    /* margin: 5px 10px;
        max-width: 120px; */
    max-width: 200px;
    margin: 5px auto;
    padding: 5px;
  }

  /* CONNEXION */

  .btn-connexion {
    text-transform: capitalize;
    letter-spacing: normal;
  }

  .btn-exit {
    text-transform: capitalize;
    letter-spacing: normal;
  }

  /* HEADER & HOME FEED*/
  .head-btn.active .lpicto-group:after {
    height: 20px;
    width: 20px;
    opacity: 1;
    background: url(./images/group-active-gradient1.svg) no-repeat center;
    background-size: 100%;
  }

  .head-btn .lpicto-notif.active:after {
    height: 20px;
    width: 20px;
    background: url(./images/notif-gris_active-gradient1.svg) no-repeat center !important;
    opacity: 1;
  }

  .head-btn.active .lpicto-chat:after {
    height: 18px;
    width: 18px;
    opacity: 1;
    background: url(./images/ico-chat-active-gradient1.svg) no-repeat center;
    background-size: 100%;
  }

  .main-nav .search-bar .search-form .searchmenu {
    top: 35px;
  }

  .usr-option {
    border: none;
    height: auto;
  }

  .blc-pub-areaico {
    width: 28%;
  }

  .blc-pub-areaico .lpicto-video,
  .blc-pub-areaico .lpicto-picture,
  .blc-pub-areaico .lpicto-file {
    height: 36px;
    border-left: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lpicto-picture::after,
  .lpicto-video::after,
  .lpicto-file::after,
  .lpicto-picture:hover:before,
  .lpicto-video:hover:before,
  .lpicto-file:hover:before,
  .blc-btn-action-publication .btn-icon:after,
  .blc-btn-action-publication .btn-icon:before,
  .feedComposerBackground .blc-pub-areaico .lpicto-video:after {
    height: 16px;
    width: 16px;
    background-size: contain;
  }

  .newsfeed .blc-btn-action-publication .lpicto-like::after {
    height: 18px;
    width: 17px;
  }

  .blc-pub-area {
    height: 36px;
  }

  .blc-pub-areacontent {
    font-size: 10px;
    padding: 8px 0 8px 14px;
    border: none;
    width: 72%;
  }

  .feedComposerBackground .blc-pub-area-action {
    height: 35px;
    padding: 0 16px !important;
    line-height: 31px;
  }

  .blc-pub-area-action span {
    font-size: 10px;
  }

  .feedContainer .feedComposerBackground .blc-pub-areacontent {
    font-size: 11px;
  }

  .feedContainer .feedComposerBackground .blc-pub-area-action span {
    font-size: 11px;
  }

  .feedComposerBackground {
    margin: 20px 0;
    width: auto;
  }

  .gbl-header .usr-info {
    max-width: 40px;
    padding: 5px 0;
  }

  .gbl-header .nav-item.left-item .usr-info {
    width: 30px;
  }

  .header-mobil .main-nav .nav .nav-item {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .header-mobil .main-nav .nav .nav-item::before {
    height: 0;
  }

  .bgl-content {
    position: relative;
    /* top: 28px; */
  }

  .lwrapper-global {
    height: auto;
    /* overflow: hidden; */
  }

  .with_header {
    margin-top: 0px;
  }

  .landing .tab-standard-body .bgl-content {
    top: 0;
  }

  .gbl-header.header-mobil .ctnr {
    padding: 0;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .gbl-header.header-mobil .nav-item a {
    height: 20px;
  }

  .gbl-header.header-mobil .lpicto-announce:after {
    content: '';
    background: url(./images/annonces@3x_drakgray.png) no-repeat;
    display: inline-block;
    background-size: 100%;
    height: 20px;
    width: 25px;
    opacity: 0.57;
  }

  .gbl-header.header-mobil .nav-item.active .lpicto-announce:after {
    background: url(./images/annonces@3x.png) no-repeat;
    background-size: 100%;
    opacity: 1;
  }

  .usr-info-header {
    display: block;
  }

  .header-mobil .rw {
    flex-direction: column;
  }

  .header-mobil .global-header-right {
    width: 100%;
    order: 0;
    flex: 0 0 52px;
    height: 52px;
  }

  .header-mobil .global-header-left {
    width: 100%;
    order: 1;
    flex: 0 0 52px;
    height: 52px;
    justify-content: center;
    border-top: rgba(234, 234, 234, 0.6) 1px solid;
  }

  .header-mobil .global-header-left .menu-cntr,
  .header-mobil .global-header-left .menu-content,
  .header-mobil .global-header-left .main-nav,
  .header-mobil .global-header-left .nav {
    height: 100%;
    width: 100%;
  }

  .header-mobil .nav.goback_active {
    background-color: #f3fbfd;
    position: relative;
  }

  .header-mobil .global-header-left .main-nav .nav-item.left-item {
    width: 11%;
    margin-left: auto;
    flex: none;
  }

  .header-mobil .global-header-left .main-nav .nav-item.right-item {
    margin-right: auto;
    width: 100%;
    max-width: 50%;
  }

  .header-mobil .global-header-left .nav-item.right-item,
  .gbl-header .header-mobil .search-bar {
    position: static;
  }

  .header-mobil .global-header-left .search-bar .Search-itemList {
    margin: 0;
    padding: 0;
    border-top: none;
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 107px);
    left: 0;
    top: 50px;
    box-shadow: none;
  }

  .header-mobil .global-header-left .Search-itemList .search-result {
    margin: 0;
    width: 100%;
    max-height: unset;
    height: calc(100vh - 107px);
  }

  .header-mobil .global-header-left .Search-itemList .search-result>div {
    padding: 0 20px;
  }

  .header-mobil .global-header-left .Search-itemList .search-result>div:first-child .searchmenu-header,
  .header-mobil .global-header-left .Search-itemList .search-result>div:nth-child(2) .searchmenu-header {
    border: none;
  }

  .header-mobil .global-header-left .Search-itemList .search-result .searchmenu-header {
    margin: 0;
    padding: 12px 20px !important;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }

  .header-mobil .header-back-container {
    width: 30px;
    height: 30px;
    position: relative;
  }

  .header-mobil .goback_active .header-back-container {
    width: 12px;
  }

  .header-mobil .goback_active .header-back-container .chevron-left {
    margin: 0;
    position: absolute;
    top: 10px;
    left: 2px;
    width: 10px;
    height: 10px;
    background-size: 100% 100% !important;
  }

  .gbl-header .search-bar {
    flex: 0 0 auto;
  }

  .gbl-header.header-mobil {
    height: auto;
    box-shadow: none;
  }

  .gbl-header .usr-option {
    height: 52px;
    justify-content: space-around;
    width: 100%;
  }

  .gbl-header .logo {
    min-width: unset;
  }

  .global-header-right .put-inline {
    /* height: 100%; */
    width: 100%;
  }

  .global-header-right .nav-item,
  .global-header-right .logo,
  .global-header-right .head-btn {
    width: 15%;
    margin: 0;
    height: 100%;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
  }

  .global-header-right .logo {
    border-bottom: 2px solid #fff;
    position: relative;
  }

  .global-header-right .logo a {
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .global-header-right .logo.active::after {
    content: '';
    display: block;
    height: 2px;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#182da8),
        to(#a007a0));
    background: -webkit-linear-gradient(left, #182da8, #a007a0);
    background: linear-gradient(90deg, #182da8, #a007a0);
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
  }

  .global-header-right .logo img {
    width: auto;
    height: 32px;
  }

  .global-header-right .nav-item {
    border-bottom: 2px solid #fff;
    position: relative;
  }

  .global-header-right .nav-item.active::after {
    content: '';
    display: block;
    height: 2px;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#182da8),
        to(#a007a0));
    background: -webkit-linear-gradient(left, #182da8, #a007a0);
    background: linear-gradient(90deg, #182da8, #a007a0);
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
  }

  .global-header-right .head-btn {
    border-bottom: 2px solid #fff;
    position: relative;
  }

  .global-header-right .head-btn.active::after {
    content: '';
    display: block;
    height: 2px;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#182da8),
        to(#a007a0));
    background: -webkit-linear-gradient(left, #182da8, #a007a0);
    background: linear-gradient(90deg, #182da8, #a007a0);
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .global-header-right .head-btn button.lpicto-notif {
    width: 100%;
    height: 100%;
    /*border-bottom: 2px solid #fff;*/
    position: relative;
  }

  .global-header-right .head-btn button.lpicto-notif.active::before {
    content: '';
    display: block;
    height: 2px;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#182da8),
        to(#a007a0));
    background: -webkit-linear-gradient(left, #182da8, #a007a0);
    background: linear-gradient(90deg, #182da8, #a007a0);
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  /* .global-header-left .main-nav .nav li.nav-item:before{
        background-color: rgba(0, 0, 0, 0);
    } */

  .header-mobil .main-nav .search-bar .search-form {
    height: 30px;
    /*width: 250px;*/
    width: 100%;
    /*max-width: 250px;*/
  }

  .global-header-left .search-form .btn-search {
    height: 30px;
  }

  /* .search-form .lpicto-search.lpicto-black:after {
        top: 2px;
    } */
  .gbl-header .search-form input {
    width: calc(100% - 36px);
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;
  }

  .search-bar input~.btn-search {
    border-radius: 22px;
    padding: 0 10px;
    height: 28px;
    width: 36px;
  }

  .search-bar input~.btn-search .lpicto-search {
    height: 28px;
    position: relative;
  }

  /* .search-bar input:focus ~ .btn-search .lpicto-search{

    } */
  .search-bar input~.btn-search .lpicto-search:after {
    height: 28px;
    width: 13px;
    background-size: 100%;
    background-position: center;
    position: absolute;
    left: 2px;
  }

  .search-bar input:focus~.btn-search {
    height: 28px;
    padding: 0 10px;
    background-image: -webkit-gradient(linear,
        left bottom,
        right top,
        from(#1578fb),
        to(#17ead7)),
      linear-gradient(to right top, #1578fb, #17ead7) !important;
  }

  .search-bar input:focus~.btn-search .lpicto-search {
    height: 28px;
  }

  .search-bar input:focus~.btn-search .lpicto-search:after {
    background-image: url(./images/ico-search-white.svg) !important;
    height: 28px;
    width: 13px;
    background-size: 100%;
    background-position: center;
  }

  /* .nav-item .onFocused .btn-search .lpicto-search{
        right:15px;
     }
    .global-header-left .nav-item .onFocused .lpicto-search:after{ */
  /* height: 40px;
        width: 50px;
        right: -10; */
  /* height: 30px;
        width: 45px;
        right: -15px;
    } */
  /* .global-header-left .lpicto-search.lpicto-black{
        right: 15px;
        top: 20px;
    } */
  .wall .wall-right,
  .wall .wall-left {
    display: none;
  }

  .wall .wall-center {
    width: calc(100% - 40px);
    margin: 20px auto 0 auto;
  }

  /* SEARCH */
  .search-wrap .tab-standard-body {
    margin-top: 85px;
  }

  .search-wrap .tab-standard-header .blc-tab-title {
    max-width: none;
    position: fixed;
    z-index: 100;
    top: 104px;
  }

  /* .lpicto-search.lpicto-black:after{
        right: -2px;
    } */
  /* .search-wrap .search-content{
        width: 100%;
        position: relative;
        margin:0;
    } */
  .search-wrap .blc-content-tab {
    border: none;
  }

  .header-mobil .usr-info .head-img {
    flex: 0 0 30px;
    height: 30px;
  }

  /* .headerPage-left h1.blc-title-newprod {
        margin: 0;
    } */

  /* dashboard */
  .dashboard .container {
    align-items: center;
    flex-direction: column;
  }

  .blc-dashboard-center {
    width: 100%;
  }

  .dashboard .blc-dashboard-right {
    margin: 0;
  }

  .blc-dashboard-right .lprofil-PM {
    display: none;
  }

  /*annonce form */
  .blc-annonce-center .blc-filter-annonce {
    padding: 0 40px;
  }

  .blc-tab-spec-secondary .step-title,
  .blc-tab-spec-secondary .steps>span {
    display: none;
  }

  .detailAnnonce .annonce-body,
  .detailAnnonce .accordion {
    padding: 0 35px;
  }

  .detailAnnonce .annonce-body {
    /* top: -56px; */
  }

  a.btn-goback.goback-spec {
    display: block !important;
    left: 22px;
  }

  .blc-company.form-any-creation a.btn-goback.goback-spec {
    top: 15px;
    left: 15px;
  }

  .flex-direction-mobile {
    align-items: center;
    flex-direction: column;
  }

  /** ---- relation ---- **/
  .p-relation.layout.wall.container {
    flex-direction: row;
    margin-top: 20px;
  }

  .p-relation.wall .wall-center {
    width: calc(100% - 315px);
    margin-left: auto;
    margin-right: auto;
  }

  .p-relation .blc-validate-contact.header-contact-mobile {
    padding-top: 0;
    padding-bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container.p-relation {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
  }

  .p-relation .wall-right {
    display: block;
  }

  /*----*/
  .publish-dialog .feedComposerBackground .blc-pub-area-action {
    height: auto;
    padding: 19px 0 !important;
  }

  .publish-dialog .publish-member .blc-select-container.lselect-secondary {
    min-width: 135px;
  }

  #root.with_header .imageResizerBackground {
    padding: 130px 5px 25px 5px;
  }

  .btn-edit-custom {
    right: -30px;
  }

  /* edition entrprise*/
  .editProfil-page.edit-entreprise .header-page .container {
    padding: 65px 40px;
  }

  .editProfil-page .flex-direction-mobile .lradio {
    flex: 0 0 30px;
  }
}

@media screen and (max-width: 949px) {
  .chat-container main.tchat-center .chat-WrapHeader .group-action {
    height: 64px;
    width: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 949px) {
  .chat-container .tchat-center .group-action {
    position: absolute;
    right: 0;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .chat-container .tchat-center .group-action button.btn {
    height: 100%;
    width: 54px;
    padding: 15px 20px;
  }

  .chat-container .tchat-center .group-action .lpicto-menu:after {
    position: unset;
  }

  .chat-sidebar-overlay .chat-info {
    width: auto;
    overflow: auto;
  }

  .chat-sidebar-overlay .chat-info .btn-goback {
    position: absolute;
    top: 0;
    left: 5px;
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;
  }

  .chat-sidebar-overlay .chat-info .btn-goback .chevron-left {
    width: 10px;
    height: 10px;
    background-size: 10px 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .forget-pass .show-mdp {
    top: 0px;
  }

  /* RELEASE NOTE */

  .releaseNt {
    height: calc(100vh - 113px);
  }

  .releaseNt .accordionForm-body {
    padding: 20px;
  }

  .releaseNt .accordionForm-body .put-inline {
    flex-direction: column;
  }

  .releaseNt .accordionForm-body .item {
    padding-bottom: 20px;
  }

  /* CHAT */
  .wrap-chat-toolbar {
    background: rgba(249, 249, 249, 1);
  }

  .header-menuChat {
    display: none;
    position: fixed;
    z-index: 99;
    background-color: #eaeaea;
    width: 100%;
    top: 53px;
  }

  .header-menuChat ul {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    height: 60px;
    align-items: center;
  }

  .header-menuChat li {
    width: 50px;
    /* background: #a007a0; */
    /* height: 100%; */
    flex: 0 0 auto;
    text-align: center;
  }

  .header-menuChat li:nth-child(2) {
    flex: 1 1 auto;
    width: calc(100% - 100px);
    text-align: left;
  }

  .blc-chat-content .chat-info {
    width: 100%;
  }

  .MuiIcon-colorAction {
    color: #fff !important;
  }

  .MuiIcon-root {
    width: 110px !important;
    height: 40px !important;
    font-size: 13px !important;
  }

  /*.blc-chat-content .MuiIconButton-label-62{
        color: #333333 !important;
    }*/
  /*
    .MuiModal-hidden-49{
        visibility: visible;
    }*/
  header.st-content-tab {
    border: none !important;
  }

  ul.w-full {
    padding-top: 0;
  }

  .MuiDrawer-paperAnchorLeft-127 {
    width: 100% !important;
    transform: translateX(0px) !important;
  }

  .chat-sidebar .chat-search {
    max-width: 400px;
    margin: 0 auto 20px auto;
  }

  .chat-sidebar {
    width: 100%;
  }

  .fullW-chat {
    width: 100%;
  }

  .blc-chat-content.container {
    margin-top: 0 !important;
  }

  /* Groupe écran -767px */

  .quant-member {
    display: block;
  }

  .group-info .group-name {
    font-size: 10px;
  }

  .group-info .group-member {
    font-size: 9px;
  }

  .group-info {
    padding-left: 0px;
  }

  .search-wrap .lpicto-menu:after {
    left: 0;
    position: relative;
  }

  .search-wrap .search .blc-contact-item,
  .search-wrap .search .blc-group-list-item,
  .search-wrap .search .centered>li {
    padding: 0 14px 0 18px;
    width: 100%;
    height: 83px;
    border-bottom: 1px solid rgb(234, 234, 234);
  }

  .search-wrap .search .profil-list .blc-contact-item {
    min-height: 83px;
    height: auto;
  }

  .search-wrap .search ul.corporation-list-container>li {
    height: auto;
  }

  .blc-group-list-item .group-img {
    min-width: 53px;
    height: 53px;
  }

  .blc-group-list-item .group-img img {
    width: 53px;
    height: 53px;
  }

  /* GLOBAL */
  .tab-standard-body {
    padding: 0 20px;
  }

  /* CONNEXION */
  .landing .header-title {
    padding-bottom: 15px;
  }

  .header-title h1 {
    font-size: 26px;
    line-height: 36px;
    padding: 0 27px 15px;
    letter-spacing: -1px;
  }

  .landing .header-title h1 {
    font-size: 40px;
    line-height: 45px;
    padding: 0 27px 15px;
    letter-spacing: -1px;
  }

  .landing .header-title p {
    font-size: 14px;
    line-height: 20px;
    width: 65%;
  }

  .header-top .rw {
    align-items: center;
    height: 60px;
    padding-bottom: 0px;
  }

  .header-top .btn-login button {
    font-size: 10px;
  }

  .header-top .btn-login button::before {
    width: 12px;
    height: 12px;
    left: -15px;
  }

  /* LANDING */
  .landing .tab-standard-body .signin-submit {
    margin-top: 30px;
  }

  /* ----- landing betatest ----- */
  .landing-betatest {
    height: 100vh;
  }

  .landing .header {
    flex: 2;
    background: url(images/bigbg-heatitle.png) no-repeat top center;
    background-color: #faf4fe;
    background-size: 108%;
    background-position: bottom;
  }

  .landing .header-top .rw {
    height: 65px;
  }

  .landing .header-top.container .logo {
    width: 70px;
  }

  .landing .header-top.container .logo .logo_mobile {
    width: 14px;
  }

  .landing .header-top.container .logo a {
    display: block;
    height: 100%;
  }

  .landing .header-top.container .logo a img {
    width: 100%;
  }

  .landing .header-title {
    height: calc(100% - 100px);
    min-height: 140px;
  }

  .landing .header-title .container {
    background: none;
    padding-top: 10px;
    background-size: 125%;
    height: 100%;
    justify-content: flex-end;
    background-position: bottom;
  }

  .landing-betatest .parent-btn-connexion {
    flex: 1;
  }

  .invit .btn {
    margin: 0 0 0 10px;
    min-width: 80px;
  }

  .signin-Step3 .contactActions {
    padding-bottom: 10px;
  }

  .signin-Step3 .blc-contact-search-bar .search-text {
    height: 100%;
    width: calc(100% - 30px);
  }

  .signin-Step3 .blc-contact-search-bar .search-text input {
    height: 100%;
  }

  .signin-Step3 .blc-signin .blc-contact-search-bar {
    margin: 0 auto;
  }

  .signin-Step3 .blc-signin .blc-contact-item .blc-contact-img,
  .signin-Step3 .blc-signin .blc-contact-item .blc-contact-img img {
    height: 39px;
    width: 39px;
  }

  .signin-Step3 .blc-signin .blc-contact-item .blc-contact-info {
    line-height: 15px;
    padding: 0 9px;
  }

  .signin-Step3 .blc-signin .blc-contact-item .blc-contact-info span:first-child {
    font-size: 11px;
  }

  .signin-Step3 .blc-signin .blc-contact-item .blc-contact-info span:last-child {
    font-size: 10px;
  }

  .signin-Step3 .label-form-inscription {
    margin-top: 20px;
  }

  .blc-signin .blc-contact-search-bar {
    width: 100%;
    margin: 20px auto 0;
  }

  .signin-Step2 .rowForm div {
    display: block !important;
    padding: 0 20px 0 0;
  }

  p.textarea-info {
    /* text-align: right; */
    padding: 0 13px;
  }

  .signin-Step2 .rowForm .blc-select-container select.sign-select {
    /* width: 89%; */
    width: 100%;
    font-size: 11px;
  }

  .signin-Step2 .blc-signin .blc-select-container:after {
    /* right: 30px; */
    right: 15px;
    top: 14px;
  }

  .signin-Step2 .rowForm textarea {
    width: 89%;
    margin: 0 auto;
    display: block;
    min-height: 249px;
  }

  .signin-Step2 .form-signin-ctnr {
    padding-top: 1px;
  }

  .signin-Step2 .rowForm .inpForm {
    width: 89%;
  }

  .signin-Step3 .rowForm .inpForm {
    width: 86%;
  }

  .signin-Step3 .rowForm.invit .inpForm {
    margin-bottom: 10px;
  }

  .signin-Step3 .blc-contact-search-bar .search-bar .inpForm {
    display: flex;
  }

  .signin-Step2 .file-photos {
    padding: 0 13px;
  }

  .signin-Step2 .blc-signin {
    padding: 0 22px;
  }

  .signin-Step3 .blc-signin {
    padding: 0 10px;
  }

  .landing .tab-standard-body {
    background: linear-gradient(to bottom,
        rgba(250, 244, 254, 1),
        rgba(255, 255, 255, 1)) !important;
  }

  .list-step {
    padding-right: 0;
  }

  .photo-inscription {
    padding-bottom: 10px;
    margin-right: 0;
    max-width: 60px !important;
    height: 60px !important;
  }

  .photo-inscription img {
    width: 60px !important;
    height: 60px !important;
    cursor: pointer;
  }

  .form-signin-ctnr .centered.pt-50 {
    padding-top: 10px;
  }

  .landing .form-signin-ctnr .rowForm .flex-center.flex-vertical,
  .landing .form-signin-ctnr .rowForm .blc-choice-container button,
  .landing .form-signin-ctnr .rowForm.sign-mail input {
    width: 100%;
    margin: 10px 0;
  }

  .form-signin-ctnr .rowForm {
    padding-right: 0;
    padding-left: 0;
    /* width: calc(100% + 50px);
        margin-left: -25px;
        margin-right: -25px; */
  }

  .lradio input[type='radio'] {
    height: 18px;
    width: 17px;
    margin-left: -49px;
    top: 0px;
  }

  .form-signin-ctnr .lpicto-check-secondary:after,
  .editProfil-page .lpicto-check-secondary:after {
    background-size: 80%;
    left: -5px;
    top: -5px;
  }

  .form-signin-ctnr .lradio-mark,
  .editProfil-page .lradio-mark {
    flex: 0 0 16px;
    height: 16px;
  }

  .form-signin-ctnr .put-inline .lradio {
    margin-top: 7px;
  }

  /* HEADER & HOME FEED*/
  .publish-dialog .popClosebtn::before {
    /* right: 17px;
        position: absolute;
        top: -9px; */
  }

  .bgl-content {
    /* top: 32px; */
  }

  .lwrapper-global {
    height: calc(100vh - 111px);
  }

  .with_header {
    margin-top: 111px;
  }

  /* .header-mobil .global-header-right{
        flex: 0 0 52px;
        height: 52px;
    } */
  .header-mobil .global-header-left {
    flex: 0 0 60px;
    height: 60px;
  }

  .header-mobil .global-header-left .search-bar .Search-itemList {
    top: 58px;
  }

  /* .header-mobil .main-nav .search-bar .search-form{
        height: 30px;
        width: 250px;
    } */

  .main-nav .nav li.nav-item:nth-of-type(1) {
    flex: 1 1 auto;
  }

  .global-header-left .search-form .btn-search {
    /* height: 30px; */
    /* width: 30px;
        padding: 0; */
  }

  /* .global-header-left .search-form > div {
        height: 30px;
        width: calc(100% - 30px);
        padding-right: 0;
    } */
  /* .global-header-left .lpicto-search.lpicto-black{
        right:7px;
        top:15px;
    } */
  /* .nav-item .onFocused .btn-search .lpicto-search{
        right:7px;
     } */
  /* .global-header-left .nav-item .onFocused .lpicto-search:after{ */
  /* height: 30px; */
  /* width: 35px;
        right: -7px;
    } */
  .newsfeed .blc-detail-comment .usr-info-pub {
    flex: 1 0 auto;
    width: auto;
  }

  .newsfeed .btn-option-pub .btn.toggleDropDown {
    margin-left: 0;
  }

  /* ------ FOOTER ----- */
  .footer-home .mtmobile:not(.footer-list-container) {
    margin: 0;
  }

  .footer-home .nav .separated_footer_bloc {
    max-width: none;
    width: 50%;
    position: relative;
  }

  .footer-home .nav .separated_footer_bloc:first-child::after {
    position: absolute;
    content: '';
    width: 2px;
    height: calc(100% - 32px);
    display: block;
    background-color: #858585;
    margin: 16px 0;
    right: 0;
    top: 0;
    opacity: 0.2;
  }

  .footer-home .nav .separated_footer_bloc ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .footer-home .nav .separated_footer_bloc ul li.nav-item {
    padding: 0 15px;
  }

  .footer-home .nav .separated_footer_bloc ul li.sm-long-text {
    width: 100%;
    max-width: none;
  }

  .footer-home .logo

  /*.landing .header .header-top .logo */
    {
    width: auto;
  }

  /* CONTACT ou RELATION */
  .search-wrap .blc-contact-relation .ldropdown-content {
    right: -20px;
    top: 20px;
  }

  .blc-validate-contact.header-contact-mobile {
    padding-top: 0;
    padding-bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-contact-mobile .title-new-annonce {
    margin-top: 0;
  }

  .header-contact-mobile .title-new-annonce p {
    height: 20px;
    line-height: 30px;
  }

  .blc-validate-contact.header-contact-mobile a {
    width: 62px;
    position: relative;
  }

  .blc-validate-contact.header-contact-mobile a::before,
  .blc-validate-contact.header-contact-mobile a::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #ffffff;
    left: 50%;
  }

  .blc-validate-contact.header-contact-mobile a::before {
    transform: rotate(50deg);
    top: 17px;
  }

  .blc-validate-contact.header-contact-mobile a::after {
    transform: rotate(-50deg);
    top: 11px;
  }

  .p-relation.layout.wall.container {
    margin: 0;
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .p-relation .wall-right {
    display: none;
  }

  .p-relation.wall .wall-center {
    width: calc(100% - 40px);
  }

  /* .lpicto-search.lpicto-black:after{
        right: 2px;
    } */
  .p-relation .lpicto-search:after,
  .dashboard .blc-dash-search-bar .search-form .lpicto-search:after {
    height: 11px;
    width: 11px;
    background-size: 100%;
    left: 50%;
    margin-left: -7px;
  }

  .dashboard .blc-filter-annonce .blc-dash-search-bar>button.lpicto-search:after {
    height: 12px;
    width: 12px;
    background-size: 100%;
  }

  .blc-select-container.lselect-secondary:after {
    right: 6px;
    top: 5px;
    background-size: 68%;
  }

  .p-relation .lselect-secondary.bg-white select {
    padding: 5px 10px;
  }

  .p-relation .contactPage .contactActions {
    flex-flow: row nowrap;
    margin: 0;
    padding: 20px 0;
    justify-content: flex-start;
  }

  .p-relation .blc-prod-search-bar {
    /* min-width: 77px; */
    margin: 0 8px;
  }

  .p-relation .blc-prod-search-bar .search-bar {
    min-width: 77px;
  }

  .p-relation .contactActions .blc-select-container,
  .dashboard .blc-annonce-filter .blc-select-container {
    height: 25px;
    flex: 1;
    min-width: 145px;
    max-width: 400px;
  }

  .dashboard .blc-dash-search-bar .search-form.btn-40 {
    height: 25px;
  }

  .dashboard .blc-filter-annonce {
    padding: 0 20px;
    margin: 15px 0;
  }

  .dashboard .blc-dash-search-bar button {
    height: 25px;
  }

  .dashboard .blc-annonce-filter,
  .dashboard .blc-annonce-filter .blc-select-container {
    width: 100%;
  }

  .p-relation .blc-prod-action {
    /* height: 25px; */
    margin-left: 0;
    flex: 0 0 auto;
  }

  .p-relation .contactActions .blc-prod-action .btn {
    width: auto;
    padding: 0 22px;
    display: flex;
  }

  .p-relation .contactActions .blc-prod-action .btn span {
    display: inherit;
    margin-left: 7px;
    font-size: 11px;
  }

  .p-relation .search-bar .search-form {
    height: 25px;
  }

  .blc-prod-filter .blc-prod-action {
    /* height: 25px; */
    margin-left: 0;
    flex: 0 0 13%;
  }

  .blc-prod-filter .search-text {
    display: none;
  }

  /* --- dynamic searchbar --- relation contacts */
  .p-relation .mobile-searchbar-active .blc-select-container {
    display: none;
  }

  .p-relation .mobile-searchbar-active .blc-prod-search-bar {
    flex: 1;
    margin-left: 0;
  }

  .p-relation .mobile-searchbar-active .btn-send-comment {
    width: 40px !important;
  }

  .p-relation .mobile-searchbar-active .search-text {
    display: block;
    width: 100%;
  }

  .p-relation .mobile-searchbar-active .btn-send-comment .lpicto-search {
    right: 0px;
  }

  .p-relation .mobile-searchbar-active .btn-send-comment .lpicto-search:after {
    background: url(images/chevron-right-gray.svg);
    background-size: 100%;
    display: block;
    height: 10px;
    width: 10px;
    margin: 0;
  }

  /* --- ---- */

  .blc-prod-filter .btn-send-comment {
    width: 100% !important;
    height: 25px;
  }

  .p-relation .btn-send-comment .lpicto-search,
  .dashboard .blc-dash-search-bar .search-form .lpicto-search {
    top: inherit;
    right: unset;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .p-relation .btn>i {
    margin-right: 0;
  }

  .blc-prod-filter .lpicto-filter:after,
  .p-relation .lpicto-filter:after {
    margin-right: 0;
    background-size: 75%;
    margin: 0 auto;
  }

  .blc-prod-filter .blc-prod-action .btn,
  .p-relation .blc-prod-action .btn {
    width: 39px;
    padding: 0;
    height: 25px;
    display: block;
    float: left;
  }

  /*.p-relation .blc-prod-action .btn span{
        display: none;
    } */
  /* .blc-prod-action .btn{
        width: 39px;
        padding: 0;
        height: 25px;
        display: block;
        float: left;
    } */
  .blc-prod-action .btn span {
    display: none;
  }

  .blc-prod-action .btn.lbtn-new {
    display: block;
    line-height: 20px;
    height: 30px;
    margin: 0 auto 25px auto;
  }

  .blc-prod-action .btn.lbtn-new span {
    display: block;
    font-size: 12px;
    /* height: 40px; */
  }

  .p-relation .nbRelationContainer {
    font-size: 16px;
  }

  .p-relation .lpicto-menu:after {
    background-size: 62%;
    /*right: -8px;*/
  }

  .p-relation .btn-icon.lpicto-chat-primary {
    margin-right: 0;
  }

  .p-relation .blc-contact-item-right .btn {
    padding-right: 10px;
    padding-left: 0;
  }

  .p-relation .blc-contact-relation .blc-contact-item {
    padding: 0 19px;
  }

  .p-relation .blc-contact-relation .blc-contact-item-right,
  .p-relation .blc-contact-item-right .btn.toggleDropDown {
    padding: 0;
  }

  .p-relation .blc-contact-item-right .ldropdown.toggleDropDown {
    margin-left: 5px;
  }

  .blc-contact-relation .blc-contact-item {
    height: 58px;
  }

  .p-relation .blc-contact-info {
    padding-left: 9px;
    /* padding-right: 0; */
    overflow: hidden;
  }

  .p-relation .blc-contact-info span {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .p-relation .blc-contact-img img {
    width: 50px;
    height: 50px;
  }

  .p-relation .blc-contact-item-left {
    flex: 0 0 auto;
    width: calc(100% - 64px);
  }

  .p-relation .blc-contact-item-right {
    /* flex: 0 0 15%; */
    flex: 0 0 auto;
    width: auto;
  }

  .p-relation .lpicto-chat-primary:after {
    background-size: 100%;
    height: 16px;
    width: 16px;
  }

  /* PROFIL */
  .detailAnnonce-footer {
    align-items: center;
    flex-flow: column wrap;
    text-align: center;
    padding: 0;
  }

  .detailAnnonce-footer .blc-share-menu-group .lpicto-favoris,
  .detailAnnonce-footer .blc-share-menu-group .lpicto-share-primary {
    flex: 0 0 40px;
    margin: 0 10px;
  }

  .detailAnnonce .desc-annonce .title-annonce {
    font-size: 20px;
    padding-right: 0;
    line-height: 24px;
    margin-bottom: 26px;
  }

  .detailAnnonce .annonce-ref span:nth-of-type(3) {
    margin: 10px 30px 0 30px;
    width: 100%;
    flex-flow: column nowrap;
    height: 50px;
  }

  .detailAnnonce .info-date {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
  }

  .detailAnnonce .desc-annonce {
    width: auto;
    margin: 0 auto;
    flex: 0 1 100%;
  }

  .detailAnnonce .desc-annonce .annonce-ref {
    justify-content: center;
    flex-flow: row wrap;
  }

  .detailAnnonce .lpicto-loc:after {
    margin-right: 0;
  }

  .detailAnnonce .date-publicate {
    width: 40%;
  }

  .detailAnnonce .taglist-annonce {
    flex: 0 0 60%;
  }

  .annonce-form .layout-small .lselect {
    position: relative;
    z-index: 20;
  }

  .blc-annonce-center .blc-filter-annonce {
    padding: 0 20px;
  }

  /* PROFIL entreprise*/
  .blc-list-profil-cntr .corporation-Indépendant,
  .blc-list-profil-cntr .corporation-industriel,
  .blc-list-profil-cntr .corporation-académique,
  .blc-list-profil-cntr .corporation-autres {
    padding: 4px 0 0;
  }

  .blc-list-profil-cntr .tag-usr-profil {
    padding: 8px 14px;
    flex: 0 0 auto;
    max-width: 150px;
  }

  #root.with_header .imageResizerBackground {
    padding: 130px 5px 25px 5px;
  }

  .blc-dialogForm .popTitle-ctnr {
    padding-top: 10px;
  }

  .blc-list-profil-cntr {
    margin: 20px auto 0px auto;
  }

  .blc-profil-actions .lpicto-chat-primary:after {
    background-size: 100%;
    height: 20px;
    width: 20px;
  }

  .blc-profil-actions .lpicto-favoris:after {
    background-size: 100%;
    height: 20px;
    width: 20px;
  }

  .blc-profil-actions .lpicto-share-primary::before {
    background-size: 100%;
    height: 18px;
    width: 18px;
  }

  .layout-entreprise {
    padding: 0;
  }

  .profil-enterprise .blc-tags {
    padding-bottom: 0;
  }

  .profil-page .profil-info-center,
  .profil-enterprise .wrap-left-container.entreprise {
    margin-left: 20px;
    margin-right: 20px;
  }

  .profil-enterprise .blc-profil-actions div button {
    height: 25px;
    margin-left: 0;
    margin-right: 0;
    padding: 2px 10px;
    font-size: 12px;
  }

  .profil-enterprise .blc-profil-actions div button.btn-share-profil {
    padding-right: 0;
  }

  .profil-enterprise .blc-profil-actions button.btn-admin {
    /* margin-top:20px; */
  }

  .profil-enterprise .usr-info-pub,
  .feedContainer .usr-info-pub,
  .favoris-page .usr-info-pub {
    flex: 0 0 auto;
    padding-left: 9px;
    width: calc(100% - 100px);
  }

  .feedContainer .newsfeed .usr-info-pub,
  .favoris-page .feedContainer .usr-info-pub {
    max-width: calc(100% - 95px);
    width: 100%;
  }

  .feedContainer .blc-comment-item .blc-head-publication .date-pub {
    position: relative;
    right: 12px;
  }

  .feedContainer .blc-comment-item .comment-option {
    right: 5px;
  }

  .feedContainer .blc-comment-item .comment-option .lpicto-menu {
    width: 20px;
  }

  .profil-enterprise .btn-pub,
  .profil-enterprise .btn-option-pub,
  .feedContainer .btn-option-pub {
    flex: 0 0 auto !important;
    padding-left: 4px;
    width: 20px;
  }

  .corporation-feed .feedComposerFieldComment {
    font-size: 10px;
  }

  .corporation-feed .blc-head-publication,
  .feedContainer .blc-head-publication {
    padding-left: 25px;
    padding-right: 25px;
  }

  .newsfeed .blc-detail-comment .blc-comment-item .blc-head-publication.head-left {
    padding-right: 0;
  }

  .newsfeed .blc-comment-item .blc-head-publication {
    padding-right: 5px;
  }

  .newsfeed .blc-detail-comment .blc-comment-item .blc-comment-content .blc-comment-lvl-container {
    padding: 10px;
    max-width: calc(100% - 38px);
  }

  .layout-entreprise .layout-medium {
    margin: 0 0 20px;
  }

  .corporation-feed .lpicto-favoris:after {
    position: relative;
    height: 20px;
    width: 20px;
    background-size: 100%;
  }

  .corporation-feed .blc-btn-action-publication {
    padding-bottom: 0;
  }

  .corporation-feed .blc-btn-action-publication button {
    /*justify-content: center;
        margin-right: 0;
        padding: 0;
        width: 100%;*/
  }

  .corporation-feed .lpicto-menu,
  .feedContainer .lpicto-menu {
    padding: 0;
  }

  .corporation-feed .lpicto-menu:after,
  .feedContainer .lpicto-menu:after {
    position: relative;
    right: 0;
    width: 5px;
    height: 16px;
    top: 0;
    background-size: 80%;
  }

  .profil-enterprise .display-txt .display {
    font-size: 9px;
    color: rgb(51, 51, 51);
  }

  .profil-enterprise .blc-head-publication .usr-pub {
    font-size: 10px;
    color: rgb(51, 51, 51);
  }

  .profil-enterprise .blc-head-publication .role-pub,
  .profil-enterprise .blc-head-publication .date-pub {
    font-size: 9px;
    color: rgba(51, 51, 51, 0.6);
    height: auto;
    display: block;
    line-height: 12px;
  }

  .profil-enterprise .feedComposerBackground {
    /* margin-top: 10px; */
  }

  .blc-profil-header .put-inline.m-show {
    display: none;
  }

  .profil-enterprise .layout-entreprise {
    margin-top: 0 !important;
    border: none !important;
    background: none;
  }

  .layout-add-enterprise {
    /* padding: 20px; */
    margin-top: 20px;
  }

  .layout-add-enterprise .lprofilPm-body.more-spaces {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .layout-add-enterprise .lprofilPm-item {
    padding-left: 17px;
    padding-right: 17px;
    border-bottom: 1px solid #eaeaea;
  }

  .layout-add-enterprise .st-content-tab {
    border: none !important;
    margin-bottom: 0 !important;
  }

  .layout-add-enterprise .create-enterprise {
    color: #fff !important;
    text-align: center;
    margin: 20px auto;
    display: block;
    width: 200px;
    line-height: 35px;
    height: 35px;
  }

  .layout-add-enterprise .lprofilPm-body .lprofilPM-info {
    margin: 0;
    padding: 10px 0 0;
  }

  .layout-add-enterprise .lprofilPm-body .sidebar-list .lprofilPM-info {
    padding: 0 0 0 15px;
  }

  .layout-add-enterprise .wall-header {
    box-shadow: none;
    margin-bottom: 0;
    padding: 20px;
    border-bottom: 1px solid #eaeaea;
  }

  .layout-add-enterprise .blc-contact-item {
    justify-content: flex-start;
    border-top: 1px solid #eaeaea;
    padding: 13px 18px;
    box-shadow: none;
  }

  .sidebar-list .lprofilPM-img {
    height: 53px;
    width: 53px;
  }

  .layout-add-enterprise .blc-contact-img {
    flex: 0 0 53px;
    height: 53px;
    width: 53px;
  }

  /* .layout-add-enterprise .blc-contact-img img{
        height: 53px;
        width: 53px;
    } */
  .layout-add-enterprise span.blc-contact-info {
    padding: 0;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
  }

  .layout-add-enterprise span {
    color: rgba(51, 51, 51, 0.6);
  }

  .layout-add-enterprise .blc-contact-enterprise {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }

  .layout-entreprise .corporation-feed {
    width: 100%;
    margin: 0;
  }

  /* .blc-notif-publication {
        flex-flow: wrap;
    }
    .blc-notif-publication > div{
        white-space: nowrap;
        line-height: 1;
        display: flex;
        align-items: center;
    }
    .blc-notif-publication > div::after{
        height: 10px !important;
        height: 1px;
        line-height: 1px;
    }
    .blc-notif-publication > div:last-child{
        margin: 0
    }
    .blc-notif-publication > div:last-child::after,
    .blc-notif-publication > div.shares::after{
        content:"";
        display: none;
    } */
  /* PROFIL ACTUS */
  /* .post-ToShare.blc-profil-header .blc-img-profil */
  .blc-action-comment-item .btn {
    padding: 0 10px;
  }

  .blc-btn-action-publication .btn-action-item {
    flex: 1 1 33%;
    position: relative;
    z-index: 300;
  }

  .profil-page .post-ToShare .blc-img-profil {
    left: 0;
    margin-left: 0;
    position: relative;
    top: 0;
  }

  .profil-page .post-ToShare .blc-profil-head-info {
    flex: 1 1 50%;
  }

  .profil-page .post-ToShare.blc-profil-header {
    flex-flow: row nowrap;
    margin: 0 auto;
    max-width: 260px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .profil-actus-center {
    width: 100%;
  }

  .react_tinylink_card {
    min-width: 100%;
  }

  /* PROFIL PUBLICATION & DETAIL BREVET*/
  .detailBrevet .headerPage-left {
    flex: 0 0 20%;
  }

  .detailBrevet .header-brevet .brevet-head {
    height: 50px;
    margin: 6px 21px 0 21px;
    padding: 0 0;
  }

  .lpicto-projet {
    height: 30px;
    width: 20px;
  }

  .detailBrevet .lpicto-goback span {
    display: none;
  }

  .detailBrevet .wall {
    padding: 0;
  }

  .detailBrevet.blc-brevet {
    margin-top: 0;
  }

  .profil-tab .profil-actus-center .blc-brevet {
    margin: 0 20px;
  }

  .brevet-bottom,
  .blc-smallbr-list {
    align-items: flex-start;
    color: rgb(51, 51, 51);
    flex-direction: column;
    font-size: 9px;
    margin-top: 6px;
  }

  .brevet-bottom-left {
    display: none;
  }

  .brevet-link-more span {
    font-size: 9px;
  }

  .brevet-ref {
    font-size: 9px;
  }

  .brevet-title {
    font-size: 12px;
  }

  .brevet-title span {
    color: rgb(51, 51, 51);
    font-size: 14px;
    letter-spacing: -0.52px;
    line-height: 22px;
  }

  .brevet-description {
    font-size: 9px;
    color: rgb(51, 51, 51);
  }

  .lpicto-brevet,
  .lpicto-projet,
  .lpicto-autre {
    flex: 0 0 auto;
    height: auto;
    height: 30px;
    width: 20px;
  }

  .lpicto-brevet:after,
  .lpicto-projet:after,
  .lpicto-autre:after {
    /* display: block; */
    height: 30px;
    width: 20px;
    margin: 0 auto;
    background-position: center center;
  }

  .profil-page .brevet-head {
    height: 52px;
    padding: 10px 10px;
  }

  .search-content .brevet-icon,
  .profil-page .brevet-icon {
    flex: 0 0 30px;
    width: 30px;
  }

  .brevet-info {
    flex: 1 1 80%;
    padding-left: 0;
  }

  .headcenter-brevet {
    align-self: flex-end;
    flex: 0 1 30%;
    min-height: 40px;
    padding: 10px 0;
    width: auto;
  }

  .brevet-head .brevet-info {
    margin-left: 13px;
  }

  .blc-smallbr-item .brevet-info {
    padding-left: 0 !important;
    width: calc(100% - 90px);
  }

  .brevet-link-content span:nth-of-type(1) {
    padding: 0 10px;
  }

  .brevet-link-content span:nth-of-type(2) {
    padding: 0;
  }

  .detailBrevet .lpicto-goback:before {
    transform: rotate(180deg);
  }

  .search-content .brevet-info,
  .profil-page .brevet-info {
    margin-left: 10px;
    flex: 0 0 auto;
    width: calc(100% - 90px);
  }

  .search-content .brevet-action {
    flex: 0 0 auto;
    width: 60px;
  }

  .brevet-action {
    justify-content: center;
    flex: 0 0 auto;
  }

  .detailBrevet .brevet-action {
    /* flex: 0 0 20%; */
  }

  .detailBrevet .brevet-desc-content {
    padding-left: 23px;
    padding-right: 23px;
  }

  .detailBrevet .header-brevet .brevet-bottom {
    align-items: center;
    height: 26px;
    justify-content: space-between;
  }

  .detailBrevet .header-brevet .brevet-description {
    padding: 20px;
  }

  .detailBrevet .header-brevet .brevet-info {
    margin-left: 13px;
    justify-content: center;
  }

  .brevet-action button {
    display: none;
  }

  /* .btn-share-brevet .detailBrevet .header-brevet button{
        display: block;
    } */
  .detailBrevet .btn-share-brevet button {
    border: none !important;
    padding-right: 6px;
    padding-left: 6px;
  }

  .detailBrevet .btn-share-brevet button span:nth-of-type(2) {
    display: none;
  }

  .blc-medium-item,
  .blc-small-item,
  .blc-smallbr-item {
    width: 100% !important;
  }

  .profil-page .lpicto-favoris {
    display: none;
  }

  .detailBrevet .header-brevet .brevet-desc-content {
    border-bottom: 1px solid #eaeaea;
  }

  .profil-page .brevet-img,
  .blc-smallbr-list-content .blc-medium-item .brevet-img,
  .blc-smallbr-item .brevet-img {
    height: auto !important;
    max-height: 170px;
  }

  .profil-tab .lpicto-menu::after {
    /* right: 9px; */
    left: 50%;
    top: auto;
  }

  .detailBrevet .header-brevet .brevet-img {
    max-height: 170px;
    overflow: hidden;
  }

  .label-img-upl {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .brevet-desc-content .brevet-title {
    height: 64px;
    margin-bottom: 10px;
  }

  .detailBrevet .brevet-desc-content .brevet-title {
    height: auto;
  }

  .detailBrevet .brevet-tag-list {
    display: none;
  }

  .lpicto-link:after {
    height: 13px;
    width: 13px;
    background-size: 100%;
  }

  .brevet-link-more {
    margin-top: 6px;
  }

  .brevet-link-content span a,
  .detailBrevet .embedded-link,
  .profil-page .embedded-link {
    font-size: 10px;
    color: rgb(51, 51, 51) !important;
  }

  /* .profil-tab{
        margin:0 20px;
    } */
  .profil-icon-item.first:nth-child(2),
  .profil-icon-item.first:nth-child(3),
  .profil-icon-item.first:nth-child(4),
  .profil-icon-item.first:nth-child(5),
  .profil-icon-item.first:nth-child(5),
  .profil-icon-item.first:nth-child(6) {
    display: none;
  }

  .blc-smallbr-item .number-profil>span,
  .number-profil span {
    top: inherit;
    font-size: 11px;
    left: 50%;
    margin-left: -17px;
    text-align: center;
    width: 100%;
  }

  .brevet-info-content .list-profil-icon {
    width: auto;
  }

  .brevet-bottom-info {
    width: 40px;
  }

  .brevet-info-content .list-profil-icon .profil-icon-item {
    width: 40px;
  }

  .profil-icon-item.first div {
    width: 33px;
    height: 33px;
  }

  .brevet-link-info {
    padding: 18px 20px;
  }

  .number-profil {
    position: relative;
  }

  .number-profil span {
    color: rgb(89, 193, 229);
  }

  /* .blc-prod-filter .search-bar{
        margin: 0 8px;
        flex: 0 0 38px;
    } */
  .profil-items-ctnr .blc-prod-filter {
    width: auto;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 14px;
  }

  .profil-items-ctnr .blc-prod-filter .blc-select-container {
    width: auto;
    height: 25px;
    flex: 1 1 188px;
  }

  .blc-prod-filter .search-form>div,
  .blc-prod-filter .search-form>.search-text {
    height: 25px;
  }

  .profil-items-ctnr .btn-send-comment .lpicto-search:after {
    height: 11px;
    width: 11px;
    background-size: 100%;
    bottom: 0;
    margin-left: 10px;
    left: 50%;
  }

  .blc-prod-filter .blc-prod-search-bar {
    flex: 0 0 39px;
    margin: 0 10px;
  }

  .blc-prod-filter .search-bar .search-form {
    height: 100%;
  }

  /* .profilInfo */
  .profilInfo .blc-profil-actions {
    justify-content: center;
    flex-direction: column;
  }

  .profilInfo .blc-profil-actions button.add-feed {
    min-width: 111px;
    /* margin: 10px; */
    margin-right: 10px !important;
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
  }

  .compt-list>li {
    padding: 15px 0;
  }

  .list-icon-label li>span {
    font-size: 8px;
  }

  .lpicto-entreprise:after,
  .lpicto-loc:after,
  .lpicto-relation:after,
  .lpicto-subscriber:after,
  .lpicto-scient::after,
  .lpicto-language:after {
    height: 13px;
    width: 10px;
    background-size: 100%;
  }

  .diplom-info {
    padding-left: 0;
  }

  .parcours-title {
    flex-direction: column;
  }

  .parcours-title span {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
    text-transform: none;
  }

  .parcours-text span,
  .diplom-text span {
    font-size: 9px;
    line-height: 16px;
  }

  /* .parcours-title .parcours-type{
        font-size:12px;
    } */

  .blc-compt-item-ctnr {
    /* padding-left: 50px; */
  }

  .recommanded-nbr {
    font-size: 9px;
    line-height: 20px;
    padding-left: 6px;
    /* display: none; */
  }

  /* .compt-icon-add button{
        width: 38px;
        height: 38px;
    } */
  .parcours-title .parcours-date,
  .diplom-date.parcours-date {
    font-size: 9px;
  }

  .diplom-list,
  .parcours-list {
    padding-top: 27px;
  }

  .profil-items-ctnr .blc-contents-accordion {
    padding: 0 0 30px 0;
  }

  .diplom-img-item {
    flex: 0 0 40px;
    height: 40px;
    margin-right: 17px;
  }

  .profil-items-ctnr .blc-title-accordion {
    height: 45px;
  }

  .profil-page .accordion {
    /* margin: 20px; */
    padding: 0 18px;
  }

  .accordion .ico-caret-up {
    width: 10px;
    height: 10px;
  }

  .profil-page .title-accordion {
    font-size: 10px;
  }

  /* .centered-profil{
        padding: 0 20px;
    } */
  .centered-profil .licontent {
    justify-content: flex-start;
    padding: 0 20px 0 39px;
  }

  .profil-tab .blc-content-tab ul li {
    width: 100%;
    height: 96px;
    margin-top: 13px;
  }

  .corporation-feed {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  .profilInfo .blc-tab-title {
    height: auto;
    min-height: 47px;
  }

  .profilInfo .blc-content-tab .blc-tab-title ul>li {
    padding: 15px 5px;
  }

  .show-more.ico-caret-down,
  .show-more.ico-caret-up {
    background-size: 12px !important;
    margin-top: 0;
  }

  .blc-profil-top h3 {
    font-size: 10px;
    padding: 25px 0;
    margin-top: 0;
  }

  .blc-resume p,
  .blc-activity p {
    font-size: 9px;
  }

  .blc-profil-info .centered-profil {
    margin: 0;
    padding: 15px 0;
  }

  .blc-profil-actions .put-inline.half-putinline {
    width: 100%;
    align-items: center;
  }

  .blc-profil-actions .put-inline.half-putinline:nth-of-type(2) {
    margin-top: 20px;
  }

  .blc-profil-actions .put-inline {
    flex-flow: row wrap;
    justify-content: center;
    /* max-width: 228px; */
  }

  .blc-profil-actions .put-inline button {
    /* margin: 10px 2px 0 2px; */
    padding: 0 10px;
    font-size: 12px;
  }

  /* .blc-profil-actions .put-inline button:nth-of-type(1), .blc-profil-actions .put-inline button:nth-of-type(2) {
        width:111px;
        margin-right: 2px;
    }
    .blc-profil-actions .put-inline button:nth-of-type(3) {
        min-width:111px;
        margin-left: 2px;
    } */
  .blc-coord,
  .blc-stat {
    max-width: 50%;
    width: auto;
    padding: 0 10px;
    margin: auto;
  }

  .blc-coord {
    order: 2;
    max-width: 70%;
  }

  .blc-coord ul.list-icon-label {
    overflow: hidden;
  }

  .blc-stat {
    order: 3;
  }

  .profil-page .post-user,
  .entreprise .post-user {
    font-size: 10px;
    margin-top: -2px;
    line-height: 15px;
  }

  .profil-page .blc-profil-head-info,
  .entreprise .blc-profil-head-info {
    display: flex;
    margin-top: 30px;
    padding-left: 0;
    text-align: center;
    margin: 0 auto;
    flex-direction: column;
  }

  .profil-page .blc-profil-header,
  .entreprise .blc-profil-header {
    align-items: center;
    flex-direction: column;
    margin: 0;
    width: 100%;
    order: 0;
  }

  .profil-page .lshare .blc-profil-header {
    flex-direction: row;
  }

  .profil-page .blc-tags,
  .entreprise .blc-tags {
    order: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 20px;
  }

  .profil-page .blc-social,
  .entreprise .blc-social {
    margin-top: -22px;
  }

  .blc-profil-info .txt-limit-name {
    font-size: 15px;
    display: flex;
    height: 23px;
    line-height: 23px;
  }

  .blc-profil-name>div {
    padding-bottom: 5px;
  }

  .profil-page .blc-img-profil,
  .entreprise .blc-img-profil {
    position: absolute;
    top: -126px;
    left: 50%;
    margin-left: -50px;
    width: 107px;
    height: 107px;
  }

  body #root.with_header .blc-profil-info {
    margin: 84px 0 0;
    padding: 70px 25px 0;
  }

  body #root.with_header .profil-page .blc-profil-info {
    margin: 84px 0 0;
    padding: 70px 60px 10px;
  }

  .blc-profil-cover {
    margin-top: 0;
    height: 140px;
    top: 2px;
  }

  /* SEARCH & RESULTAT */
  .search-wrap-mobil .list-profil-item .tag-ctnr {
    width: 96%;
  }

  .search-wrap-mobil .list-profil-item.list-corporation-item .tag-ctnr {
    width: auto;
    margin-left: auto;
  }

  .search-wrap-mobil .list-profil-item.list-corporation-item .tag-ctnr span {
    font-size: 10px;
  }

  .search-wrap-mobil .annonce-mobil {
    margin-bottom: 20px;
  }

  .search-wrap .tab-standard-body>ul {
    background: rgba(0, 0, 0, 0);
    border: none;
  }

  .search-wrap .tab-standard-body>ul.centered,
  .search-wrap .tab-standard-body>ul .blc-contact-relation {
    background: #ffffff;
    border: solid 1px #eaeaea;
  }

  .contactPage .more-btn {
    margin: 20px 0 0 0;
    display: block;
  }

  .contactPage .more-btn .btn {
    display: block;
    margin: 0 auto;
    border-width: 1px !important;
    max-width: 210px;
    padding: 0 24px;
  }

  .search-wrap-mobil .search {
    /* padding-top: 60px; */
    margin-top: 90px;
  }

  .search-wrap-mobil .brevet-title {
    font-size: 10px;
  }

  .search-wrap-mobil .brevet-ref {
    font-size: 9px;
  }

  .search-wrap .search-content {
    top: -29px;
  }

  .search-wrap .tab-standard-header .blc-tab-title {
    /* position: fixed;
        z-index: 10; */
    top: 112px;
  }

  .search-wrap .search .blc-param-form .bloc-param-action button {
    color: rgb(51, 51, 51);
    position: relative;
  }

  .search-wrap .search .blc-param-form .bloc-param-action button:before {
    opacity: 0.4;
  }

  .wall-center .blc-contact-info span,
  .blc-contact-relation .blc-contact-info span {
    font-size: 10px;
    color: rgb(51, 51, 51);
  }

  .search-wrap .blc-contact-relation .blc-contact-item-right {
    flex: 0 0 50px;
    justify-content: center;
    position: relative;
    padding-right: 0;
  }

  .search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect {
    background-image: none;
    padding: 0;
  }

  .search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect .lpicto-share-secondary {
    display: inline-block;
    height: auto;
  }

  .search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect .lpicto-share-secondary::before {
    height: 14px;
    margin: 2px 0 0;
  }

  .search-wrap .btn-connect .lpicto-share-secondary::before,
  .favoris-page .btn-connect .lpicto-share-secondary::before,
  .interested-list .btn-connect .lpicto-share-secondary::before {
    background-image: url(./images/ico-share-primary.svg);
  }

  .search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect,
  .search-wrap .blc-contact-item-right .btn-add-mobile {
    border: 2px solid #59c1e5;
    border-radius: 14px;
    display: block;
    width: 38px;
    height: 24px;
    cursor: pointer;
    position: relative;
  }

  .search-wrap .blc-contact-item-right .btn-add-mobile::before,
  .search-wrap .blc-contact-item-right .btn-add-mobile::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #59c1e5;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -1px;
  }

  .search-wrap-mobil .lpicto-favoris {
    display: none;
  }

  .search-wrap .blc-contact-item-right .btn-add-mobile::before {}

  .search-wrap .blc-contact-item-right .btn-add-mobile::after {
    transform: rotate(90deg);
  }

  .search-wrap .blc-contact-item-right .btn-add-mobile.negate::after {
    display: none;
  }

  .search-wrap .blc-contact-item .blc-contact-img,
  .p-relation .blc-contact-img {
    flex: 0 0 37px;
    height: 37px;
    width: 37px;
  }

  .search-wrap .blc-contact-item .blc-contact-img img,
  .p-relation .blc-contact-img img,
  .list-corporation-item .img-corporation-container,
  .list-corporation-item .img-corporation-container img.img-corporation {
    height: 37px;
    width: 37px;
  }

  .search-wrap .search .blc-contact-item,
  .blc-contact-relation .blc-contact-item {
    margin: 0;
    width: 100%;
    padding: 0 10px;
  }

  .search-wrap .search .blc-param-title {
    font-size: 10px;
    color: rgb(51, 51, 51);
    padding: 15px 10px;
  }

  .blc-contact-relation {
    padding: 0;
  }

  .blc-contact-relation:nth-of-type(1) {
    padding-top: 10px;
  }

  .blc-content-tab ul li.tablist {
    padding: 0 2px;
    line-height: 10px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .blc-content-tab ul li.tablist a {
    font-size: 10px;
    color: rgb(51, 51, 51);
    font-weight: 500;
  }

  .search-wrap .blc-content-tab ul li.tablist.active a {
    font-weight: 700;
  }

  .search-wrap .blc-content-tab .blc-tab-title ul>li {
    width: 100%;
    padding: 0 5px;
  }

  .search-wrap .blc-content-tab ul li.tablist a span {
    display: block;
    line-height: 0px;
    padding: 5px 0 0;
    font-weight: 300;
    font-size: 8px;
    letter-spacing: 2px;
  }

  .blc-content-tab .blc-tab-title ul>li.active::after {
    height: 2px;
    bottom: -1px;
  }

  /* dashboard */
  .annonce-item {
    position: relative;
  }

  .dashboard .blc-annonce-item {
    flex-direction: column;
  }

  .annonce-mobil .annonce-header,
  .dashboard-list .annonce-mobil .annonce-body {
    text-align: center;
    width: 100%;
  }

  .annonce-mobil .annonce-body {
    padding: 10px 20px;
  }

  .annonce-mobil .title-annonce,
  .dashboard .annonce-mobil .info-date {
    text-align: center;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  .annonce-mobil .annonce-header .img-annonce {
    flex: 0 0 100%;
  }

  .annonce-about {
    margin: 0;
  }

  .annonce-mobil .annonce-modif {
    color: #fff;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    width: auto;
  }

  .dashboard-list .annonce-lib {
    background: rgb(51, 51, 51);
    color: #fff;
    font-size: 10px;
    white-space: nowrap;
  }

  .annonce-tags {
    justify-content: center;
  }

  .blc-title-dash {
    font-size: 26px;
    margin: auto;
    padding: 30px 0;
  }

  .blc-dashboard-right,
  .blc-annonce-right {
    width: 100%;
  }

  .blc-annonce-right .wall-right-spec .blc-validate-annonce-ctnr,
  .dashboard .blc-validate-annonce-ctnr {
    display: inherit;
  }

  .annonce-page~.wrapper-annonce {
    margin-top: 15px;
  }

  .annonce-page .blc-annonce-search {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 95%;
  }

  .annonce-page .blc-annonce-search .btn-search-annonce {
    flex: 0;
  }

  .blc-annonce-search {
    padding-top: 160px;
    text-align: center;
    padding-bottom: 0;
  }

  .blc-dashboard-right .blc-validate-annonce-ctnr .txt-cntr {
    /* margin:auto; */
  }

  .blc-annonce-right .blc-validate-annonce-ctnr .txt-cntr {
    text-align: center;
  }

  .btn-dash-spec.chevron-right {
    background: url('./images/chevron-right-white.svg') no-repeat !important;
    height: 10px;
    opacity: 1;
    margin: 0;
    width: 10px;
  }

  .dashboard .blc-validate-annonce-ctnr a,
  .wall-right-spec .blc-validate-annonce-ctnr a {
    padding: 15px 25px;
    /* margin-left: 20px; */
  }

  .blc-annonce-right .wall-right-spec .blc-validate-annonce-ctnr a {
    margin-left: 0;
  }

  .state-ok {
    background: rgb(89, 193, 229);
    color: #fff;
  }

  /* .state-waiting {
        background: #FF1840;
        color: #FF1840;
    } */

  .dashboard .date-fin-annonce {
    margin-bottom: 10px;
    border-left: none;
  }

  .chercheur-item-action {
    display: none;
  }

  .list-contact .chercheur-item-action {
    border-top: 1px solid rgb(234, 234, 234);
    display: inherit;
    padding-top: 20px;
  }

  .list-contact>.put-inline {
    flex-direction: column;
    align-items: center;
  }

  .command-attached-list .command-state {
    display: none;
  }

  .command-attached-list .chercheur-item {
    border: none;
    margin: 0;
    width: auto;
  }

  .command-attached-list>.put-inline {
    width: 100%;
  }

  .wrapper-annonce .tab-standard-body>ul>li,
  .search-wrap .tab-standard-body>ul.list-annonce>li {
    width: 100%;
  }

  /*annonce form */
  .annonce-form .blc-annonce-form {
    margin: 0 20px 30px 20px;
  }

  .blc-share-menu-group {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    width: 100%;
  }

  .detailAnnonce-footer .blc-share-menu-group {
    /* width: calc(100% - 250px); */
    max-width: 250px;
    position: relative;
    margin: 0;
    padding-bottom: 30px;
  }

  .detailAnnonce-footer .blc-share-menu-group .btn-annonce {
    margin-bottom: 14px;
  }

  .blc-share-menu-group>div,
  .blc-share-menu-group>button {
    margin: 0 8px 20px;
  }

  .blc-share-menu-group>button,
  .blc-share-menu-group>.btn-edit-annonce {
    width: 220px;
    margin-bottom: 14px;
  }

  .infos-wrapper-annonce .interet-txt {
    padding: 0 10px;
  }

  .detailAnnonce-footer .interet-txt {
    font-size: 26px;
    /* padding-right: 30px; */
    padding-bottom: 30px;
    width: 250px;
    flex: 0 0 auto;
  }

  .form-any-creation.blc-company {
    margin: 18px 20px;
    padding-bottom: 0;
  }

  .form-any-creation .pageCreateForm .popForm,
  .form-any-creation .css-epgpks {
    min-width: unset;
  }

  /** ---- FAVORIS PAGE ---- **/
  .favoris-page .blc-content-tab {
    padding: 22px 0;
  }

  .favoris-page .ldropdown.toggleDropDown .btn,
  .search-wrap .ldropdown.toggleDropDown .btn {
    height: 100%;
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .favoris-page .blc-head-publication .ldropdown.toggleDropDown .btn {
    height: 20px;
    width: 20px;
  }

  .favoris-page .blc-tab-title {
    display: flex;
  }

  .favoris-page .blc-content-tab .blc-tab-title ul>li,
  .search-wrap .blc-content-tab .blc-tab-title ul>li {
    width: 100%;
    padding: 12px 5px !important;
  }

  .favoris-page .blc-tab-title .btn.lpicto-menu:after {
    right: unset;
  }

  .favoris-page .blc-tab-title .ldropdown.toggleDropDown.active .btn.lpicto-menu:after,
  .search-wrap .blc-tab-title .ldropdown.toggleDropDown.active .btn.lpicto-menu:after {
    background: url(./images/ico-menu-active-gradient1.svg) no-repeat;
  }

  .favoris-page .blc-tab-title .ldropdown.toggleDropDown ul,
  .search-wrap .blc-tab-title .ldropdown.toggleDropDown ul {
    width: 250px;
  }

  .favoris-page .blc-content-tab .blc-tab-title ul li:not(.active) a,
  .search-wrap .blc-content-tab .blc-tab-title ul li:not(.active) a {
    color: #4a4a4a !important;
  }

  .search-wrap .blc-content-tab .blc-tab-title ul li.desabled:not(.active) a {
    color: #cccccc !important;
  }

  .favoris-page .blc-content-tab ul {
    width: 100%;
  }

  .favoris-page .blc-prod-filter {
    padding: 0;
  }

  .favoris-page .blc-prod-filter .blc-prod-search-bar,
  .favoris-page .blc-prod-filter .blc-select-container,
  .favoris-page .blc-prod-filter .blc-prod-action {
    flex: 1 1 auto;
    height: 30px;
  }

  .favoris-page .blc-prod-filter .search-text {
    display: block;
  }

  .favoris-page .blc-prod-filter .search-form>.search-text,
  .favoris-page .blc-prod-filter .blc-prod-action .btn,
  .favoris-page .blc-prod-filter .search-form>div {
    height: 30px;
  }

  .favoris-page .blc-prod-filter .btn-send-comment {
    max-width: 52px;
    align-items: center;
  }

  .favoris-page .blc-prod-filter .btn-send-comment .lpicto-search {
    position: static;
  }

  /* ---- favoris page corporation ---- */
  .favoris-page .corporation-list ul {
    padding: 15px 20px;
  }

  .favoris-page .corporation-list ul li,
  .favoris-page .corporation-list .img-profil-ctnr {
    margin: 0;
  }

  .favoris-page .corporation-list .tag-ctnr {
    min-width: 100px;
  }

  .favoris-page .corporation-list .nameJob-ctnr {
    padding-left: 0 5px 0 10px;
    width: 100%;
  }

  .favoris-page .corporation-list .btn.lpicto.lpicto-favoris {
    margin-left: 5px;
  }

  .favoris-page .production-list .brevet-info-content .list-profil-icon {
    width: auto;
  }

  .favoris-page .blc-select-container.lselect-secondary:after {
    top: 9px;
  }

  .favoris-page .contact-list .blc-contact-relation {
    padding: 5px 20px;
  }

  .favoris-page .contact-list .blc-contact-item-right {
    padding: 0;
  }

  .favoris-page .tab-standard-body ul.list-annonce {
    padding: 0;
  }

  .favoris-page .tab-standard-body ul li.annonce-item {
    width: 100%;
    padding: 0;
  }

  .favoris-page .wrapper-annonce .tab-standard-body,
  .favoris-page .wrapper-annonce .name-author-ctnr {
    padding: 0;
  }

  .favoris-page .favoris-msg {
    margin: 0;
  }

  ul.pagination li {
    height: 20px;
    padding: 0;
  }

  .blc-newprod-content-ctnr .mb-35px,
  .blc-newprod-content-ctnr .mb-42px {
    margin-bottom: 20px !important;
  }

  .btn-edit-custom {
    right: -10px;
    top: -50px;
  }

  .blc-profil-cover .btn {
    top: 5px;
    right: 5px;
  }

  .all-notification .blc-contact-item-right .btn {
    margin-right: 10px;
    padding: 0 14px;
  }

  .all-notification .blc-contact-item-right .btn:last-child {
    margin-right: 0;
  }

  .all-notification .blc-contact-item-right {
    min-width: 90px;
  }

  .all-notification .blc-contact-item-right {
    justify-content: flex-end;
  }

  .all-notification .blc-contact-item {
    padding: 18px 10px;
  }
}

@media (max-width: 575px) {

  /* ---- inscription betatest ---- */
  .landing .header-title h1 {
    font-size: 26px;
    line-height: 36px;
    padding: 0 27px 15px;
    letter-spacing: -1px;
  }

  .landing .header-title p {
    font-size: 12px;
    line-height: 22px;
  }

  .landing .header {
    background-size: 120%;
  }

  /* --- profil page --- */
  body #root.with_header .profil-page .blc-profil-info {
    padding: 70px 35px 0;
  }

  /* --- relation ---- */
  .p-relation .blc-prod-search-bar {
    /* min-width: 77px; */
    margin: 0 8px;
    flex: 0 0 77px;
  }

  .p-relation .search-text {
    display: none;
  }

  .p-relation .search-bar .search-form {
    justify-content: center;
  }

  .p-relation .btn-send-comment {
    width: 100% !important;
    height: 25px;
  }

  /* .video-react .video-react-video {
        max-height: calc(100vh - 0px);
    } */

  /* edit entrprise */
  .editProfil-page.edit-entreprise .header-page {
    height: auto;
  }

  .editProfil-page.edit-entreprise .header-page .container {
    padding: 30px 40px;
  }

  .editProfil-page.edit-entreprise .header-page .container h2.clr-txt2 {
    font-size: 35px;
  }

  .editProfil-page.edit-entreprise .container .content-form {
    padding-top: 0;
  }

  /*pagination*/
  ul.pagination li {
    font-size: 13px;
  }

  ul.pagination li.chevron-right,
  ul.pagination li.chevron-left {
    background-size: 9px !important;
  }

  .annonce-form .formAction .put-inline {
    flex-direction: column;
  }

  .annonce-form .formAction .btn {
    margin: 0 0 5px 0;
  }
}

@media (max-width: 488px) {

  /** --- global --- **/
  .m-show_488 {
    display: initial;
  }

  .m-hide_488 {
    display: none;
  }

  /** --- end global --- **/

  /*CONNEXION*/

  .imageResizerBackground .profilInfoDialog.login-dialog {
    max-width: none;
    width: 95% !important;
  }

  /***------****/
  .feedContainer .blc-head-publication {
    position: relative;
  }

  /*.feedContainer .blc-inner-comments .blc-head-publication {
        padding-left: 5px;
        padding-right: 5px;
    }*/
  .newsfeed .blc-comment-item .date-pub {
    right: 26px;
    top: 12px;
  }

  .feedContainer .blc-comment-item .comment-option {
    right: 5px;
  }

  .feedContainer .newsfeed .usr-info-pub,
  .favoris-page .feedContainer .usr-info-pub {
    max-width: calc(100% - 78px);
  }

  .newsfeed .blc-comment-item .blc-comment-lvl-container .date-pub,
  .newsfeed .blc-comment-item .blc-comment-lvl-container .comment-option {
    position: static;
    min-width: unset;
    flex: none;
  }

  .newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication {
    width: 100%;
  }

  .newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication .usr-info-pub {
    width: calc(100% - 55px);
    margin-right: auto;
    padding-right: 8px;
  }

  .newsfeed .blc-detail-comment .blc-comment-lvl-container .date-pub span {
    display: block;
    text-align: center;
  }

  .newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication .comment-option button.lpicto-menu {
    width: 15px;
  }

  .blc-comment-item .date-pub {
    /* width: 20%;
        flex: 0 0 auto; */
    position: absolute;
    right: 10px;
    top: 22px;
    min-width: 60px;
    flex: 1 1 auto;
    text-align: right;
  }

  .newsfeed .blc-comment-content span {
    font-size: 11px;
  }

  .newsfeed .blc-comment-item .date-pub span {
    font-size: 9px;
  }

  .feedContainer .blc-comment-content {
    padding-top: 20px;
  }

  .feedContainer .blc-comment-item .comment-option {
    position: absolute;
    right: 0;
  }

  .corporation-feed .blc-head-publication,
  .feedContainer .blc-head-publication {
    padding-left: 14px;
    padding-right: 14px;
  }

  .corporation-feed .lpicto-menu:after,
  .feedContainer .lpicto-menu:after {
    width: 4px;
    height: 13px;
  }

  .favoris-page .feedContainer .btn-option-pub {
    margin-left: 5px;
  }

  /* .btn.lpicto-send{
        padding: 0 10px;
    } */
  .btn-option-pub .btn.toggleDropDown {
    /* padding:0;
        width: 20px; */
    margin-left: 0;
  }

  /* .profilInfo .blc-profil-actions button.add-feed{
        min-width: 100px;
    } */
  .no_header .uploadProfil {
    max-height: calc(100vh - 120px);
    margin-top: 30px;
  }

  .uploadProfil {
    /* max-height: calc( 100vh - 180px);  */
    margin-bottom: 100px;
  }

  .invit .btn {
    margin: 10px 0 0 0;
  }

  .publish-dialog .blc-pub-area .blc-img-profil {
    border-radius: 100%;
    height: 50px;
    padding: 0;
    position: absolute;
    left: 50%;
    top: -45px;
    transform: translateX(-50%);
    width: 50px;
    z-index: 9;
  }

  .publish-dialog .blc-img-profil img {
    height: 100%;
    width: 100%;
  }

  .publish-dialog .feedComposerBackground {
    padding: 0;
  }

  .publish-dialog .second-area-container {
    width: 100%;
    padding: 30px 5px 5px;
  }

  .blc-dialogForm.publish-dialog {
    padding: 20px;
  }

  .blc-dialogForm .popTitle-ctnr {
    padding-top: 0;
  }

  .blc-list-profil-cntr .list-corporation-item .tag-usr-profil {
    max-width: 50%;
  }

  .blc-list-profil-cntr .list-corporation-item .tag-usr-profil .btn-30 {
    font-size: 10px;
  }

  .list-profil-item.list-corporation-item .nameJob-ctnr span {
    font-size: 11px;
    max-height: 4ch;
    overflow: hidden;
  }

  .publish-dialog .blc-pub-action-left .btn {
    padding: 0;
    margin: 0 30px 0 0;
  }

  .publish-dialog .blc-pub-action-left {
    flex-wrap: nowrap;
  }

  .publish-dialog .blc-pub-area {
    padding-left: 0;
    width: 100%;
  }

  .publish-dialog .blc-pub-area textarea {
    font-size: 14px;
  }

  .publish-dialog .blc-pub-area textarea.feedComposerField {
    padding: 0;
  }

  #root.with_header .imageResizerBackground {
    /*overflow: initial;*/
    padding: 120px 5px 25px 5px;
  }

  .publish-dialog.custom-closeBtn .popClosebtn {
    position: absolute;
    top: -25px;
  }

  .publish-dialog .feedComposerBackground {
    border: none !important;
  }

  .publish-dialog .blc-pub-area-action {
    border-top: 1px solid #eaeaea;
    height: auto !important;
    padding: 20px 0 !important;
    justify-content: center;
  }

  .blc-notif-publication {
    padding: 8px 12px;
  }

  .blc-notif-publication .comments {
    font-size: 8px;
    display: flex;
  }

  .blc-notif-publication .comments::after {
    height: 2px;
    line-height: 2px;
  }

  .blc-notif-publication .notif-part-two .comments {
    text-align: left;
  }

  /* .blc-notif-publication::after {
        left: 0;
        width: 100%;
    } */
  .newsfeed .blc-text-publication {
    font-size: 9px;
  }

  .newsfeed .blc-detail-comment,
  .profil-actus .blc-detail-comment {
    padding: 10px 10px;
  }

  .newsfeed .blc-detail-comment .blc-head-publication {
    min-width: 40px;
  }

  .newsfeed .blc-detail-comment .blc-add-comment .blc-white-rounded,
  .newsfeed .blc-detail-comment .blc-inner-add-comment .blc-white-rounded {
    margin: 0;
  }

  .newsfeed .blc-add-comment .usr-pub,
  .newsfeed .blc-inner-add-comment .usr-pub {
    width: 40px;
    height: 30px;
  }

  .newsfeed .blc-add-comment .blc-white-rounded,
  .newsfeed .blc-inner-add-comment .blc-white-rounded {
    /*height: 30px;*/
    min-height: 29px;
    padding-left: 10px;
    margin-left: 10px;
  }

  .newsfeed .blc-detail-comment .comment-text {
    padding: 7px 0 4px;
  }

  .newsfeed .blc-add-comment .blc-white-rounded textarea,
  .newsfeed .blc-inner-add-comment .blc-white-rounded textarea {
    font-size: 10px;
  }

  .newsfeed .blc-detail-comment .blc-inner-comments .blc-comment-item .blc-comment-content {
    padding: 0;
  }

  .newsfeed .blc-detail-comment .blc-comment-item .blc-comment-content {
    padding-top: 8px;
  }

  .newsfeed .blc-head-publication .usr-pub.usr-pub-img img {
    min-height: 30px;
    min-width: 30px;
    height: 37px;
    width: 37px;
  }

  .newsfeed .blc-detail-comment .blc-inner-comments,
  .newsfeed .blc-detail-comment .blc-inner-add-comment {
    padding-left: 40px;
  }

  .newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item {
    margin-left: 40px;
  }

  .newsfeed .blc-detail-comment .usr-pub img {
    height: 30px;
    width: 30px;
  }

  .newsfeed .blc-detail-comment .lpicto-video {
    padding: 0 10px 0 5px;
  }

  .newsfeed .blc-detail-comment .lpicto-video:after {
    width: 16px;
    height: 16px;
  }

  .newsfeed .blc-head-publication {
    padding: 15px 12px 0;
  }

  .blc-head-publication {
    padding: 6px 20px 0 12px;
  }

  .blc-head-publication .usr-pub {
    flex: 0 0 37px;
    line-height: 15px;
  }

  .profil-enterprise .usr-info-pub,
  .feedContainer .usr-info-pub {
    width: calc(100% - 90px);
    flex: 1 1 auto;
  }

  .blc-text-publication .display-txt {
    padding: 10px 12px;
  }

  .blc-text-publication .show-more {
    padding: 0;
  }

  .blc-btn-action-publication .btn-action-item:nth-of-type(3) {
    flex: 0 0;
    width: auto;
  }

  .blc-btn-action-publication {
    justify-content: space-between;
  }

  .imageResizerBackground {
    align-items: flex-start;
  }

  .imageResizerBackground .blc-dialogForm.custom-closeBtn,
  #root.with_header .blc-dialogForm.publish-dialog {
    margin-top: 60px;
  }

  .popTitle-ctnr.share-listContact {
    margin: 10px 0 0;
    padding: 10px;
  }

  .publish-dialog .label-form {
    margin: 15px 0 10px;
  }

  .post-ToShare {
    margin-bottom: 30px;
  }

  .blc-comment-content {
    padding: 0 5px 0 56px;
    width: 100%;
  }

  .blc-inner-comments {
    padding-left: 16px;
  }

  .headerPage-left h1.blc-title-newprod {
    padding: 20px 0;
  }

  .blc-newprod-content {
    margin-top: 30px;
  }

  .action-bottom-right.spec-btn-action {
    top: 52px;
  }

  .rowForm.put-inline {
    flex-wrap: wrap;
  }

  .blc-dialogForm.preview {
    box-shadow: none;
  }

  .brevet-head {
    padding: 20px 10px;
  }

  .brevet-desc-content {
    padding: 10px !important;
  }

  .brevet-link-info {
    padding: 10px;
    flex-wrap: wrap;
  }

  /* PROFIL */
  .lprofilPM-name center {
    padding-top: 20px;
  }

  .blc-profil-top .blc-coord li {
    margin-right: 4px;
    /* overflow: hidden; */
  }

  /* dashboard*/
  /* .annonce-about {
        flex-direction: column;
        justify-content: center;
    } */
  .dashboard-list ul li {
    margin-bottom: 50px;
  }

  .annonce-about>span,
  .annonce-about>div {
    margin-bottom: 18px;
    text-align: center;
    justify-content: center;
  }

  .dashboard .annonce-about {
    margin-bottom: 50px;
  }

  .dashboard .annonce-about .btn-cmd {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .dashboard .annonce-about .btn-cmd .btn {
    border-radius: 0 !important;
    width: 100%;
  }

  .blc-dashboard-right .btn-40 {
    /* margin-left: 30px; */
    width: 60px;
  }

  .dashboard .blc-validate-annonce-ctnr,
  .wall-right-spec .blc-validate-annonce-ctnr {
    display: flex;
    padding: 23px 20px;
    justify-content: space-between;
  }

  .blc-annonce-right .wall-right-spec.wall-right,
  .dashboard .blc-dashboard-right .wall-right {
    margin: 0;
    width: 100%;
  }

  .command-attached-list .chercheur-info {
    display: none;
  }

  .command-attached-list>.put-inline {
    justify-content: center;
  }

  .blc-annonce-filter .blc-select-container {
    font-size: 10px;
    width: 170px;
  }

  .blc-annonce-right .wall-right-spec.wall-right {
    display: block;
    margin-left: 0;
    width: 100%;
  }

  .blc-annonce-right .wall-right-spec .blc-validate-annonce-ctnr {
    display: flex;
    padding: 23px 20px;
    justify-content: space-between;
  }

  .blc-annonce-right .blc-validate-annonce-ctnr .title-new-annonce,
  .blc-dashboard-right .blc-validate-annonce-ctnr .title-new-annonce {
    font-size: 13px !important;
    text-align: left;
    padding-right: 25px;
    margin: auto 0;
  }

  .detailAnnonce-footer .blc-share-menu-group button {
    /* width:20px; */
  }

  /* .detailAnnonce-footer .blc-share-menu-group .lpicto-favoris,
    .detailAnnonce-footer .blc-share-menu-group .lpicto-share-primary{
        flex:0 0 20%;
    } */
  .annonce-page select,
  .wrapper-annonce select {
    font-size: 10px;
  }

  .blc-dash-filter span {
    display: none;
  }

  .sm-show {
    display: block;
  }

  .sm-hide {
    display: none;
  }

  .blc-dash-search-bar {
    margin-left: 10px;
  }

  .popUpSearch .popForm {
    display: block;
  }

  .popUpSearch .blc-dash-search-bar {
    margin-left: 0;
    width: 100%;
  }

  .popUpSearch .formAction {
    max-width: 150px;
    margin: auto;
  }

  .sm-show.lpicto-search {
    border: 1px solid rgba(51, 51, 51, 0.3);
    border-radius: 30px;
    display: block;
    height: 34px;
    margin: auto;
    position: relative;
    width: 77px;
  }

  .sm-show.lpicto-search::after {
    position: relative;
  }

  .blc-dash-filter {
    margin-left: 8px;
  }

  .dashboard .blc-dash-filter .btn {
    padding: 0 10px;
    height: 34px;
  }

  .dashboard .blc-dash-search-bar {
    flex: none;
  }

  .dashboard .blc-filter-annonce .blc-annonce-filter {
    flex: 1;
  }

  .popTitleCtnr-spec {
    background: rgb(249, 249, 249);
    justify-content: space-between;
    margin: 0 -20px;
    padding: 20px;
  }

  .popTitleCtnr-spec .chevron-left {
    background-size: 12px 12px !important;
    height: 12px;
    width: 12px;
  }

  .popTitleCtnr-spec~.popForm {
    max-height: none;
  }

  .popTitleCtnr-spec .popClosebtn:before {
    position: relative;
    right: 10px;
  }

  .popForm .formAction button {
    margin-bottom: 10px;
    width: 100%;
  }

  .popForm .formAction {
    display: block;
  }

  .popForm .popTitle,
  .popup-spec .popTitle {
    font-size: 26px;
  }

  .popFrom-indication {
    font-size: 10px;
  }

  .label-secondary {
    font-size: 14px;
  }

  /*notification */
  .notification-dropContent.rounded.shadowed {
    background: rgb(249, 249, 249);
    height: 100vh;
    right: auto;
    position: fixed;
    left: 0;
    width: 100vw;
    z-index: 9;
  }

  .notification-list {
    background: #fff;
  }

  .notification-list-container {
    box-shadow: none;
    padding: 20px 20px 9.5px;
  }

  .notification-list-container .blc-contact-item {
    padding: 10px;
  }

  .notification-history {
    margin-top: 5px;
  }

  .notification-list-container .notif-time-categ {
    margin-bottom: 5px;
  }

  .all-notification.container>h1 {
    font-size: 25px;
    text-align: center;
    margin: auto;
    width: 96%;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  /* annonce*/
  .annonce-body {
    margin: 10px;
  }

  .wrapper-annonce .blc-annonce-item .annonce-body {
    padding: 10px;
    margin-bottom: -40px;
  }

  .wrapper-annonce .blc-content-tab .blc-tab-title>ul {
    width: 100%;
  }

  .annonce-page .blc-annonce-search {
    padding-top: 50px;
  }

  .annonce-page .blc-annonce-search h2 {
    margin-bottom: 22px;
    padding-top: 50px;
    text-align: center;
  }

  .annonce-page .blc-annonce-search .form-search .row-form {
    flex-wrap: nowrap;
  }

  .blc-annonce-search h2 {
    font-size: 26px;
    text-align: center;
    padding-top: 70px;
  }

  .blc-annonce-search .blc-select-container,
  .blc-annonce-search .btn-search-annonce {
    height: 25.5px;
  }

  .blc-annonce-search .search-form {
    margin: 0 20px 20px;
  }

  /* .blc-annonce-center .blc-filter-annonce {
        padding: 0 20px;
    } */
  .annonce-ref {
    justify-content: center;
  }

  .annonce-ref>div {
    margin-top: 10px;
  }

  .blc-annonce-item .annonce-about {
    align-items: flex-start;
  }

  .annonce-about .info-author,
  .annonce-about .date-fin-annonce,
  .annonce-body .annonce-location {
    flex-direction: column;
  }

  .annonce-body .localtarif-annonce span {
    margin-left: 0;
  }

  .annonce-about .date-fin-annonce .expiredAnnonce {
    padding-left: 0;
  }

  .blc-tab-title-spec.blc-tab-title>ul>li:nth-child(4),
  .blc-tab-title-spec.blc-tab-title>ul>li:last-child {
    display: none;
  }

  .blc-tab-title-spec {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }

  .blc-tab-title-spec .sm-tab ul {
    display: block;
  }

  .sm-tab ul>li {
    border-bottom: 1px solid #eaeaea;
    padding: 10px !important;
    width: 100% !important;
  }

  .linkinnov-premium-user .notif-premium-user {
    flex-direction: column;
  }

  .notif-premium-user>div {
    margin-top: 15px;
  }

  .annonce-form .annonce-visuel-img {
    flex: 0 0 68px;
    height: 68px;
    margin-right: 15px;
    width: 68px;
  }

  .in-file-photos {
    width: auto;
  }

  .blc-profil-header-form .flex-center {
    align-items: flex-start;
  }

  .annonce-form .rowForm {
    margin: 0;
    padding: 15px 0;
  }

  .annonce-form .label-form {
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .detailAnnonce .bloc-annonce-body {
    margin: 0 20px;
    max-width: 100%;
    /* top: 140px; */
  }

  .detailAnnonce .annonce-body,
  .detailAnnonce .accordion {
    padding: 0 20px;
  }

  .detailAnnonce-footer {
    align-items: center;
    flex-direction: column;
    padding: 0 0 20px 0;
  }

  .detailAnnonce-footer .interet-txt {
    padding-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .detailAnnonce .container {
    top: -180px;
    margin-bottom: -180px;
  }

  .detailAnnonce .desc-annonce {
    flex: 0 0 100%;
  }

  .detailAnnonce .desc-annonce .annonce-ref {
    justify-content: center;
  }

  .detailAnnonce .info-date {
    justify-content: center;
  }

  .detailAnnonce .taglist-annonce,
  .detailAnnonce .date-publicate {
    flex: 0 0 100%;
    text-align: center;
  }

  .detailAnnonce .taglist-annonce {
    margin: 0 0 30px 0;
  }

  .detailAnnonce .tags-wrapper-annonce {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .detailAnnonce .lpicto-loc:after {
    margin-right: 0;
  }

  .detailAnnonce .title-accordion {
    font-size: 10px;
  }

  .detailAnnonce .desc-annonce .title-annonce {
    font-size: 14px;
    text-align: center;
  }

  .blc-company .label-form,
  .blc-company .pageForm {
    margin-top: 0;
  }

  .blc-company .pageForm br {
    display: none;
  }

  .blc-company .required-field-notif {
    margin: 5px 0 0;
  }

  .in-file-photos {
    min-height: 40px;
    height: auto;
  }

  /* .popUpSignal {
        max-height: calc(100vh - 100px);
        overflow: auto;
        top: 0;
    } */
  .popin-group-manage .blc-contact-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .popin-group-manage .lprofilPm-item,
  .popin-group-manage .blc-contact-item-right {
    width: 100%;
  }

  /** ---- FAVORIS PAGE --- search result page ---- **/
  .favoris-page .blc-content-tab .blc-tab-title ul>li.sm-hide,
  .search-wrap .blc-content-tab .blc-tab-title ul>li.sm-hide {
    display: none;
  }

  .favoris-page .blc-tab-title .ldropdown.toggleDropDown.sm-show,
  .search-wrap .blc-tab-title .ldropdown.toggleDropDown.sm-show {
    display: block;
    max-width: 90px;
  }

  .favoris-page .blc-prod-filter .blc-prod-search-bar {
    width: 55px;
  }

  .favoris-page .blc-prod-filter .blc-select-container {
    width: 100%;
  }

  .favoris-page .blc-prod-filter .search-text {
    display: none;
  }

  .blc-dialogForm.popup-spec {
    padding-top: 0;
  }

  .favoris-page .usr-pub img {
    height: 37px;
    width: 37px;
  }

  /* ---- favoris page corporation - production - contact ---- */
  .favoris-page .corporation-list ul {
    padding: 10px 12px;
  }

  .favoris-page .corporation-list ul li,
  .favoris-page .corporation-list .img-profil-ctnr {
    margin: 0;
  }

  .favoris-page .corporation-list .tag-ctnr {
    min-width: 74px;
  }

  .favoris-page .corporation-list .tag-ctnr .tag-usr-profil {
    padding: 8px;
    font-size: 9px;
  }

  .favoris-page .corporation-list .nameJob-ctnr {
    font-size: 11px;
  }

  .favoris-page .corporation-list .btn.lpicto.lpicto-favoris {
    margin-left: 5px;
  }

  .favoris-page .production-list .blc-smallbr-list {
    padding: 12px 12px;
  }

  .favoris-page .production-list .blc-smallbr-list .blc-smallbr-item {
    padding: 0;
  }

  .favoris-page .contact-list .blc-contact-relation {
    padding: 0;
  }

  .favoris-page .contact-list .blc-contact-relation .blc-contact-item-right,
  .favoris-page .contact-list .blc-contact-item-left {
    flex: none;
    width: auto;
    padding: 0;
  }

  .favoris-page .contact-list .blc-contact-item-right .btn {
    margin-right: 5px;
  }

  /* ----- HEADER ---- */
  .header-mobil .global-header-left .main-nav .nav-item.right-item {
    max-width: 79%;
  }

  .header-mobil .global-header-left .main-nav .nav.goback_active .nav-item.right-item {
    max-width: 85%;
  }

  .landing .header-title p {
    width: 82%;
  }

  .sm488-long-text {
    width: 100%;
  }

  .rowForm.partedtwo {
    width: 100%;
  }

  .rowForm.put-inline .rowForm.partedtwo:last-child {
    margin-bottom: 0;
  }

  .blc-action-bottom-newprod {
    flex-wrap: wrap;
  }

  .blc-action-bottom-newprod button {
    margin: 5px;
  }

  .delete-dialog-confirm .min-width-115 {
    min-width: 0;
  }

  .list-contact-group-creation .blc-contact-item-right {
    min-width: 0;
    width: 50px;
  }

  .popTitle {
    font-size: 20px;
    margin-left: 0;
    margin-right: 18px;
    padding: 0 10px;
    margin-bottom: 5px;
  }

  .blc-dialogForm.blc-skillDialog {
    width: 100%;
  }

  .blc-contents-accordion .parcours-action button,
  .blc-contents-accordion .blc-compt-item-ctnr-right button,
  .blc-contents-accordion .diplom-action button {
    padding: 0;
  }

  .invitation-group .layout-medium {
    max-width: calc(100% - 30px);
  }

  .publish-dialog .popTitle-ctnr {
    margin: -31px -21px 0 -21px;
    padding: 10px;
  }
}

@media (max-width: 420px) {

  /* --- profil page --- */
  .profil-enterprise .blc-profil-actions div button {
    padding: 2px 7px;
  }

  .profilInfo .blc-profil-actions button.add-feed {
    padding: 0 14px;
    margin-right: 7px !important;
  }

  .blc-profil-actions .put-inline {
    justify-content: space-between;
  }

  .blc-profil-actions .put-inline.half-putinline:nth-of-type(1) {
    /* flex-direction: column; */
  }

  /* .blc-profil-actions .put-inline.half-putinline:nth-of-type(1) button{
        margin: 5px auto;
    } */

  .profil-page .blc-profil-actions .put-inline.half-putinline:nth-of-type(1) button.add-feed {
    margin: 5px auto !important;
  }

  body #root.with_header .profil-page .blc-profil-info {
    padding: 70px 25px 0;
  }

  body #root.with_header .profil-page.container {
    padding: 0 20px !important;
  }

  .profil-page.container .profil-info-center {
    margin: 0;
  }

  .recommanded-nbr {
    line-height: normal;
    padding: 5px 5px 5px 7px;
  }

  .blc-coord {
    width: auto;
    max-width: 60%;
    margin: 0;
    padding-left: 0;
  }

  .blc-stat {
    width: auto;
    max-width: 40%;
    margin: 0 0 0 auto;
    padding-right: 0;
  }

  /* ---- group view ---- */
  .group-view-actu .blc-group-filter .search-text,
  .group-list .blc-group-filter .search-text {
    display: none;
  }

  .group-view-actu .blc-group-filter .blc-select-container {
    width: 100%;
  }

  .group-view-actu .blc-menu-group .put-inline {
    justify-content: center;
  }

  .group-view-actu .blc-menu-group .sub-menu-group {
    flex: 0 0;
  }

  .group-view-actu .btn-manage-members.btn {
    font-size: 10px;
    line-height: 10px;
    padding: 0 15px;
  }

  .group-list .blc-group-filter .blc-group-search-bar {
    flex: 0 0;
  }

  .group-view-actu .profil-page .profil-info-center {
    margin-left: 2px;
    margin-right: 2px;
  }

  /* paramètre de compte */
  .blc-parametrage .blc-param-compte .blc-param-content.container {
    flex-flow: column;
    padding: 0 24px;
  }

  .blc-param-compte .abonnement-subtitle .day-left {
    display: block;
    margin: 5px 0;
  }

  /*---- annonce ----*/
  .annonce-page .bloc-annonce-header {
    background-size: 135%;
    background-position-x: center;
  }

  /* --- news feed --- */
  .newsfeed .lshare {
    padding: 12px;
  }

  .newsfeed .lshare .blc-profil-header {
    padding: 0;
  }

  .newsfeed .blc-head-publication .usr-info-pub .usr-pub,
  .newsfeed .lshare .usr-info-pub span {
    font-size: 11px;
  }

  .newsfeed .usr-info-pub .role-pub,
  .newsfeed .usr-info-pub .date-pub,
  .favoris-page .usr-info-pub .role-pub,
  .favoris-page .usr-info-pub .date-pub {
    font-size: 9px;
    line-height: 11px;
  }

  .newsfeed .lpicto-favoris:after,
  .favoris-page .lpicto-favoris:after {
    height: 20px;
    width: 20px;
    background-size: 100% 100% !important;
  }

  .newsfeed .blc-detail-comment .blc-comment-lvl-container .date-pub {
    display: none;
  }

  .newsfeed .blc-detail-comment .blc-comment-lvl-container .usr-info-pub .date-pub {
    display: block;
    position: static;
    min-width: unset;
    width: auto;
    text-align: left;
    padding: 5px 0;
  }

  .newsfeed .blc-detail-comment .blc-comment-lvl-container .usr-info-pub .date-pub span {
    display: inline;
    font-weight: 300;
    font-size: 9px;
  }

  /*.newsfeed .blc-detail-comment .blc-comment-lvl-container .usr-info-pub .date-pub span:before{
        content: ".";
        display: inline-block;
        font-size: 16px;
        margin: 0 5px;
        height: 15px;
    }*/

  .favoris-page .blc-annonce-item .lpicto-favoris:after {
    height: 30px;
    width: 25px;
    background-size: initial !important;
  }

  /** ---- relation ---- **/
  .p-relation .blc-contact-relation .blc-contact-item {
    padding: 0 10px;
  }

  .p-relation .contactActions .blc-prod-action .btn {
    width: 40px;
    padding: 0 10px;
    display: flex;
  }

  .p-relation .contactActions .blc-prod-action .btn span {
    display: none;
  }

  /* ---- header search ---- */
  .header-mobil .global-header-left .Search-itemList .search-result>div {
    flex: 0 0 100%;
  }

  .header-mobil .global-header-left .Search-itemList .search-result>div:nth-child(2) .searchmenu-header {
    border-top: 2px solid #eaeaea;
  }

  /* HEADER inscription  */
  .landing .header {
    background-size: 145%;
  }

  /* ----- FOOTER inscription ----- */
  .footer-home .nav .separated_footer_bloc ul li.nav-item {
    /*margin-left: 0;
        margin-right: auto;*/
  }

  .footer-home .nav .separated_footer_bloc ul li.nav-item a {
    /*text-align: left;*/
  }

  /* ---- favoris -- search page ---- */
  .favoris-page .blc-tab-title .ldropdown.toggleDropDown.sm-show,
  .search-wrap .blc-tab-title .ldropdown.toggleDropDown.sm-show {
    max-width: 60px;
  }

  .blc-menu-group>.put-inline button {
    margin: 0 10px 10px;
  }

  /* search page */
  .search-wrap-mobil .list-profil-item.list-corporation-item .tag-ctnr {
    padding: 8px 10px;
  }

  .search-wrap-mobil .list-profil-item.list-corporation-item .tag-ctnr span {
    font-size: 9px;
  }

  .search-wrap .tab-standard-body .list-corporation-item .tag-usr-profil span.corporation-clr {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
  }

  /* edit entrprise */
  .editProfil-page.edit-entreprise .header-page .container {
    padding: 30px 25px;
    height: auto;
  }

  .editProfil-page.edit-entreprise .header-page .container h2.clr-txt2 {
    font-size: 20px;
  }

  /* chat */
  .chat-container .container-input-message2 {
    flex-direction: column;
    justify-content: flex-start;
  }

  .chat-container .container-input-message2 .input-message {
    width: 100%;
    text-align: left;
  }

  .chat-container .message-action-container {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 360px) {
  .blc-company .file-chooser-container .file-photos .in-file-photos label {
    padding-right: 10px;
  }

  .blc-company .file-chooser-container .file-photos .in-file-photos:after {
    line-height: 0;
    top: 35%;
  }

  .global-header-right {
    min-width: 320px;
  }
}

/*---------------*/
@media (min-width: 768px) {
  .blc-profil-top .blc-coord li {
    margin-right: 4px;
    overflow: hidden;
  }

  .detailAnnonce .blc-annonce-item .desc-annonce {
    /*flex: 0 1 62%;*/
  }

  .detailAnnonce .info-annonce {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .favoris-page .blc-contact-relation .blc-contact-item-right {
    min-width: 190px;
    justify-content: flex-start;
    padding: 0;
  }

  .footer-home .logo,
  .landing .header .header-top .logo {
    width: 105px;
  }
}

@media (min-width: 768px) {

  /** --- CHAT --- **/
  .chat-container .chat-new-container .blc-chat.left-chat .chat-toobar .chat-avatar {
    display: none;
  }

  .chat-container .bg-249 {
    background-color: inherit;
  }

  .chat-container .blc-chat .chatInfo-user .btn-goback {
    display: none;
  }
}

@media (min-width: 420px) and (max-width: 767px) {
  .chat-container .blc-chat.mobile-active {
    padding: 5px 40px;
  }
}

@media (max-width: 508px) {
  .blc-share-menu-group .put-inline {
    padding: 0 44px;
  }
}

/******---- betatest - responsive ----******/
/* height */
@media (min-height: 1024px) {
  .landing-betatest .header {
    flex: none;
  }
}

@media (max-height: 575px) {

  /* HEADER  --- footer --- inscription*/
  .landing-betatest .tab-standard-body .container.betatest .signin-submit {
    margin-bottom: 20px;
  }

  .landing .header-title .container {
    height: auto;
  }

  .landing .footer-home {
    padding-top: 14px;
  }

  .landing .footer-home .container::before {
    top: -16px;
  }

  .landing .mtmobile.footer-list-container {
    margin-bottom: 10px;
  }

  .landing .header-title p {
    line-height: 20px;
    padding-bottom: 15px;
  }

  /* chat */
  .chat-container .blc-chat .chat-info header.chatInfo-user {
    flex: none !important;
  }

  .chat-container .blc-chat .chat-info {
    overflow: auto;
  }

  .blc-chat-content .contact-sidebar-container {
    overflow: auto;
  }

  .blc-chat-content .contact-sidebar-container .min_40vh {
    overflow: auto;
    min-height: 40vh;
  }

  .chat-wrap.tchat-center .empty-chat-icon {
    font-size: 6em;
    margin-top: 10px;
  }

  .chat-container .chat-new-container {
    min-height: 325px;
  }

  .blc-chat-ctnr .flex.overflow-y-auto {
    max-height: unset;
  }

  .blc-chat-ctnr .flex.overflow-y-auto {
    padding-bottom: 0;
  }

  /* header -- notification */
  .notification-dropContent.rounded.shadowed .notification-list-container {
    max-height: calc(100vh - 150px);
  }

  /* header profil drop down */

  .is-safari .gbl-header .dropdown-content {
    max-height: calc(100vh - 200px);
  }

  .blc-dialogForm .popTitle-ctnr .popTitle {
    font-size: 16px;
    line-height: normal;
  }

  .list-profil-item.list-corporation-item {
    height: auto;
    min-height: 50px;
  }

  .blc-dialogForm .blc-list-profil-cntr {
    margin: 10px auto 0px auto;
  }
}

@media (max-height: 575px) and (max-width: 767px) and (orientation: landscape) {
  .chat-container .blc-chat.chat-wrap.tchat-center .chat-feed-flux {
    margin-top: 44px;
  }

  .chat-container .blc-chat.chat-wrap.tchat-center .chat-WrapHeader.bg-249 {
    position: fixed;
    top: 110px;
    right: 0;
  }

  /* search */
  .search-wrap .tab-standard-body .list-corporation-item .tag-usr-profil span.corporation-clr {
    font-size: 10px;
  }
}