@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./App.css");
@import url("./normalize.css");
@import url("./resizer.css");
@import url("./contact.css");
@import url("./home.css");
@import url("./parametre-compte.css");
@import url("./innov.css");
@import url("./main.css");
@import url("./innov_mobile.css");
@import url("./home_page.css");
