/* ===== FONTS Montserrat====*/
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.eot');
  src: url('./fonts/Montserrat.eot') format('embedded-opentype'),
    url('./fonts/Montserrat.woff2') format('woff2'),
    url('./fonts/Montserrat.woff') format('woff'),
    url('./fonts/Montserrat.ttf') format('truetype'),
    url('./fonts/Montserrat.svg#Montserrat') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('./fonts/Montserrat-Thin.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('./fonts/Montserrat-Italic.ttf');
}

/* Global */
.al-items-start {
  align-items: flex-start !important;
}

.uppercase {
  text-transform: uppercase;
}

.bkg-cobalt2 {
  background-color: rgba(89, 193, 229, 0.1);
}

.bkg-expert {
  background-color: #f8f1fe;
}

.bkg-body {
  background-color: #f9f9f9;
}

.bg-transp {
  background: transparent;
}

.bg-249 {
  background-color: rgb(249, 249, 249);
}

.fnt-11 {
  font-size: 12px;
}

.fnt-12 {
  font-size: 12px;
}

.fnt-13 {
  font-size: 13px;
}

.fnt-14 {
  font-size: 14px;
}

.fnt-15 {
  font-size: 15px;
}

.fnt-19 {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fnt-22 {
  font-size: 22px;
}

.fnt-26 {
  font-size: 26px;
}

.fnt-45 {
  font-size: 45px;
}

.fnt-50 {
  font-size: 50px;
}

.h-40 {
  height: 40px;
}

.h-100pc {
  height: 100%;
}

.w-40 {
  width: 40px;
}

.height-auto {
  height: auto !important;
  min-height: inherit !important;
}

.plr-35px {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.plr-68 {
  padding-right: 68px;
  padding-left: 68px;
}

.pt-50 {
  padding-top: 50px;
}

.b {
  font-weight: 700 !important;
}

.b2 {
  font-weight: 400 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-black2 {
  border-color: rgba(51, 51, 51, 0.3) !important;
}

.border-black3 {
  border: 2px solid rgba(51, 51, 51, 0.3) !important;
}

.border-black4 {
  border: 2px solid #333333 !important;
}

.border-dark-purple {
  border: 2px solid #a03ee6 !important;
}

.border-cobalt {
  border: 2px solid #59c1e5 !important;
}

.border-linkedin {
  border: 2px solid #2867b2 !important;
}

.btn-40 {
  height: 40px;
}

.border-red {
  border: 1px #f00 solid !important;
}

.square-rounded {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 22px !important;
}

.border_form_violet {
  border: 2px solid #a03ee6 !important;
}

.square-rounded-3 {
  border-radius: 3px !important;
}

.clr-cobalt {
  color: #59c1e5 !important;
}

.inpForm.disciplineSelect.clr-cobalt {
  border-color: #59c1e5 !important;
}

.clr-expert {
  color: #a03ee6 !important;
  position: relative;
}

.clr-mis {
  color: #ff569f !important;
}

.progress {
  width: 100%;
}

.clr-linkedin {
  color: #2867b2 !important;
}

.clr-dark-purple {
  color: #a03ee6;
}

.clr-txt {
  color: rgba(51, 51, 51, 0.6) !important;
}

input:-webkit-autofill {
  color: rgba(51, 51, 51, 0.6) !important;
}

.balela {
  border: 1px solid #59c1e5 !important;
  padding: 30px 30px 30px 21px;
  /* align-items: center;
  justify-content: left; */
  /* width: 76%; */
  /* margin-right: 8px; */
  position: relative;
  top: 20px;
  /* left: 26px; */
  justify-content: flex-start;
  border-radius: 4px;
  display: flex;
}

.balela img {
  display: block;
  width: 60px;
  height: 60px;
}

.m-right-zero {
  margin-right: 0 !important;
}

/*Pagee refonteeee*/
.pageInfo .blc-profil-actions {
  margin-bottom: 20px;
  justify-content: space-between;
}

.like-page {
  min-width: 135px !important;
}

.pageInfo .profil-relation {
  min-width: max-content;
}

.pageInfo .blc-profil-info {
  padding-left: 90px;
  padding-right: 75px;
  padding-top: 101px;
  padding-bottom: 0px;
  min-height: 360px;
}

.pageInfo .blc-stat {
  width: 33%;
}

.pageInfo .lpicto-relation:after {
  height: 12px !important;
  width: 12px !important;
}

.pageInfo .blc-stat {
  width: 33%;
}

.pageInfo .lpicto-entreprise:after {
  height: 12px !important;
  width: 12px !important;
}

.pageInfo .lpicto-loc:after {
  height: 12px !important;
  width: 12px !important;
}

.put-inline.page-tags {
  align-items: center !important;
}

.page-tags .blc-tags {
  margin-bottom: 0%;
}

.pageoptions {
  padding-bottom: 37px;
}

/* .pageoptions a::after{

  position: absolute ;
  content: '';
  background: url(./images/chevron-down.svg) no-repeat center center;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  background-size: 50%;
  left: 50px;
  opacity: 0.6;
} */

.pageoptions li {
  margin-top: 42px;
  margin-left: 20px;
}

.pageoptions .chevron-right {
  margin-right: 14px;
  opacity: 1;
}

.pageoptions li:hover {
  color: #039be5;
}

.pageoptions .active .wall-seeMore {
  color: #039be5 !important;
}

.page-center {
  /* width: calc(100% - 1080px); */
  display: block;
  margin-left: 15px;
  margin-top: 10px;
  width: -webkit-fill-available;
}

.page-caroussel .sticky-block .slick-prev:before,
.slick-next:before {
  /* line-height: 11.3 !important;
  color: transparent;
  margin-left: 38px;
  background: url(./images/chevron-down.svg) no-repeat center center;
  transform: rotate(90deg) ;
  position: absolute;
  opacity: 0.5; */
  /* content: '' !important; */
  content: '';
  pointer-events: none !important;
  margin-top: 10px;
}

.page-caroussel .sticky-block .slick-next:before {
  content: '';
  pointer-events: none !important;
  display: none;
}

.page-caroussel .lslider .slick-arrow:after {
  top: 240px;
}

.page-caroussel .lslider .slick-prev {
  left: -23% !important;
}

.page-caroussel .lslider .slick-next {
  right: 23% !important;
}

.sticky-block .sticky-block-page .item-style {
  width: 100%;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
}

.sticky-block .item-style p {
  margin-top: 10px;
}

/* .page-caroussel .sticky-block .slick-next:before{

   margin-left: -57px; 
   color: transparent;
   transform: rotate(-90deg);
   opacity: 0.5;

} */
.page-right .st-content-tab {
  margin-bottom: 20px;
  padding: 15px;
  padding-bottom: 4px;
}

.page-right .pagedescriptioninfo .tag-usr {
  margin-bottom: 10px;
  margin-right: 10px;
}

.page-center .pagedescription .st-content-tab {
  margin-top: 20px;
  margin-bottom: 20px;
  /* width: 109% !important; */
  padding: 20px;
  padding-bottom: 0px;
}

.page-center .pagedescriptioninfo .tag-usr {
  margin-bottom: 20px;
  margin-right: 15px;
}

.page-center .pagedescription .pageactivite {
  /* margin-bottom: 20px;
  margin-right: 15px; */
  display: flex;
  padding: 20px 25px !important;
}

.page-center .pagedescription .act-button {
  display: flex;
  margin-top: 22px;
}

.page-center .pagedescription button {
  margin-right: 30px;
}

.page-center .pagedescription img {
  padding-right: 30px;
}

.page-center .pagedescription.labo-members img {
  padding-right: 0px;
}

.page-center .pagedescription .pageactivite .info {
  margin-top: 18px;
  color: #039be5 !important;
}

.pagedescription.labo-members .blc-contact-relation {
  padding: 0 25px 10px 0;
}

.pagedescription.labo-members .blc-contact-item {
  height: 50px;
}

.pagedescription.labo-members .blc-contact-info {
  max-height: 50px;
  overflow: hidden;
}

.pageinfopratique {
  width: 50%;
}

.pageinfopratique .info {
  margin: 10px 0px;
}

.pageinfopratique span {
  font-weight: 500 !important;
}

.pagesavoir .lslider .slick-arrow:before {
  content: '' !important;
}

.pagesavoir .lslider .slick-arrow:after {
  width: 40px;
  top: 180px;
}

.pagesavoir .lslider .slick-prev {
  left: -4% !important;
}

.pagesavoir .lslider .slick-next {
  right: -5% !important;
}

.pagesavoir .slick-slide img {
  padding: 10px !important;
}

.pagesavoir .slick-slider {
  margin-top: -25px !important;
}

.pagesavoir .lslider .slick-list {
  max-height: unset;
}

/* .slick-prev:before, .slick-next:before{

  top:50% !important;
} */
/*ENDDD*/

.m-right-15 {
  margin-right: 15px;
}

.m-top-20 {
  margin-top: 20px !important;
}

.m-top-30 {
  margin-top: 30px !important;
}

.m-0px {
  margin: 0px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-42px {
  margin-bottom: 42px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-50px {
  margin-bottom: 50px !important;
}

.mlr-0px {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mlr-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mlr-30px {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mtb-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mtb-30px {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mtb-60px {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.nobg {
  background: transparent !important;
}

.noshadow {
  box-shadow: none !important;
}

.p-bottom-zero {
  padding-bottom: 0 !important;
}

.p-right-zero {
  padding-right: 0 !important;
}

.p-left-zero {
  padding-left: 0 !important;
}

.p-10px {
  padding: 10px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.p-20px {
  padding: 20px !important;
}

.plr-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.plr-22px {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.clear-both {
  clear: both;
}

.radius-3 {
  border-radius: 3px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

/* .w-40 {
    width: 40px !important;
} */
.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.animate-opacity-05 {
  opacity: 0.5;
}

.animate-opacity-08 {
  opacity: 0.8;
}

[class*='animate-opacity-'] {
  transition: opacity ease 0.25s;
  -webkit-transition: opacity ease 0.25s;
  -moz-transition: opacity ease 0.25s;
  -ms-transition: opacity ease 0.25s;
  -o-transition: opacity ease 0.25s;
}

[class*='animate-opacity-']:hover {
  opacity: 1;
}

.child-align-item-center {
  align-self: center;
}

.flex-vertical {
  flex-direction: column !important;
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-direction-mobile {
  align-items: center;
  flex-direction: row;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.error-sign {
  border-color: #ff727d !important;
  color: #ff727d !important;
}

body,
p,
body #root.with_header {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}

button .lpicto-filter:after {
  height: 13px;
  margin-right: 5px;
  background-position: center;
}

.inpNoMargeB {
  margin-bottom: 0 !important;
}

/* ===== HOME LANDING PAGE ====*/

.gradient-border-bottom {
  border-bottom-width: 5px;
  border-style: solid;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background-image: linear-gradient(to right, #182da8, #a007a0);
}

.bkg-grey {
  background: grey;
}

.bkg-gradient {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0)) !important;
  background-image: linear-gradient(to right, #182da8, #a007a0) !important;
}

.bkg-gradient-2 {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#1578fb),
      to(#17ead7)) !important;
  background-image: linear-gradient(to right, #1578fb, #17ead7) !important;
}

.bkg-gradient-3 {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#1f379a),
      to(#af42f0)) !important;
  background-image: linear-gradient(45deg, #1f379a, #af42f0) !important;
}

.bkg-gradient-new {
  background-image: -webkit-gradient(250deg,
      from(#af42f0),
      to(#1f379a)) !important;
  background-image: linear-gradient(250deg, #af42f0, #1f379a) !important;
}

.bkg-gradient-new-light {
  background-image: -webkit-gradient(221deg,
      from(#eddbf4),
      to(#eddbf4)) !important;
  background-image: linear-gradient(221deg, #eddbf4, #eddbf4) !important;
}

/* HEADER */
.header-top.container {
  padding: 0 20px;
}

.header-top.container .logo {
  width: 110px;
}

.usr-info-header {
  width: 170px;
  display: block;
  position: relative;
}

.usr-option {
  align-items: center;
  display: flex;
  border-left: solid 1px rgba(51, 51, 51, 0.3);
  height: 20px;
}

.side-nav-links {
  padding: 0 20px;
}

.side-nav-links li {
  margin-bottom: 6px;
  text-align: center;
}

.side-nav-links li a {
  color: #999 !important;
  font-size: 11px;
}

.side-nav-links li a:hover {
  color: #666 !important;
}

.nav-annonce span {
  padding: 10px 14px;
}

/*.landing .header{
    background-color: #faf4fe;
}*/
.landing .header-title h1 span {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.landing .header-title h1 span,
.landing .header .header-top.container .logo,
.landing .header .header-top.container .btn-login,
.landing .header .header-top.container .btn-login::before {
  background-color: #faf4fe;
  flex: 0%;
}

.header-top .rw {
  justify-content: space-between;
  align-items: flex-end;
  height: 100px;
  padding-bottom: 20px;
}

.header-top .logo svg {
  max-width: 182px;
  height: auto;
}

.header-top .btn-login button {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.header-top .btn-login button::before {
  background: rgba(0, 0, 0, 0) url(images/account.svg) no-repeat center center;
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  left: -35px;
  background-size: 100%;
  top: -2px;
}

.header-top .btn-login {
  padding: 10px 0;
}

.header-title {
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 78px;
}

.landing .header {
  background: url(images/bigbg-heatitle.png) no-repeat center;
  background-size: 100%;
  background-color: #faf4fe;
}

.header-title .container {
  max-width: 1310px;
  margin: 0 auto;
  /* background: url(images/bigbg-heatitle.png) no-repeat top center; */
  /* background-size: 100%; */
  height: 330px;
}

.landing-betatest {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.landing-betatest .static-p {
  padding-top: 0;
  background-color: #faf4fe;
  background: url(images/bkg-landing-left.png) no-repeat left 154px,
    url(images/bkg-landing-right.png) no-repeat right 368px,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), rgba(255, 255, 255, 1)) !important;
}

.landing-betatest .header,
.landing-betatest .parent-btn-connexion,
.landing-betatest .container.betatest {
  flex: 1;
}

.landing-betatest .header-title .container {
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-betatest .header-title h1 {
  padding: 20px;
}

.landing-betatest .header-title {
  padding: 0;
}

.landing-betatest .header-title .container * {
  text-shadow: 1px 1px #fff;
}

.header-title h1 {
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 100px 30px 35px;
  letter-spacing: -2px;
  color: #333333;
}

.header-title h1 span {
  font-weight: 100;
}

.header-title p {
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  max-width: 555px;
  margin: 0 auto;
  display: block;
  padding: 0 40px;
  color: #000;
}

.lselect-secondary.bg-white select {
  height: 100%;
}

.hide-dp-indicator:after {
  background: none !important;
}

.text-keyword {
  font-size: 11px;
}

.blc-select-container:after {
  content: '';
  pointer-events: none;
  width: 14px;
  height: 14px;
  position: absolute;
  background: url(./images/chevron-down.svg) no-repeat center center;
  right: 14px;
  background-size: 76%;
  top: 12px;
}

.search-profil-filters-container :nth-child(2)::after {
  display: none;
}

.blc-select-container [class*='css-dvua']::before,
.blc-select-container [class*='css-dvua']::after {
  /* .blc-select-container .css-dvua67-singleValue::before, .blc-select-container .css-dvua67-singleValue::after{ */
  /*visibility: hidden;*/
  display: none;
}

.form-any-creation .blc-select-container {
  height: 40px;
}

.rowForm .blc-select-container:after {
  top: auto;
}

.separated-date-picker.rowForm {
  display: flex;
  align-items: center;
}

.separated-date-picker.rowForm .blc-select-container {
  height: 40px;
  width: 82px;
}

.separated-date-picker .rdt.inpForm {
  border: none;
}

.separated-date-picker .blc-select-container input {
  height: 40px;
  width: 95%;
}

.separated-date-picker .blc-select-container .rdtPicker {
  color: #000;
}

.blc-select-container input {
  background-color: transparent;
}

.not-dropdown * {
  cursor: text;
}

.not-dropdown div[class*='-menu'] * {
  cursor: default;
}

.not-dropdown .blc-select-container::after,
.not-dropdown.blc-select-container::after

/* , .no-dropdown div[class*='-menu']*/
  {
  display: none !important;
}

.annonce-page .blc-select-container:after,
.wrapper-annonce .blc-select-container:after {
  top: inherit;
}

/* TABS */
.blc-content-tab ul li.tablist {
  position: relative;
}

.blc-content-tab ul li.tablist a {
  font-size: 14px;
  color: rgb(51, 51, 51, 0.4);
  font-weight: 700;
}

.blc-content-tab ul li.tablist.active {
  opacity: 1;
}

.search-wrap .blc-content-tab ul li.tablist a {
  color: rgb(51, 51, 51, 0.6);
}

.search-wrap .blc-content-tab ul li.tablist.desabled:not(.active) a {
  color: #cccccc;
}

.landing .tab-standard-header {
  background-color: #faf4fe;
  padding-top: 0;
  box-shadow: none;
}

.landing .tab-standard-header .blc-tab-title {
  max-width: 1230px !important;
  width: auto;
  background-color: #ffffff;
}

.landing .blc-content-tab ul {
  margin: 0 75px 0;
  position: relative;
}

.landing .blc-content-tab .blc-tab-title ul>li,
.invitation .blc-content-tab .blc-tab-title ul>li {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: #858585;
  padding: 15px 5px 20px 5px;
  line-height: 25px;
  border-bottom: 3px solid rgba(51, 51, 51, 0.1);
}

.landing .blc-content-tab .blc-tab-title ul>li.f-list.active::after {
  content: '';
  display: block;
  height: 4px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background: linear-gradient(to right, #182da8, #a007a0);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33.333% !important;
}

.landing .blc-content-tab .blc-tab-title ul>li.s-list.active::after {
  content: '';
  display: block;
  height: 4px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background: linear-gradient(to right, #182da8, #a007a0);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 66.666% !important;
}

.landing .blc-content-tab .blc-tab-title ul>li.t-list.active::after {
  content: '';
  display: block;
  height: 4px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background: linear-gradient(to right, #182da8, #a007a0);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
}

.landing .blc-content-tab .blc-tab-title ul>li .step-title {
  font-size: 19px;
  text-transform: none;
  letter-spacing: 0;
}

.landing .blc-content-tab .blc-tab-title ul>li.active .step-title {
  color: #000000;
}

/* FOOTER */

.footer-home {
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  padding: 30px 0;
  width: 100%;
  position: relative;
}

.footer-home .container::before {
  position: relative;
  content: '';
  height: 2px;
  width: 100%;
  display: block;
  background-color: #858585;
  margin: 0 auto;
  top: -30px;
  opacity: 0.2;
}

.footer-home .logo {
  max-width: 100px;
}

.footer-home .ico-fb a {
  background: url(images/facebook.svg) center center no-repeat;
  width: 16px;
  height: 17px;
  display: block;
}

.footer-home .ico-twit a {
  background: url(images/twitter.svg) center center no-repeat;
  width: 16px;
  height: 16px;
  display: block;
  opacity: 0.3;
  background-size: cover;
}

.footer-home .ico-In a {
  background: url(images/linkedin.svg) center center no-repeat;
  width: 17px;
  height: 17px;
  display: block;
}

.footer-home .nav {
  display: flex;
  justify-content: space-between;
}

.rw-center {
  align-items: center;
}

.footer-home .nav li {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.footer-home .container .rw {
  align-items: center;
}

.btn-social span {
  margin: 0 20px;
}

/* Connexion */

.profilInfoDialog.login-dialog {
  background: #ffffff url(images/login-bkg.png) 0 0 no-repeat;
  border-radius: 2px !important;
  box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4);
  max-width: 661px;
  padding: 22px 136px 40px 136px !important;
  width: auto !important;
  font-family: 'Montserrat', sans-serif;
}

.login-title {
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 60px 10px;
  font-family: 'Montserrat', sans-serif;
}

.login-title span {
  font-weight: 200;
}

.login-dialog .login-quit {
  padding: 10px;
  right: 10px;
  top: 10px;
}

.login-dialog .rowForm {
  margin-bottom: 15px;
}

.login-dialog .rowForm:nth-of-type(4) {
  margin-bottom: 5px;
}

/*houniiiiii*/
.rowForm .flexini {
  margin-top: 0px;
  /* margin-left: 21px; */
  max-width: 412px;
  /* margin-inline: auto; */
  /* word-break: break-all; */
  padding-left: 14px;
  min-height: 133px;
}

.rowForm .inpForm,
.rowForm .blc-select-container,
.rowForm .select-pictoAdd,
.rowForm .select-pictoAdd:hover {
  min-height: 40px;
  border: rgba(51, 51, 51, 0.3) solid 1px;
  border-radius: 4px;
  padding: 0 20px;
  font-style: normal;
  position: relative;
  margin: 8px 0;
}

.select-pictoAdd .css-bg1rzq-control {
  background: transparent;
}

.rowForm input::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400;
}

.rowForm input::-moz-placeholder {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400;
}

.rowForm input:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400;
}

.rowForm input::placeholder,
.rowForm textarea::placeholder {
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400;
}

.rowForm input:not([type='submit']),
.rowForm textarea,
.react-datepicker__input-container input {
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
}

/* .landing .search-bar .search-text input */

.form-signin-ctnr .rowForm .search-text input::-webkit-input-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.form-signin-ctnr .rowForm .search-text input::-moz-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.form-signin-ctnr .rowForm .search-text input:-ms-input-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.form-signin-ctnr .rowForm .search-text input::placeholder {
  color: #333333 !important;
  opacity: 1;
}

.rowForm.pw {
  margin-bottom: 10px;
}

.btn-connexion {
  border-radius: 26px;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
}

.btn-exit {
  border-radius: 26px;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  border: 2px solid;
}

/* MOT DE PASSE OUBLIE */
.info-frgt-pass {
  /* text-align: center */
}

.info-frgt-pass a {
  display: inline-block;
  margin-left: 10px;
}

.forget-pass,
.profil-tab,
.newsfeed,
.detailAnnonce,
.dashboard {
  font-family: 'Montserrat', sans-serif !important;
}

.actucls {
  margin-left: 10px;
  margin-right: 10px;
}

.forget-pass .show-mdp {
  top: 10px;
}

.bgl-content {
  background: (0, 0, 0, 0);
}

body #root.with_header .bgl-content {
  /* padding-top: 79px; */
}

.pwd-forgot {
  font-size: 11px !important;
  text-align: right;
  width: 100%;
  margin-bottom: 18px;
}

.forgot-pass-step1 {
  font-family: 'Montserrat', sans-serif;
}

.blc-form-frgt-pass button {
  width: 150px;
  padding: 0 14px;
  border: 1px solid;
}

.mission-form p {
  display: flex;
}

/* Inscription STANDARD*/
.invit.rowForm input {
  margin-top: 0;
}

.langing {
  height: 100vh;
}

.landing .tab-standard-body {
  background: url(images/bkg-landing-left.png) no-repeat left 154px,
    url(images/bkg-landing-right.png) no-repeat right 368px,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), rgba(255, 255, 255, 1)) !important;
  margin-top: 0;
  width: 100% !important;
  padding: 0 20px;
}

.landing .tab-standard-body .bgl-content {
  background-color: rgba(255, 255, 255, 0);
}

.landing .tab-standard-body .container {
  max-width: 1230px;
}

.landing .tab-standard-body .container.betatest {
  /*height: calc(100vh - 630px);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing .tab-standard-body .container.betatest .signin-submit {
  margin: 0 0 50px;
}

/* Inscription STEP 1 */
.sector p {
  margin-bottom: 30px;
  margin-top: -8px;
  color: rgba(51, 51, 51, 0.6);
}

.lradio-text {
  font-size: 14px;
}

.mission-form .lradio-sub-text {
  margin-top: 50px;
  margin-right: 150px;
}

.sign-mail,
.sign-pw {
  flex-direction: column;
  padding: 0 90px;
}

.sign-mail input,
.sign-pw input {
  margin-left: 0 !important;
  margin-top: 15px;
}

.must {
  margin: 10px 0 40px 0;
}

.blc-signin {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 600px;
}

.label-form-inscription {
  text-align: center;
  font-size: 19px;
  margin-top: 50px;
  margin-bottom: 14px;
}

.form-signin-ctnr,
.editProfil-page {
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 50px;
}

.form-signin-ctnr .put-inline .lradio {
  justify-content: center;
  margin-right: 0;
  margin-top: 10px;
  position: relative;
  width: 30%;
}

.lradio input[type='radio'] {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 50%;
  margin-left: -42px;
}

.form-signin-ctnr .lradio-mark,
.editProfil-page .lradio-mark,
.mission-form .lradio-mark {
  border: 2px solid #333333;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex: 0 0 20px;
  position: relative;
  height: 20px;
}

/* .betatest{
    height: 100vh;
} */
.releaseNt .accordionForm-body .put-inline {
  flex-flow: row nowrap;
}

.releaseNt {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.releaseNt .tab-standard-header .blc-tab-title {
  background: #fff;
  padding: 30px;
  margin: 0 auto 40px;
}

.releaseNt .ico-caret-down {
  transform: rotate(180deg);
}

.releaseNt .ico-caret-up {
  transform: rotate(0deg);
}

.betatest .tab-standard-body .container {
  height: calc(100vh - 630px);
}

.landing .signin-submit {
  justify-content: center;
  width: 100%;
  margin: 0 auto 60px auto;
  height: 50px;
  border-radius: 26px;
}

.betatest .signin-submit {
  border-radius: 0 !important;
}

.landing .signin-submit button:not([class*='border-']) {
  border: none;
}

.landing .signin-submit button {
  width: 392px;
  height: 50px;
  border-radius: 26px !important;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  border: 2px solid rgba(0, 0, 0, 0);
  margin: 0 15px;
}

.sign-condition {
  margin-bottom: 20px !important;
  padding-top: 40px;
  clear: both;
  position: relative;
}

.sign-condition input {
  position: absolute;
  top: 50px;
  width: 22px;
  height: 22px;
  opacity: 0;
  left: 0;
  z-index: 2;
}

.sign-condition input+label {
  padding-left: 40px;
  text-align: left;
  display: block;
  position: absolute;
  font-size: 14px;
  line-height: 24px;
}

.sign-condition input+label::before {
  content: '';
  vertical-align: text-top;
  width: 22px;
  height: 22px;
  background: white;
  position: absolute;
  left: 0;
  top: 10px;
  border: 2px solid #59c1e5;
}

.sign-condition input:checked+label:before {
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 10px;
  border: 2px solid #59c1e5;
}

.sign-condition input:checked+label:after {
  content: '';
  position: absolute;
  left: 6px;
  top: 20px;
  background: #59c1e5;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #59c1e5, 4px 0 0 #59c1e5, 4px -2px 0 #59c1e5,
    4px -4px 0 #59c1e5, 4px -6px 0 #59c1e5, 4px -8px 0 #59c1e5;
  transform: rotate(45deg);
}

.form-signin-ctnr .lpicto-check-secondary:after,
.mission-form .lpicto-check-secondary::after,
.editProfil-page .lpicto-check-secondary:after {
  content: '';
  background: url(images/check-radio.svg) no-repeat center center;
  height: 22px;
  width: 22px;
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.blc-choice {
  height: 40px;
  padding: 0 10px;
  border: rgba(51, 51, 51, 0.3) solid 2px;
  border-radius: 4px;
  font-size: 11px;
  width: 180px;
  margin: 5px 7.5px;
  color: rgba(51, 51, 51, 0.6);
}

.blc-choice-discipline {
  height: 40px;
  padding: 0 10px;
  border: rgba(51, 51, 51, 0.3) solid 2px;
  border-radius: 4px;
  font-size: 11px;
  width: 170px;
  margin: 5px 7.5px;
  color: rgba(51, 51, 51, 0.6);
}

.blc-choice.active,
.blc-choice:hover {
  border-color: #59c1e5;
}

.sign-name {
  justify-content: center;
}

.sign-name .inpForm {
  width: 210px;
  margin: 8px;
}

/* Inscription STEP 2 */
p.textarea-info {
  text-align: right;
}

i.check-step {
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  position: relative;
}

i.check-step::before {
  content: '';
  width: 3px;
  height: 16px;
  position: absolute;
  transform: rotate(45deg);
  background-color: #59c1e5;
  bottom: 2px;
  left: 14px;
}

i.check-step::after {
  content: '';
  width: 3px;
  height: 10px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #59c1e5;
  bottom: 2px;
  left: 6px;
}

i.check-step.link-expert-feature-announce::before,
i.check-step.link-expert-feature-announce::after {
  background-color: #2fe9db;
}

i.check-step.link-expert-feature-match::before,
i.check-step.link-expert-feature-match::after {
  background-color: #16c9e2;
}

i.check-step.link-expert-feature-chat::before,
i.check-step.link-expert-feature-chat::after {
  background-color: #329eea;
}

i.check-step.link-expert-feature-contract::before,
i.check-step.link-expert-feature-contract::after {
  background-color: #5569dd;
}

i.check-step.link-expert-feature-mission::before,
i.check-step.link-expert-feature-mission::after {
  background-color: #773ece;
}

i.check-step.link-expert-feature-pay::before,
i.check-step.link-expert-feature-pay::after {
  background-color: #5d3cbf;
}

i.check-step.link-expert-feature-dashboard::before,
i.check-step.link-expert-feature-dashboard::after {
  background-color: #ac4de7;
}

.signin-Step2 .blc-signin {
  max-width: 556px;
}

.signin-Step2 .form-signin-ctnr {
  width: 100%;
  padding-bottom: 20px;
}

.file-photos .inpForm.inputfile {
  width: 100%;
  height: 40px;
  left: 0;
  top: -10px;
}

.file-photos .img-format-desc {
  text-align: left;
  font-size: 11px;
  color: rgba(51, 51, 51, 0.6);
}

.signin-Step2 .rowForm div {
  width: 100%;
}

.in-file-photos {
  height: 40px;
  width: 284px;
  border: 1px solid rgba(51, 51, 51, 0.6);
  color: rgba(51, 51, 51, 0.6);
  background: rgba(0, 0, 0, 0);
  padding: 10px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.in-file-photos::after {
  content: '...';
  position: absolute;
  font-size: 24px;
  right: 10px;
  color: #858585;
  line-height: 1px;
}

.rowForm ul.put-inline {
  flex-flow: row wrap;
  padding-top: 30px;
}

.competence-selectioned li {
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  margin: 0 5px;
  border-radius: 22px;
  margin-bottom: 10px;
}

/* .rowForm .blc-select-container select.sign-select{
    height: 38px;
    border: none;
    border-radius: 4px;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    padding: 0 20px 0 20px;
    font-style: normal;
    position: relative;
    color:rgba(51, 51, 51, 0.6);
    margin: 0 -20px;
} */
.rowForm .blc-select-container.selected {
  color: #333333;
  border-color: #59c1e5;
}

.rowForm .blc-select-container select.sign-select option[value=''] {
  color: rgba(51, 51, 51, 0.6);
}

.signin-Step2 .rowForm textarea {
  font-size: 11px;
  line-height: 20px;
}

.signin-Step2 .rowForm textarea:invalid {
  border-color: #ff727d;
}

.blc-signin {
  margin: 0 auto 40px !important;
}

.blc-content-tab ul+li.active {
  background-image: linear-gradient(to right, #182da8, #a007a0) !important;
  background-position-y: 84px;
  background-repeat: no-repeat;
}

.blc-signin .blc-select-container:after {
  content: '';
  display: block;
  position: absolute;
  background: url(images/chevron-down.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  border: none;
  top: 9px;
  background-size: 10px;
  right: 17px;
}

.landing .blc-signin .blc-select-container:after {
  top: 15px;
}

.inputs-info {
  margin-top: 4px;
}

.pass-step {
  display: block;
  margin: 0 auto 30px auto;
  font-size: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
}

/* INSCRIPTION STEP 3*/
.signin-Step3 .label-form-inscription {
  margin-top: 35px;
}

.signin-Step3 .blc-signin {
  max-width: 450px;
}

.blc-signin .blc-contact-search-bar {
  margin-left: 10px;
  width: 315px;
  margin-top: 20px;
}

/* .nav-item .onFocused .btn-search .lpicto-search{
    right:0px;
    position: absolute;
 } */
.search-form .btn-search,
.blc-signin .btn-search {
  width: 40px;
  height: 40px;
  position: relative;
  padding: 4px 0 4px 16px;
}

.search-form .btn-search {
  padding-right: 16px;
  width: 56px;
}

.lpicto-search.lpicto-black {
  display: block;
  position: relative;
  height: 30px;
  padding: 0;
  /* top: 20px;
    right: 19px; */
}

/* .lpicto-search{
    position: absolute;
} */
.search-form input {
  padding-left: 16px;
  width: 100%;
  height: 100%;
}

.search-form>div {
  width: 100%;
  padding: 4px 16px;
}

.lpicto-search.lpicto-black:after {
  /* background: url(./images/ico-search-black.svg) no-repeat;
    position: absolute;
    right: 0; */
  /* top: 6px; */
}

.blc-signin .blc-contact-item {
  min-height: 80px;
  box-shadow: none;
  padding: 0px;
}

.blc-signin .blc-contact-img {
  border: none;
  border-radius: 50%;
  display: block;
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
  padding: 0;
  overflow: hidden;
}

.blc-signin .blc-contact-img img {
  width: 50px;
  height: 50px;
}

.signin-Step3 .list-step {
  max-height: 59vh;
  overflow: hidden;
  border: none;
}

.signin-Step3 .more-list {
  padding: 25px;
  border-bottom: 1px solid #eaeaea;
}

.signin-Step3 .more-list button:before {
  opacity: 0.4;
}

.list-step {
  border-bottom: 2px solid rgba(0, 0, 0, 0.16);
  padding-bottom: 20px;
}

.list-step .blc-contact-info {
  padding: 0 15px;
  line-height: 23px;
}

.lselect-list .blc-contact-info span {
  max-width: 190px;
  overflow-wrap: break-word;
}

.lselect-list .usr-info::before {
  background: none;
}

.list-step .step-connex .btn {
  width: 125px;
  margin: 0;
  padding: 0 18px;
}

.list-step .step-connex .btn span {
  font-size: 12px;
  font-weight: 600;
}

.more-list {
  padding: 25px;
  align-items: center;
}

.more-list button {
  position: relative;
  height: 20px;
}

.more-list button::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: -22px;
  background: url(./images/chevron-down.svg) no-repeat center center;
  background-size: 100%;
  top: 6px;
}

.invit .btn {
  padding: 0 20px !important;
  margin: 0 20px;
  min-width: 95px;
}

.rowForm.invit {
  padding: 20px 0 0;
}

/*===== Connected =====*/
/* header */
.global-header-left {
  width: 130%;
}

.global-header-right {
  width: 50%;
  /* min-width: 380px; */
}

.gbl-header {
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
  height: 80px;
}

/* #root .gbl-header {
    z-index: 2500;
} */

#root .imageResizerBackground {
  z-index: 1500;
}

.gbl-header .ctnr {
  max-width: 1230px;
}

.gbl-header .menu-cntr {
  width: calc(100% - 100px);
}

.gbl-header .logo {
  margin-right: 30px;
  display: block;
  flex: 1 1 auto;
  min-width: 80px;
}

.gbl-header .logo img {
  max-height: 80px;
}

.layout {
  /*margin-top: 80px;*/
}

.main-nav .nav .nav-item {
  /* padding-left: 30px; */
  padding-right: 30px;
  position: relative;
}

.main-nav .nav .nav-item::before {
  content: '';
  position: absolute;
  left: -15px;
  height: 20px;
  width: 1px;
  background-color: rgba(51, 51, 51, 0.3);
  top: 10px;
}

.main-nav .nav .nav-item::after {
  content: '';
  position: absolute;
  height: 20px;
  width: 1px;
  background-color: rgba(51, 51, 51, 0.3);
  top: 10px;
  left: 106.5%;
}

.main-nav .nav .right-item::before {
  content: '';
  position: absolute;
  left: 25px;
  height: 20px;
  width: 1px;
  background-color: rgba(51, 51, 51, 0.3);
  top: 10px;
}

.lpicto-search .main-nav .lpicto-search.lpicto {
  position: relative;
}

.main-nav .lpicto-search.lpicto::after {
  position: relative;
}

.main-nav .search-bar .search-form {
  /* padding: 0 15px; */
  position: relative;
}

.search-bar input:focus~.btn-search .lpicto-search {
  height: 40px;
}

.main-nav .search-bar .search-form .searchmenu {
  position: absolute;
  left: 5px;
  margin: 0;
  top: 45px;
  width: 95%;
}

.gbl-header .main-nav .nav li:not(.idprofil) {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-nav .nav li.nav-item:nth-of-type(1) {
  flex: 1 0 auto;
}

.main-nav .nav li.nav-item:nth-of-type(2) {
  padding-right: 0;
}

.gbl-header .main-nav .nav li a {
  width: 100px;
}

.gbl-header .main-nav .nav .dropdown-content li a {
  width: auto;
}

.main-nav .nav .nav-text {
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
}

.usr-info .head-img {
  flex: 0 0 40px;
  height: 40px;
}

.head-img img {
  width: 100%;
  height: 100%;
}

.head-btn {
  margin-left: 8px;
  position: relative;
}

.head-btn .lpicto-group:after {
  height: 20px;
  width: 20px;
}

.head-btn .lpicto-notif.active:after {
  height: 20px;
  width: 20px;
}

.head-btn .lpicto-chat:after {
  height: 19px;
  width: 19px;
}

.nav-item a.lpicto-announce.header-btn-annonce {
  /*box-sizing: border-box;*/
  height: 40px;
  z-index: 3;
  border: 2px #a03ee6 solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item a.lpicto-announce.header-btn-annonce:not(.active):hover {
  background-color: #ffefff;
}

.nav-item a.lpicto-announce.header-btn-annonce.active,
.nav-item a.lpicto-announce.header-btn-annonce:focus {
  border: none;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background: linear-gradient(45deg, #182da8, #a007a0);
}

.main-nav .nav-item .header-btn-annonce .nav-text {
  color: #a03ee6;
  line-height: 10px;
  height: unset;
}

.nav-item a.lpicto-announce.header-btn-annonce.active .nav-text,
.nav-item a.lpicto-announce.header-btn-annonce:focus .nav-text {
  color: #fff;
}

.usr-info {
  margin-left: 0;
  position: relative;
}

.usr-info::before {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: rgba(51, 51, 51, 0.3);
  position: absolute;
  margin-left: 20px;
  right: 0px;
}

.usr-info .usr.dropbtn {
  display: flex;
  align-items: center;
}

.usr-info .usr.dropbtn::after {
  content: '';
  width: 12px;
  height: 100%;
  position: absolute;
  background: url(./images/chevron-down.svg) no-repeat center center;
  background-size: 80%;
}

.usr-info .active.usr.dropbtn::after {
  transform: rotate(180deg);
}

.user-info .usr {
  position: relative;
  z-index: 10;
}

.blc-dropdown ul {
  background: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 285px;
}

.dropdown-content-prod ul {
  max-width: 150px;
}

.dropdown-content-prod li {
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  /* max-width: 150px; */
}

/* .dropdown-content-prod li span{} */
.dropdown-content li {
  padding: 13px;
  margin: 0 20px;
  /* padding: 15px 20px; */
  background: #ffffff;
  list-style: none;
  /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1); */
  display: block;
}

.dropdown-content li.idprofil {
  height: 120px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
}

.group-action .dropdown-content ul {
  width: auto;
}

.group-action .dropdown-content {
  min-width: 115px;
}

.group-action .dropdown-content li {
  margin: 0;
  padding: 13px 20px;
  cursor: pointer;
}

.group-action .dropdown-content li:hover {
  background: #f6f6f6;
}

.dropdown-content .idprofil a {
  font-size: 12px;
  line-height: 17px;
  position: relative;
  left: 14px;
  bottom: 18px;
}

.idprofil h2 {
  font-size: 12px;
  text-transform: none;
}

.idprofil p {
  font-size: 11px;
  text-transform: none;
  font-weight: 300;
}

.idprofil .detail-profil p {
  max-height: 6ch;
  overflow: hidden;
}

.MuiMenu-paper-133 {
  box-shadow: 0 10px 10px -3px rgba(51, 51, 51, 0.15) !important;
}

.dropdown-content .idprofil a::before {
  position: absolute;
  content: '';
  background: url(./images/chevron-down.svg) no-repeat center center;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  background-size: 50%;
  left: -22px;
  top: -2px;
  opacity: 0.6;
}

.expert a::before {
  position: absolute;
  content: '';
  background: url(./images/chevron-down.svg) no-repeat center center;
  transform: rotate(-90deg);
  width: 20px;
  height: 22px;
  background-size: 50%;
  left: -20px;
  opacity: 0.6;
}

.dropdown-content .id-list {
  line-height: 24px;
}

.dropdown-content .id-list h3 {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
}

.dropdown-content .id-list a,
.dropdown-content .id-list span {
  cursor: pointer;
  font-size: 11px;
}

.dprofil-img {
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  /*background-color: #1578fb;*/
  /*margin-left: 7px;*/
  margin-top: 7px;
  margin-right: 20px;
}

.detail-profil {
  flex: 0 0 64%;
  align-self: center;
  padding: 10px 0;
}

.profil-page,
.annonce-page {
  font-family: 'Montserrat', sans-serif !important;
}

.search-bar .Search-itemList {
  background: #fff;
  position: absolute;
  overflow: hidden;
  max-height: none;
  min-width: 180%;
  max-width: 200%;
}

.searchItem.item {
  padding: 10px 0 !important;
  /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2); */
  border-bottom: solid 1px #eaeaea;
}

.searchItem.item:last-of-type {
  border: none !important;
}

.searchItem.item.item-highlighted {
  /* border-bottom: none; */
}

.Search-itemList .searchItem {
  /* width: 87%;
    margin: 0 auto; */
}

.searchItem-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchItem-description h3,
.searchItem-description h3 span {
  font-size: 12px !important;
  color: #333333 !important;
}

.searchItem-description p {
  font-size: 11px;
  /* color: rgba(51, 51, 51, 1); */
}

.searchItem-description .lastName {
  margin-left: 4px;
}

.blc-profil-cover {
  /*margin-top: 100px;*/
  margin-top: 20px;
}

body #root.with_header .blc-profil-info {
  margin-top: 185px;
}

body #root.with_header .blc-profil-info.mg205 {
  margin-top: 205px;
}

body #root.with_header .editProfil-page {
  margin-top: 0;
}

.with-footer .editProfil-page {
  margin-bottom: 20px;
}

/* header search */
.search-bar .btn-Voirplus-secondary {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
}

.searchmenu,
.css-kj6f9i-menu {
  font-family: 'Montserrat', sans-serif;
  left: 0;
  position: absolute;
}

.searchmenu-header {
  padding: 0 !important;
  text-transform: none;
  margin-top: -1px;
  margin-left: 10px;
}

.btn-VoirPlus.searchmenu-header {
  border-top: none;
  font-size: 12px;
  font-style: normal;
}

.searchmenu-header .dprofil-img {
  width: 30px;
  height: 30px;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 10px;
}

.searchItem-img {
  flex: 0 0 30px;
  margin-right: 9px !important;
  height: 30px;
}

.btn-send-comment .lpicto-search {
  position: absolute;
  right: 14px;
}

/* .blc-group-filter .btn-send-comment .lpicto-search,
.blc-prod-filter .btn-send-comment .lpicto-search,
.blc-annonce-search .btn-send-comment .lpicto-search,
.p-relation .btn-send-comment .lpicto-search {
    top: 19px;
} */
.blc-group-filter .btn>i {
  margin-right: 8px;
}

/* Accueil PAGE CONNECTE*/
.btn.lpicto-send {
  padding: 0 10px;
}

.blc-pub-area .login-quit.i-close.closeUrl {
  top: 35px;
}

.linkDisplay {
  /* height: 350px;
    max-height: 350px; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}

.linkDisplay .react_tinylink_card_media {
  display: none !important;
  flex: 1 1 0%;
}

.linkDisplay .react_tinylink_card_media[src] {
  display: block !important;
  /* height: 300px !important; */
  /* flex: 1 1 300px; */
  /* order: 1; */
}

.feedLinkDisplay .hidden.react_tinylink_card_media[src] {
  display: none !important;
}

.linkDisplay .hidden.react_tinylink_card_media[src] {
  display: none !important;
}

.linkDisplay a {
  height: auto;
  max-height: 300px;
}

.react_tinylink_card {
  width: auto;
}

.react_tinylink_card_media {
  display: none !important;
  flex: 1 1 0%;
}

.react_tinylink_card_media[src] {
  display: block !important;
  /* height: 382px !important; */
  /* flex: 1 1 382px; */
  padding: 2px;
  background-size: auto calc(100% - 4px) !important;
}

.react_tinylink_card_media[src] img {
  display: block !important;
  visibility: hidden;
  margin: auto;
  min-width: 100px;
  min-height: 100px;
}

.react_tinylink_card_content_header,
.react_tinylink_card_footer_description {
  margin-left: -30px;
  margin-right: -30px;
}

.react_tinylink_card_footer_description {
  padding: 0 15px !important;
}

.react_tinylink_card_content_header_description {
  padding: 0 15px !important;
}

.btn-newPost {
  padding: 10px;
  display: none;
}

.btn-newPost .btn {
  display: block;
  margin: 0 auto;
  padding: 0 30px;
}

.newsfeed .blc-center .blc-brevet.st-content-tab {
  margin: 0 30px;
  /* border:none !important; */
}

.lshare img {
  width: 100%;
}

.lshare {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  border-radius: 3px;
  border: 1px solid #e6eaeb;
  padding: 20px 0 0 0;
}

.post-ToShare {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  border-radius: 3px;
  border: 1px solid #e6eaeb;
  padding: 20px 0;
  cursor: pointer;
}

.post-ToShare .blc-resume .show-more {
  top: 0;
}

.newsfeed .lshare {
  padding: 20px;
}

.newsfeed .group-shared p span br {
  content: ' ';
}

.newsfeed .group-shared p span br:after {
  content: ' ';
}

.post-ToShare .blc-profil-header,
.lshare .blc-profil-header {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.post-ToShare .blc-profil-head-info {
  width: 100%;
  margin-left: 12px;
}

.post-ToShare .postToshare-info.infosh .blc-profil-head-info,
.post-ToShare .postToshare-info.infoese .blc-profil-head-info {
  margin-left: 0;
}

.post-ToShare .postToshare-info.infoese .capitalize {
  margin-left: 0;
  font-weight: 700;
}

.lshare .blc-profil-head-info {
  width: 100%;
}

.post-ToShare .blc-profil-head-info span,
.lshare .blc-profil-head-info span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 14px;
}

.profil-actus-center .post-ToShare .blc-profil-head-info span {
  /* margin-left:12px; */
}

.blc-img-profil .lpicto-edit:after {
  top: 80px;
  position: absolute;
  right: -40px;
}

.post-ToShare .blc-img-profil,
.lshare .blc-img-profil {
  flex: 0 0 30px;
  height: 30px;
  border-radius: 50%;
}

.post-ToShare .blc-img-profil img,
.lshare .blc-img-profil img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
}

.blc-dialogForm .blc-tags {
  margin-bottom: 0;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.lshare p {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.post-ToShare p {
  padding-top: 14px;
  padding-right: 20px;
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

/* .feedContainer .post-ToShare p span{
    padding-left:0;
} */
.postToshare-info p {
  padding-left: 0;
}

.postToshare-info .blc-resume {
  display: flex;
}

.blc-dialogForm .post-ToShare .blc-medium-item {
  width: auto !important;
}

.blc-dialogForm .post-ToShare .blc-resume p {
  width: 100%;
  padding-right: 0;
}

.wall-right {
  width: 285px;
  display: block;
  height: 100%;
  position: relative;
  right: 0;
  margin: 20px;
}

.wall-left {
  width: 285px;
  margin: 0 20px;
  position: relative;
}

.wall-left2 {
  width: 285px;
  margin: 10 20px;
  position: relative;
}

.wall-center {
  width: calc(100% - 650px);
  display: block;
  margin: 0 auto;
}

.wall-right .sticky-block,
.wall-left .sticky-block {
  position: sticky;
  position: -webkit-sticky;
  margin-bottom: 50px;

  /* top: 85px; */
  /*overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 80px);*/
}

.wall-right.side-profil {
  height: auto;
  align-self: stretch;
}

.group-wall .profil-info-right .wall-right.side-profil {
  height: 100%;
}

.group-wall .profil-info-right {
  height: auto;
  align-self: stretch;
}

/* PROFIL */

/* .feedContainer, */

/* .feedContainer .feedPicture{
    display: block;
    height: 120px;
    width: 100%;
    background-size: cover;
    background: url(./images/bghead-annonce.png) no-repeat center center;
} */
.blc-smallbr-item .list-profil-icon ul li {
  display: block;
}

.uploadProfil .popTitle {
  margin: 0 30px;
}

.uploadProfil .popTitle-ctnr {
  padding-bottom: 20px;
}

.blc-dialogForm.uploadProfil {
  font-family: 'Montserrat', sans-serif;
}

.blc-dialogForm.uploadProfil .popContent button {
  padding-left: 40px;
  position: relative;
}

.blc-dialogForm.uploadProfil .popContent button::before {
  content: '';
  display: block;
  background: url(./images/icone-upload.svg) no-repeat center center;
  position: absolute;
  z-index: 1000;
  width: 35px;
  height: 35px;
  background-size: 70%;
  left: 6px;
}

.feedContainer .newsfeed .post-ToShare.blc-profil-header .blc-tags {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.feedContainer .newsfeed .post-ToShare.blc-profil-header {
  align-items: flex-start;
}

.feedContainer .newsfeed .blc-text-publication a.react_tinylink_card,
.favoris-page .newsfeed .blc-text-publication a.react_tinylink_card {
  max-width: none;
}

.feedContainer .blc-btn-action-publication .btn-action-item:nth-of-type(3) button {
  width: auto;
  margin-left: auto;
  justify-content: flex-end;
}

.newsfeed .blc-btn-action-publication .btn-icon {
  height: auto;
  width: auto;
}

.newsfeed .blc-btn-action-publication .btn-icon.lpicto-chat:after {
  height: 18px;
}

.blc-tags {
  width: 100%;
  margin-bottom: 30px;
}

.blc-tags span {
  margin: 5px 5px 5px 0;
}

.post-ToShare.blc-profil-header {
  flex-flow: row wrap;
  /* align-items: flex-start; */
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 90%;
  /* margin: 0 30px; */
}

.post-ToShare.blc-profil-header .blc-img-profil {
  flex: 0 0 65px;
  height: 65px;
}

.post-ToShare.blc-profil-header .blc-img-profil img {
  width: 65px;
  height: 65px;
}

.post-ToShare.blc-profil-header .blc-profil-head-info {
  flex: 1;
  /* line-height: 30px; */
  padding-left: 20px;
}

.post-ToShare.blc-profil-header .blc-profil-head-info span {
  height: auto;
  line-height: 30px;
}

.post-ToShare.blc-profil-header .blc-profil-head-info span span {
  line-height: 17px;
  margin-bottom: 6px;
}

.post-ToShare.blc-profil-header .blc-profil-head-info span .show-more {
  margin: 0;
}

.post-ToShare.blc-profil-header .blc-profil-head-info span strong {
  margin-left: 0;
}

.post-ToShare.blc-profil-header .blc-profil-head-info span.tag-usr {
  line-height: 20px;
  padding: 8px 14px;
  margin-top: 4px;
  color: #59c1e5;
  border-radius: 20px;
  background-color: rgba(89, 193, 229, 0.1);
  margin-right: 5px;
  width: auto;
}

.profil-tab .profil-actus-center .blc-brevet {
  margin: 0 30px;
}

.recommanded-nbr {
  font-size: 11px;
  padding-left: 10px;
  line-height: 20px;
}

.blc-newprod-content .rowForm .inpForm {
  margin-right: 0;
  width: 100%;
}

.blc-newprod-head .header-page .headerPage-left {
  position: relative;
}

.blc-newprod-head .header-page .headerPage-left .btn {
  position: absolute;
}

.blc-dialogForm .blc-brevet {
  border: none !important;
  margin-bottom: 10px;
}

.blc-dialogForm .blc-brevet .brevet-img {
  max-height: 200px;
  overflow: hidden;
}

.blc-dialogForm .post-ToShare {
  padding-top: 0;
  flex-direction: row;
  padding: 10px;
}

.blc-dialogForm .post-ToShare .blc-img-profil {
  flex: 0 0 50px;
  height: 50px;
}

.blc-dialogForm .post-ToShare .blc-img-profil img {
  width: 50px;
  height: 50px;
}

.blc-dialogForm .post-ToShare .blc-profil-head-info {
  height: 30px;
}

.blc-dialogForm .post-ToShare .blc-profil-head-info span {
  flex-direction: row;
  justify-content: flex-start;
}

.diplom-img-item {
  margin-right: 30px;
  flex: 0 0 90px;
  width: 90px;
  height: 90px;
  border-radius: 0%;
  display: block;
  margin-top: 10px;
}

.blc-dialogForm.diplome-form {
  max-width: 900px;
}

.blc-dialogForm.mission-form {
  padding: 30px;
  max-width: 1037px;
}

.blc-dialogForm.diplome-form .formAction button {
  width: 205px;
}

.blc-dialogForm.diplome-form .formAction button.btn-validate {
  margin: 0;
}

.blc-dialogForm.diplome-form .formAction {
  justify-content: center;
}

.blc-dialogForm.diplome-form .popTitle-ctnr {
  display: flex;
  width: 100%;
}

.blc-dialogForm.diplome-form .popTitle-ctnr .popTitle {
  font-size: 45px;
  width: 100%;
  margin: 90px 0 40px 0;
  letter-spacing: -2px;
  color: #333;
}

/* houni */
.blc-dialogForm.mission-form .popTitle-ctnr .popTitle {
  text-align: left;
  font-size: 35px;
  margin-left: -8px;
  margin-right: 30px;
}

.blc-dialogForm.diplome-form .dialogForm-inside {
  width: 70%;
}

.blc-dialogForm.diplome-form .popFormLong {
  max-height: none;
}

.blc-social .blc-profil-head-info {
  padding-left: 0;
}

.blc-profil-head-info .tag-usr {
  margin-top: 10px;
}

.profil-actus .wall-left {
  align-self: baseline;
  margin-top: 0;
  margin-left: 0;
  margin-right: 30px;
}

.layout-group .wall-left {
  margin-top: 0;
  height: auto;
}

.profil-actus-center {
  width: calc(100% - 300px);
  margin-right: 10px;
}

.txt-limit-name {
  max-width: 260px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: flex;
}

.profil-tab .lpicto-menu:after {
  right: -6px;
  top: 3px;
}

.profil-info-center .profil-tab .lpicto-menu:after,
.production-list .lpicto-menu:after {
  right: 0;
  left: 60%;
}

.profil-info-center .profilInfo .blc-profil-top .list-icon-label li>span {
  cursor: default;
}

.newsfeed .lpicto-menu:after {
  right: 0;
  top: 3px;
  position: relative;
}

.blc-compt-item-ctnr-left {
  align-items: center;
  position: relative;
  display: flex;
}

.diplom-info .show-more {
  margin-top: 20px;
}

.parcours-type {
  padding-top: 3px;
}

.blc-share-profil button,
.blc-share-profil .btn {
  margin-right: 20px;
  padding: 0 30px;
}

.blc-title-accordion .btn {
  position: relative;
}

.blc-profil-actions button {
  /* padding:0 30px; */
  margin-right: 12px;
}

/* .blc-profil-actions button:nth-of-type(1), .blc-profil-actions button:nth-of-type(2){
    padding:0 30px;
} */
.profilInfo .blc-profil-actions button {
  padding: 0 6px;
}

.profilInfo .blc-profil-actions button.add-feed {
  padding: 0 20px;
}

.profil-enterprise .blc-profil-actions button {
  padding: 0 6px;
}

.profil-enterprise .blc-profil-actions button.btn-admin,
.profil-enterprise .blc-profil-actions button.add-feed {
  padding: 0 20px;
}

.profil-tab .blc-content-tab ul {
  height: 138px;
  justify-content: space-between;
}

.project-lists li {
  display: flex;
  width: calc(32% - 12px);
  align-items: center;
  justify-content: center;
  padding: 2px;
  height: 100%;
}

.project-lists li:nth-child(1) {
  background: linear-gradient(205deg, #af42f0, #1f379a);
}

.project-lists li:nth-child(2) {
  background: linear-gradient(26deg, #1578fb, #17ead7);
}

.project-lists li:nth-child(3) {
  background: linear-gradient(26deg, #ff91af, #f082a0);
}

.licontent {
  width: 100%;
  height: 100%;
}

.licontent span {
  padding-left: 22.5px;
}

.blc-profil-header em {
  font-style: normal;
}

button.m-hide,
.btn-edit-custom {
  position: absolute;
  right: -70px;
  top: -80px;
}

.btn-share-custom {
  position: absolute;
  right: -30px;
  top: -70px;
}

.profilInfo .blc-img-profil {
  height: 174px;
  width: 174px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: -214px;
}

.profilInfo .blc-img-profil img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0;
}

.profil-info-center,
.wrap-left-container {
  width: calc(100% - 315px);
  /* margin-right: 30px; */
}

.profil-info-right,
.annonce-right {
  display: block;
  width: 285px;
  /* height: 100vh; */
}

.side-profil {
  /*margin-top: 196px;*/
  margin-top: 185px;
  margin-right: 0;
  margin-left: 30px;
}

.side-profil.mg205 {
  /*margin-top: 196px;*/
  margin-top: 205px;
  margin-right: 0;
  margin-left: 30px;
}

.blc-profil-top {
  position: relative;
}

.blc-profil-top h3 {
  padding: 20px 0;
  margin-top: 20px;
  letter-spacing: 2.8px;
}

.profil-stat {
  justify-content: space-around;
  height: 64px;
}

.tbl-profilstat {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #eaeaea;
  border-left: 0;
}

.tbl-profilstat+.tbl-profilstat {
  border-right: 0;
}

.lprofil p,
.lprofil h3 {
  font-size: 11px;
}

.profilInfo .blc-tab-title {
  height: 62px;
}

.profilInfo.nav {
  margin-top: 30px;
}

.blc-content-tab .blc-tab-title ul>li {
  padding: 20px 5px;
}

.profilInfo .blc-content-tab .blc-tab-title ul>li {
  position: relative;
}

.blc-content-tab .blc-tab-title ul>li.active::after,
.blc-content-tab .blc-tab-title ul>li.unactive::after {
  content: '';
  display: block;
  height: 4px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#182da8),
      to(#a007a0));
  background: linear-gradient(to right, #182da8, #a007a0);
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.blc-dashboard-center .blc-content-tab .blc-head-title ul>li.active::after,
.blc-dashboard-center .blc-content-tab .blc-head-title ul>li.unactive::after {
  width: 50%;
}

.invitation .blc-content-tab .blc-tab-title ul>li.active::after {
  position: relative;
}

.btn-premium {
  display: block;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-top: 10px;
  position: relative;
  text-transform: none;
}

.btn-premium::before {
  position: absolute;
  content: '';
  background: url(./images/chevron-down.svg) no-repeat center center;
  transform: rotate(-90deg);
  width: 17px;
  height: 17px;
  background-size: 60%;
  left: -2px;
  top: 8px;
  opacity: 0.4;
}

.blc-pub-area {
  height: 100px;
  justify-content: flex-start;
}

.blc-pub-areacontent {
  border-right: 1px solid #eaeaea;
  padding: 40px 30px;
  width: 65%;
  cursor: pointer;
}

.blc-pub-areacontent:hover {
  background-color: rgba(125, 225, 250, 0.05);
}

.blc-pub-areaico {
  display: flex;
  justify-content: center;
  width: 35%;
}

.blc-pub-areaico .lpicto-video,
.blc-pub-areaico .lpicto-picture,
.blc-pub-areaico .lpicto-file {
  position: relative;
  display: block;
  width: 50%;
  height: 24px;
  text-align: center;
  cursor: pointer;
}

/* .blc-pub-areaico .lpicto-picture:after{
    content: '';
    background: url(./images/ico-att-image.svg) no-repeat center center;
    display: inline-block;
    height: 24px;
    width: 24px;
}
.blc-pub-areaico .lpicto-video:after{
    background: url(./images/ico-att-photo.svg) no-repeat center center;
    content: '';
    display: inline-block;
    height: 24px;
    width: 24px;
} */

.lpicto-picture:hover::after {
  background: url(./images/ico-att-image-active.svg) no-repeat;
}

.lpicto-video:hover::after {
  background: url(./images/ico-att-photo-active.svg) no-repeat;
}

.feedComposerBackground .blc-pub-areaico .lpicto-video:hover::after {
  background: url(./images/ico-att-photo-active.svg) no-repeat !important;
}

.lpicto-file:hover::after {
  background: url(./images/ico-att-file-active.svg) no-repeat;
}

/* GROUPE */
/* Gestion de groupe*/
/* .popin-group-manage .blc-compt-item-content button{
    margin-right: 0;
} */
body #root.with_header .blc-profil-info.popin-group-manage {
  max-width: 620px;
  padding: 40px 40px 20px;
}

.popin-group-manage .header {
  display: block;
  position: relative;
  padding-bottom: 30px;
}

.popin-group-manage .popTitle {
  width: 100%;
}

.popin-group-manage .login-quit {
  display: block;
  top: -28px;
  right: -28px;
  position: absolute;
  width: 30px;
  height: 30px;
}

.popin-group-manage .login-quit:after,
.popin-group-manage .login-quit:before {
  content: '';
  background-color: #333333;
  position: absolute;
  width: 2px;
  height: 26px;
  top: 2px;
  right: 14px;
}

.popin-group-manage .login-quit:before {
  transform: rotate(45deg);
}

.popin-group-manage .login-quit:after {
  transform: rotate(-45deg);
}

.popin-group-manage .blc-contact-search-bar {
  max-width: 388px;
  margin: 0 auto;
}

/* .popin-group-manage .lpicto-search:after{
    left: 6px;
    top: 7px;
} */
.popin-group-manage .lprofilPm-item {
  border: none;
  width: 60%;
}

.popin-group-manage .blc-contact-item-right {
  width: 40%;
  min-width: 180px;
  /* justify-content: space-around; */
}

.popin-group-manage .blc-compt-item-content {
  justify-content: center;
  margin-top: 28px;
}

.fuse_scroll_bar {
  height: 400px;
  position: relative;
}

.popin-group-manage .groupe-member {
  max-width: 450px;
  margin: auto;
}

.popin-group-manage .popTitle {
  margin: 0;
}

/* .search-bar .search-form{

} */
.group-list .wall .wall-center {
  margin-bottom: 45px;
}

.group-list .pagination {
  /* background-color: #fff; */
  max-width: 360px;
  margin: 8px auto 0;
  padding: 6px 5px 5px;
  height: 36px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.layout-group .wall-center {
  width: calc(100% - 324px);
  font-family: 'Montserrat', sans-serif;
}

.blc-group-filter {
  /* width: calc(100% - 285px); */
  width: 100%;
  padding: 30px 0;
}

.blc-group-action button {
  width: 156px;
  margin-right: 20px;
  height: 100%;
  border: 2px solid;
}

.blc-group-filter .blc-group-action button {
  margin-right: 0;
}

.blc-new-group {
  height: 100%;
}

.blc-group-title {
  padding: 26px 8px 26px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333333 !important;
}

.blc-group-list-item .group-action {
  align-self: center;
  position: relative;
  right: -10px;
}

.blc-group-list-item .group-action .btn.lpicto-menu {
  height: 30px;
  width: 40px;
  padding: 0;
}

.blc-group-list-item .group-action .lpicto-menu:after {
  right: unset;
}

.blc-group-list-item .group-img {
  min-width: 100px;
  height: 100px;
}

.search-content .blc-group-list-item .group-img {
  min-width: 50px;
  height: 50px;
}

.blc-group-list-item .group-img img {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.search-content .blc-group-list-item .group-img img {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
}

.blc-group-list-item .blc-group-item-content {
  cursor: pointer;
}

.search-content .blc-group-list-item {
  height: auto;
}

.search-content .group-info {
  padding-left: 0;
}

/* - HEADER NOTIFICATION - drop down notification - */
.all-notification .notification-date {
  margin-left: 30px;
  font-size: 10px;
}

.notification-list .blc-contact-item-right .notification-date {
  font-size: 10px;
}

.all-notification .blc-contact-item-right .btn {
  margin-right: 14px;
}

.notification-dropContent.rounded.shadowed {
  border-radius: 3px;
  box-shadow: 0 10px 10px -3px rgba(51, 51, 51, 0.15);
  position: absolute;
  top: 50px;
  right: 0px;
  width: 285px;
  z-index: 3;
}

.notification-dropContent.rounded.shadowed .blc-contact-info span {
  width: 100%;
}

.notification-dropContent.rounded.shadowed .notification-list-container {
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.wall-center .blc-contact-info span {
  width: 100%;
  /* display: block; */
}

.wall-center .blc-contact-info span.lpicto-link {
  width: auto;
}

.wall-center .list-invitation .blc-contact-info .put-inline {
  /* max-width: 77%; */
  line-height: 18px;
}

.all-notification .wall-center .blc-contact-info {
  padding: 0 10px;
  max-width: 100%;
  width: 100%;
}

.notification-list-container {
  width: 100%;
  padding: 20px 0 9.5px;
  box-shadow: 0 1px #eaeaea;
  font-size: 11px;
}

.notification-list .blc-contact-info {
  padding: 0 0 0 10px;
  max-width: 210px;
  width: 100%;
}

.notification-list .blc-contact-item-right {
  width: 13%;
}

.notification-list-container .notif-time-categ {
  display: block;
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  padding: 5px 20px;
}

.notification-list-container .blc-contact-item {
  box-shadow: 0 1px #eaeaea;
  background: none;
  padding: 10px 20px;
}

.notification-description {
  color: #333333;
}

.notif-contact-img.blc-contact-img {
  align-self: flex-start;
  border: none;
  width: 30px;
  height: 30px;
}

.notif-contact-img.blc-contact-img img {
  width: 30px;
  height: 30px;
}

.notification-history {
  font-size: 10px;
  color: rgba(51, 51, 51, 0.6);
  margin-top: 10px;
}

.notification-label-container {
  position: absolute;
  width: 24px;
  height: 24px;
}

.notification-label.no-label,
.tchat-label.no-label,
.contact-label.no-label {
  top: 0px;
  right: 1px;
  height: 9px;
  width: 9px;
  outline: 1.5px solid #fff !important;
  background-color: #a03ee6;
  z-index: 3;
}

.notification-btn-seeAll {
  color: #333333;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lpicto-notif:not(.active):after {
  content: '';
  background: url(./images/notif-gris_active.svg) no-repeat !important;
  opacity: 0.5;
  display: inline-block;
  height: 18px;
  width: 18px;
}

.lpicto-notif:hover:not(.active):after {
  background: url(./images/notif-gris_active.svg) no-repeat !important;
  opacity: 0.7;
}

.lpicto-notif.active:after {
  background: url(./images/notif-gris_active.svg) no-repeat !important;
  opacity: 1;
}

.propose-mission.titlePopup {
  height: 55px;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 40px;
}

.propose-mission.titlePopupNew {
  font-size: 45px;
  font-weight: bold;
}

.propose-mission.subtitlePopup {
  height: 18px;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.propose-mission.subtitlePopupNew {
  font-size: 45px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  margin-top: -5px;
}

.publish-dialog .propose-mission.blc-pub-area-action {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 0;
  border: none;
}

.publish-dialog .propose-mission.blc-pub-area-action button {
  width: 245px;
  margin: auto;
}

/* list all notification*/
.all-notification {
  padding: 0 10px;
}

.all-notification.container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
  /* align-items: flex-start; */
}

.all-notification .wall-center {
  /*width: calc(100% - 315px);*/
  width: 96%;
  min-height: 20px;
  padding: 10px 20px;
}

.all-notification .wall-right {
  margin-right: 0;
  margin-left: 30px;
  margin-top: 0;
}

.all-notification.container>h1 {
  text-align: left;
  margin: auto;
  width: 95%;
  max-width: 900px;
  padding: 60px 15px 30px;
}

.all-notification .st-content-tab {
  min-width: 290px;
  max-width: 900px;
  padding: 0;
  margin: auto;
}

.all-notification .blc-contact-item {
  min-height: 65px;
  padding: 15px 22px;
  cursor: pointer;
}

.notification-list-container .blc-contact-item {
  cursor: pointer;
}

.notification-list-container .blc-contact-item.not-seen {
  background-color: rgba(243, 251, 253, 0.5);
}

.notification-list-container .blc-contact-item:hover {
  background-color: rgba(243, 251, 253, 1);
}

.blc-contact-item.not-seen {
  background-color: rgba(243, 251, 253, 0.5);
}

.all-notification .blc-contact-item:hover {
  background-color: rgba(243, 251, 253, 1);
}

.all-notification .blc-contact-item-left {
  width: 100%;
}

/* -- wall - sidebar --*/

.sidebar-list-message {
  font-weight: 700;
  padding: 20px;
}

.lprofilPm-body>.sidebar-list {
  max-height: none;
}

.lprofilPm-body.more-spaces {
  padding: 20px 20px;
  width: 285px;
}

.wall-header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 29.5px;
  margin-bottom: 19.5px;
  width: 100%;
  box-shadow: 0 1px #eaeaea;
}

.wall-title {
  display: inline-block;
  font-weight: 700;

  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
}

#root.with_header .wall-seeMore {
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  cursor: pointer;
}

#root.with_header .wall-seeMore:hover {
  color: #039be5;
}

.imageResizerBackground {
  padding: 30px 10px;
  overflow: auto;
  cursor: auto;
}

.imageResizerBackground .confirmPop.login-dialog {
  margin: auto !important;
}

#root.with_header .imageResizerBackground {
  padding: 100px 10px 40px 10px;
  overflow: auto;
  cursor: auto;
}

#root.with_header .imageResizerBackground .blc-dialogForm.limited-height {
  max-height: 100%;
}

#root.with_header .imageResizerBackground .blc-dialogForm {
  margin: auto;
  height: auto;
  position: relative;
}

#root.with_header .imageResizerBackground .publish-dialog {
  max-height: none;
}

#root.with_header .blc-dialogForm.popUp-medium.popUpSignal {
  margin: auto;
}

.chevron-right {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: url(./images/chevron-right.svg) no-repeat !important;
  opacity: 0.4;
  margin-right: 5px;
  background-size: 8px 8px !important;
}

.chevron-left {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: url(./images/chevron-right.svg) no-repeat !important;
  opacity: 0.4;
  margin-right: 5px;
  background-size: 8px 8px !important;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

.sidebar-list .lprofilPm-item {
  padding: 10px 0;
  border: none;
}

.popin-group-manage .lprofilPM-img {
  height: 42px;
  width: 42px;
  border: none;
}

.sidebar-list .lprofilPM-img {
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
  max-width: 50px;
  border: none;
}

.sidebar-list .lprofilPM-info {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 0 0 0 15px;
  margin: 0;
}

.sidebar-list .lprofilPM-name {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.recommendation-item.lprofilPM-categorie {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.6);
}

.group-item.lprofilPM-categorie {
  font-size: 11px;
  font-weight: bold;
  color: #59c1e5;
}

/* ---- == page - creation group === ---*/
.fnt-19 {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-main-bg {
  background-color: #f9f9f9;
  display: flex;
}

.form-group-creation,
.form-any-creation {
  font-family: Montserrat;
  padding-top: 69px;
  padding-bottom: 132px;
  position: relative;
  background-color: #fff;
  margin: 10px 108px 46px 102px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blc-group-creation-inside,
.blc-any-creation-inside {
  width: 420px;
  display: flex;
  flex-direction: column;
}

.blc-group-creation-inside .form-big-title,
.blc-any-creation-inside .form-big-title {
  color: #4a4a4a;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
}

.form-group-creation .post-right.radio-container .lradio,
.form-group-creation .post-right.radio-container .lradio .lradio-text {
  margin-right: 0;
}

.blc-any-creation-inside .lautocomplete .blc-select-container {
  border-color: rgba(51, 51, 51, 0.3);
}

.blc-any-creation-inside .rowForm .css-epgpks,
.blc-any-creation-inside .lautocomplete input,
.blc-any-creation-inside .blc-select-container .css-1hwfws3 {
  border: none;
}

.form-group-creation .groupeProfil-img-creation,
.anyProfil-img-creation {
  height: 98px;
  margin-right: 37px;
  width: 98px;
  background: #f9f9f9 url('images/groupe-empty-icon.svg') center no-repeat;
  background-size: 117%;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.form-group-creation .groupeProfil-img-creation img,
.anyProfil-img-creation img {
  width: 100%;
  height: 100%;
}

.form-group-creation .in-file-photos {
  border: rgba(51, 51, 51, 0.3) solid 1px;
  padding: 12px 20px;
  font-size: 11px;
}

.form-group-creation .in-file-photos::after {
  color: #333;
}

.btn-goback {
  position: absolute;
  left: 46px;
  top: 30px;

  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}

.btn-goback.btn-goback-secondary {
  top: auto;
  left: auto;
  position: relative;
}

.max-characters-indice {
  display: block;
  text-align: right;
  font-size: 11px;
  color: rgba(51, 51, 51, 0.6);
  /* margin-top: 10px; */
}

.form-group-creation .label-form-group {
  margin-top: 36px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: #333;
  display: block;
}

.form-group-creation .blc-group-creation-inside .inpForm {
  margin-left: 0;
}

.form-group-creation .textarea-form-description,
.form-any-creation .textarea-form-description {
  height: 200px;
  padding: 20px;
  font-size: 11px;
  border: rgba(51, 51, 51, 0.3) solid 1px;
  margin-bottom: 8px;
}

.form-group-creation .radio-container.put-inline {
  justify-content: left;
  margin-bottom: 24px;
}

.form-group-creation .radio-container .lradio {
  width: auto;
  margin-right: 18px !important;
}

.form-group-creation .radio-container .lradio .lradio-text {
  margin-right: 40px;
}

.form-group-creation .radio-container .lradio-mark {
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex: 0 0 20px;
  position: relative;
  height: 20px;
}

.form-group-creation .lradio-text {
  font-size: 12px;
  color: #333;
}

.form-group-creation .lpicto-check-secondary:after {
  background: url(images/check-radio.svg) no-repeat center center;
  height: 22px;
  width: 22px;
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.form-group-creation .blc-profil-header-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 4px;
}

.form-group-creation .keywords-container,
.keywords-container {
  display: block;
  width: 100%;
  padding: 5px 0 5px 0px;
  margin-bottom: 18px;
}

.form-group-creation .selected-keyword,
.selected-keyword {
  display: inline-flex;
  color: #a03ee6;
  position: relative;
  padding: 13px 33px;
  margin: 5px 0 5px 10px;
  left: -10px;
  background-color: rgba(160, 62, 230, 0.07);
  font-size: 11px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.form-group-creation .keyword-deleter,
.keyword-deleter {
  background: url(images/ico-close-secondary.svg) no-repeat;
  background-size: cover;
  position: absolute;
  cursor: pointer;
  top: 17px;
  left: 12.5px;
  content: '';
  display: block;
  height: 7px;
  width: 7px;
}

/* .form-group-creation .keyword-deleter:hover,.keyword-deleter:hover{
    background-color: #bf7af0;
} */
.form-group-creation .blc-contact-search-bar {
  width: 100%;
  margin: 0;
}

.list-contact-group-creation {
  margin-top: 6px;
  padding-bottom: 24px;
  box-shadow: 0 1px #eaeaea;
}

.list-contact-group-creation .blc-contact-item {
  padding: 0px;
  margin-top: 30px;
}

.list-contact-group-creation .btn {
  padding: 0 23.5px;
}

.list-contact-group-creation .blc-contact-item-right {
  min-width: 175px;
  margin: 0;
  justify-content: flex-end;
}

.list-contact-group-creation .blc-contact-item-right .btn {
  margin: 0;
}

.list-contact-group-creation .blc-contact-item-right .blc-contact-action,
.list-contact-group-creation .blc-contact-item-right .blc-contact-action .btn {
  min-width: 102px;
  position: relative;
}

.list-contact-group-creation .blc-contact-info {
  line-height: 18px;
  word-break: break-all;
}

.list-contact-group-creation .contact-name {
  font-size: 12px;
}

.list-contact-group-creation .contact-profession {
  font-size: 11px;
}

.list-contact-group-creation .blc-contact-img {
  min-width: 50px;
  height: 50px;
  border: none;
  margin-right: 5px;
}

.list-contact-group-creation .blc-contact-img img {
  width: 50px;
  height: 50px;
}

.form-group-creation .seeMore-contacts {
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 29px 0;
}

.form-group-creation .seeMore-contacts span {
  position: relative;
  left: 13px;
  cursor: pointer;
}

.form-group-creation .seeMore-contacts span::before {
  cursor: pointer;
  background: url(images/chevron-down-grey.svg) no-repeat;
  background-size: cover;
  position: absolute;
  top: 3px;
  left: -20px;
  content: '';
  display: block;
  height: 10px;
  width: 10px;
}

.required-field-notif {
  /* color: #ff727d; */
  color: #a03ee6;
  margin: 32px 0 60px 0;
}

.required-field-notif b {
  font-size: 12px;
}

.form-group-creation .btn-validate {
  font-weight: 600;
  margin: 0;
  width: 205px;
}

.form-group-creation .btn-cancel {
  font-weight: 600;
  width: 100%;
  margin-right: 10px;
  width: 205px;
}

/** ---------- group view ---------- **/
.groupe-member .blc-contact-item-right .btn {
  margin-right: 6px;
}

.group-view-actu .blc-profil-top {
  width: 100%;
}

.group-view-actu .groupe-profil {
  margin-bottom: 30px;
}

.group-view-actu .editable:not(.hidden) {
  display: inline-block;
  position: absolute;
}

.group-view-actu .groupeProfil-info .wdth-100 {
  position: relative;
}

/* .group-view-actu .groupeProfil-info .editable {
    right: 0;
    padding: 0
} */
.group-view-actu .container.layout.profil-page,
.layout.profil-enterprise.container {
  width: 95%;
}

.group-view-actu .blc-menu-group {
  padding-bottom: 29.5px;
  margin-bottom: 29.5px;
  border-bottom: 1px solid #eaeaea;
}

.group-view-actu .sub-menu-group {
  display: inline-flex;
  padding-left: 10px;
  flex: 1;
}

.group-view-actu .blc-menu-group button,
.group-view-actu .blc-menu-group .btn {
  margin-right: 20px;
  padding: 0 30px;
}

.group-view-actu .sub-menu-group .btn {
  margin-right: 23px;
  padding: 0;
}

.group-view-actu .lpicto-check-secondary::after {
  background: url(./images/check-white.svg);
  background-size: cover;
  content: '';
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 10px;
}

.lpicto-chat {
  position: relative;
}

.group-view-actu .lpicto-chat:after {
  content: '';
  background: url(./images/ico-chat-gris.svg);
  background-size: 100%;
  opacity: 0.5;
}

.group-view-actu .lpicto-share-secondary::before {
  content: '';
  height: 20px;
  width: 20px;
  background: url(./images/ico-share-primary.svg);
  background-size: 100%;
}

.group-view-actu .groupeProfil-info {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.group-view-actu .groupeProfil-info h4 {
  padding-bottom: 7px;
}

.group-view-actu .groupeProfil-info .rowForm {
  color: rgba(51, 51, 51, 0.5);
}

.group-view-actu .blc-img-profil .brd-image {
  border: none;
}

.group-view-actu .owner-name {
  font-size: 12px;
  font-weight: bold;
  color: rgba(51, 51, 51, 0.5);
}

.group-view-actu .blc-img-profil .lpicto-edit:after {
  top: 102px;
  right: 0;
}

.group-view-actu .groupeProfil-owner {
  display: inline-flex;
  margin-top: 5px;
}

.group-view-actu .lpicto-subscriber:after {
  content: '';
  background: url(./images/ico-abo.svg) no-repeat;
  display: inline-block;
  height: 17px;
  width: 15px;
  background-size: 100%;
  margin-right: 7.5px;
  margin-bottom: 8px;
  position: relative;
  right: -2px;
}

.group-view-actu .lpicto-date:after {
  content: '';
  background: url(./images/calendar.svg) no-repeat;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 8.5px;
}

.group-view-actu .groupeProfil-description .label-a-propos {
  letter-spacing: 2.8px;
  margin-bottom: 13px;
}

.group-view-actu .groupeProfil-description .description-a-propos {
  line-height: 1.83;
}

.group-view-actu .blc-group-filter {
  padding: 0;
  margin-top: 31.5px;
  margin-bottom: 14px;
  width: 100%;
  justify-content: space-between;
}

.group-view-actu .blc-group-filter select {
  line-height: normal;
}

.group-view-actu .blc-group-filter .blc-group-action button {
  margin-right: 0;
}

.group-view-actu .blc-group-filter .blc-group-search-bar {
  max-width: none;
}

.blc-group-filter .blc-select-container {
  overflow: hidden;
}

.group-view-actu .wall-left,
.profil-enterprise .entreprise .wall-left {
  margin-left: 0;
  margin-right: 30px;
}

.group-view-actu .wall-empty {
  opacity: 0;
  z-index: -1;
}

.group-view-actu .group-actu-feed,
.profil-enterprise .corporation-feed {
  width: calc(100% - 315px);
}

.group-view-actu .newsfeed .btn-action-item:nth-of-type(3) {
  margin-left: auto;
}

.group-view-actu .lpicto-favoris:after {
  height: 20px;
  width: 21px !important;
}

.group-view-actu .btn-manage-members.btn {
  text-align: center;
  margin-right: 0;
}

.group-view-actu .be-member {
  font-size: 26px;
}

.group-view-actu .be-member.sent {
  font-size: 18px;
  line-height: 0px;
  position: relative;
  padding-bottom: 6px;
}

.group-view-actu .groupeProfil-info .group-owner {
  font-size: 10px;
}

/** home feed - new comment**/
.feedPicture-container {
  position: relative;
}

.feedPicture {
  order: 1;
}

.newsfeed .feedPicture img {
  width: auto;
  height: auto;
  /*max-height: 80vh;*/
  min-height: 75px;
  margin: auto;
}

.feedContainer .blc-text-publication .display-txt .display .feedPicture.img-annonce {
  /* margin-left:-30px;
    margin-right:-30px;
    width: calc(100% + 60px); */
  padding-top: 10px;
}

.feedContainer .blc-text-publication .display-txt .display .feedPicture.img-annonce+a {
  margin-left: -30px;
  margin-right: -30px;
  min-width: calc(100% + 60px);
}

.linkurl div div {
  position: relative;
  /* padding: 20px 0; */
}

.linkurl div div div {
  position: absolute;
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px) !important;
  padding: 20px 0;
}

.newsfeed .blc-detail-comment,
.profil-actus .blc-detail-comment {
  padding: 10px 26px 20px 26px;
  box-shadow: none;
}

.newsfeed .blc-add-comment,
.newsfeed .blc-inner-add-comment,
.profil-actus .blc-add-comment {
  padding: 0;
}

.newsfeed .blc-add-comment .usr-pub,
.newsfeed .blc-inner-add-comment .usr-pub {
  width: 40px;
  height: 40px;
}

.newsfeed .blc-detail-comment .blc-add-comment .blc-white-rounded,
.newsfeed .blc-detail-comment .blc-inner-add-comment .blc-white-rounded {
  width: calc(100% - 50px);
}

.newsfeed .blc-add-comment .blc-white-rounded,
.profil-actus .blc-add-comment .blc-white-rounded,
.blc-inner-add-comment .blc-white-rounded,
.favoris-page .blc-add-comment .blc-white-rounded {
  border: inset 1px rgba(51, 51, 51, 0.3);
  min-height: 39px;
  padding: 0 0 0 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
  width: 100%;
}

.newsfeed .blc-detail-comment .blc-inner-comments,
.newsfeed .blc-detail-comment .blc-inner-add-comment {
  padding-right: 0;
  padding-left: 50px;
}

/*
.newsfeed .usr-pub img,
.profil-actus .usr-pub img,
.favoris-page .usr-pub img {
    height: 40px;
    width: 40px;
} */

.newsfeed .blc-detail-comment .lpicto-video {
  display: flex;
  align-items: center;
  width: auto !important;
  padding: 0 20px;
}

.newsfeed .lpicto-video::after,
.profil-actus .lpicto-video::after,
.blc-inner-add-comment .lpicto-video::after,
.favoris-page .lpicto-video::after {
  background: url(./images/ico-send-gris.svg) no-repeat center center !important;
  content: '';
  display: block;
  height: 18px;
  width: 18px;
  background-size: 100%;
  margin: 0 auto;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-comment-content {
  padding-left: 0;
  padding-top: 20px;
  width: 100%;
  display: flex;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-comment-content span {
  word-wrap: break-word;
}

.newsfeed .blc-detail-comment .blc-inner-comments .blc-comment-item .blc-comment-content {
  padding-top: 0;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-comment-content .blc-comment-lvl-container {
  background: #f3fbfd;
  width: 100%;
  max-width: calc(100% - 50px);
  padding: 13px 15px 15px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.newsfeed .blc-detail-comment .blc-head-publication {
  min-width: 50px;
  padding: 0;
}

.newsfeed .blc-detail-comment .blc-comment-lvl-container .commented-text-container {
  display: block;
  padding-top: 10px;
  word-break: break-word;
}

/*.newsfeed .blc-detail-comment .blc-comment-lvl-container .blc-head-publication .usr-info-pub .usr-pub{
    overflow: unset;
    white-space: normal;
    display: inline;
    text-overflow: unset;
}*/
.newsfeed .blc-detail-comment .blc-comment-lvl-container .date-pub {
  width: auto;
  padding: 1px 5px 0;
}

.newsfeed .blc-detail-comment .blc-comment-lvl-container .usr-info-pub .date-pub {
  display: none;
}

.newsfeed .blc-detail-comment .blc-comment-lvl-container .commented-text {
  display: inline-block;
  padding: 0;
}

.newsfeed .blc-detail-comment .comment-text {
  display: inline-flex;
  padding: 12px 0 9px;
  width: calc(100% - 40px);
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item {
  padding: 0 0 10px 0;
  margin-left: 50px;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item .btn {
  width: auto;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item .btn:first-child {
  padding-left: 0;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item span {
  padding-left: 0;
  padding-right: 0;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item .lpicto-like:after,
.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item .lpicto-like-plein:after {
  height: 16px;
  width: 16px;
}

.newsfeed .blc-detail-comment .blc-comment-item .blc-action-comment-item .lpicto-chat:after {
  height: 15px;
  width: 15px;
}

.newsfeed .btn.has-child-comment .lpicto-chat:after {
  background: url(./images/BoutonMessage.svg) repeat;
  opacity: 0.7;
}

.btn.has-child-comment:hover .lpicto-chat:after {
  opacity: 1;
}

.newsfeed .blc-head-publication .usr-pub.usr-pub-img,
.newsfeed .blc-head-publication .usr-pub.usr-pub-img img {
  height: 50px;
  width: 50px;
}

.newsfeed .blc-head-publication .usr-info-pub {
  padding-top: 5px;
}

.newsfeed .blc-comment-item .blc-head-publication {
  padding-left: 0;
  padding-right: 0;
}

.newsfeed .blc-comment-item .blc-comment-lvl-container,
.newsfeed .blc-comment-item .btn-icon.lpicto-like {
  margin-left: 10px;
}

.newsfeed .blc-comment-item .btn.has-child-comment {
  display: flex;
}

.newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication,
.newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication .usr-info-pub {
  padding: 0;
  width: calc(100% + 6px);
}

.newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication .usr-info-pub {
  margin-right: auto;
}

.newsfeed .blc-comment-item .blc-comment-lvl-container .blc-head-publication .comment-option div:not(.ldropdown-content) button {
  width: 20px;
  box-sizing: content-box;
  padding: 0 5px;
  margin: 0;
}

.blc-detail-comment .comment-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/** -- annonce - wall - sidebars - preview - **/
.annonce-body .btn-goback {
  left: 20px;
}

.btn-goback button {
  display: block;
  width: 60px;
  height: 24px;
  cursor: pointer;
  position: absolute;
}

.preview .annonce-about {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0 0;
}

.preview .img-annonce {
  max-height: 120px;
  background-color: #eaeaea;
}

.preview .date-fin-annonce {
  align-items: center;
  justify-content: flex-end;
}

.preview .blc-annonce-item {
  margin-top: 16px;
}

.annonce-wall-item {
  border: solid 1px #eaeaea;
}

.annonce-wall-item .annonce-buttons {
  display: flex;
  padding: 17.5px 10px 0 10px;
  justify-content: space-around;
  align-items: center;
}

.wall-right .annonce-wall-item .annonce-title {
  padding: 0 10px;
}

.annonce-item .annonce-center .annonce-ref {
  padding: 0 6px;
}

.detailAnnonce .wall-right .annonce-type {
  padding: 0 12px;
  margin-right: 10px;
}

.wall-right .annonce-wall-item .annonce-type,
.annonce-center .annonce-type {
  padding: 0 12px;
  margin-right: 10px;
  max-width: 100px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  font-weight: 600;
}

.wall-right .annonce-wall-item .annonce-lib,
.annonce-center .annonce-lib {
  border: inset 2px #333;
  line-height: 11px;
  min-height: 30px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 3;
  font-weight: 600;
}

.annonce-center .annonce-lib {
  height: 30px;
}

.annonce-wall-item .annonce-title {
  font-size: 14px;
  font-weight: bold;
  margin-top: 22px;
}

.annonce-wall-item .localtarif-annonce {
  margin: 20px 0;
  height: 61px;
  border-top: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  position: relative;
}

/* .dashboard .annonce-wall-item .localtarif-annonce{
    display: block;
} */
.annonce-wall-item .annonce-location {
  flex-flow: column;
}

/* .annonce-wall-item .budget-od {
    flex: 0 0 50%;
    display: block;
    width: 50%;
}
.annonce-wall-item .annonce-location {
    flex: 0 0 50%;
    width: 50%;
    display: block;
} */
.annonce-wall-item .localtarif-annonce::before {
  position: absolute;
  width: 2px;
  height: 100%;
  content: '';
  background-color: #eaeaea;
}

.annonce-wall-item .lpicto-loc:after {
  height: 14px;
  width: 14px;
  background-size: 100%;
}

.annonce-wall-item .town-loc {
  margin: 0;
}

.annonce-wall-item .annonce-about {
  margin: 0;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 24px;
}

.dashboard .annonce-wall-item .annonce-about {
  margin: 20px 0;
}

.annonce-wall-item .info-author {
  margin: 0;
  width: 100%;
  align-items: center;
}

.annonce-wall-item .img-author {
  line-height: 0;
}

.annonce-wall-item .name-author-ctn {
  margin: 0;
}

.annonce-wall-item .info-author .lprofilPM-name {
  text-align: left;
}

.annonce-wall-item .action-annonce .lpicto-favoris,
.annonce-wall-item .action-annonce .lpicto-share-primary {
  height: 15px;
}

.annonce-wall-item .action-annonce .lpicto-favoris::after,
.annonce-wall-item .action-annonce .lpicto-share-primary::before {
  height: 15px;
  width: 15px;
  background-size: 100%;
}

.annonce-wall-item .action-annonce .btn {
  /* padding: 0 0 0 15px; */
}

.annonce-wall-item~.pagination-prev-next {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.annonce-wall-item~.pagination-prev-next .chevron-left,
.annonce-wall-item~.pagination-prev-next .chevron-right {
  cursor: pointer;
  height: 10px;
  width: 10px;
  padding: 0 5px;
  margin: 0 25px;
  opacity: 1;
  background-size: 100% !important;
}

/** - publier annonce - annonce form - **/
.annonce-form .form-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.annonce-form .boxes {
  padding: 25px 20px 30px 30px !important;
  border: 1px solid rgb(89, 193, 229, 0.5);
  width: 87%;
}

.annonce-form .label-form {
  margin-bottom: 8px !important;
}

.annonce-form .form-container .layout-small {
  padding: 0;
}

.annonce-form {
  font-family: Montserrat;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.annonce-form .headerPage-left {
  margin: 0 auto;
  padding: 0;
}

.annonce-form .header-annonce {
  height: unset;
}

.annonce-form .header-annonce .suggestdiv {
  height: unset;
}

.suggesth1 {
  font-weight: bold;
  margin-top: 80px !important;
}

.suggestcherchons {
  font-weight: bold;
  margin-top: 120px !important;
}

.txtinter {
  color: #4a4a4a;
  font-weight: 50;
}

.txtaccsug {
  margin-top: 40px;
  margin-bottom: 10px;
}

.bsuggestret {
  margin-left: 40px;
  margin-top: 20px;
  color: #000000;
  font-weight: 700;
}

.bsuggestretbis {
  margin-left: 40px;
  margin-top: 150px;
  color: #000000;
  font-weight: 700;
}

.bconditiongeneret {
  margin-left: 60px;
  margin-top: 40px;
  color: #000000;
  font-weight: 700;
}

.bconditiongeneretLP {
  background: none;
  border: none;
  position: fixed;
  cursor: pointer;
  top: 29%;
  left: 13%;
  color: #000000;
  font-weight: 700;
}

.bsuggestplusp {
  font-weight: 700;
  color: #000000;
}

.bsuggestnotif {
  width: 200px !important;
}

.suggestnbe {
  margin-left: 25px;
  font-weight: 700;
  color: #000000;
}

.suggestmfav {
  margin-left: 25px;
  font-weight: 300;
}

.bsuggestaccfav {
  margin-left: 60px !important;
  margin-top: -5px !important;
  padding: 0 4% !important;
}

.suggestdivbis {
  float: left;
  position: absolute;
  justify-content: center;
  margin-right: 1000px;
  height: 390px;
  width: 1250px;
  margin-left: 0px;
  background: url(./images/bg-suggestion-profil.PNG) no-repeat top center,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), #f9f9f9) !important;
}

.marlefthediv {
  margin-top: -80px;
}

.thediv {
  height: max-content;
  width: max-content;
}

.thedivLP {
  max-width: 80%;
  justify-content: center;
  margin: auto;
  margin-top: 150px;
}

.conditionsgenralescontainer {
  width: 1280px;
  height: max-content;
  background-color: #f8f7f9;
}

.conditiongeneraletop {
  position: fixed;
  margin-top: -300px;
  width: 1280px;
  height: 250px;
  background: url(./images/bg-header-annonce-form.png) no-repeat top center,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), #f9f9f9) !important;
}

.conditiongeneraletopLP {
  margin-bottom: 40px;
}

.txtexpertinformations {
  font-style: italic;
  margin-left: 40px;
  margin-right: 40px;
}

.expertinformations {
  width: 100%;
  height: max-content;
}

.txtcondgntp {
  font-weight: bold;
  margin-top: 100px !important;
}

.txtcondgntpLP {
  font-weight: bold;
  margin-top: 50px !important;
}

.cgsmention {
  margin-left: -100px !important;
}

.cgssocial {
  margin-left: 100px !important;
}

.cgsloglink {
  margin-left: 350px !important;
}

.cgssoulign {
  text-decoration: underline;
}

.conditionsgenralesommaire {
  position: fixed;
  overflow: auto;
  background-color: #ffffff;
  border-width: 1px;
  margin-left: 40px;
  height: 58%;
  width: 300px;
  float: left;
}

.conditionsgenralestext {
  background-color: #ffffff;
  border-width: 1px;
  margin-left: 360px;
  margin-right: 130px;
  margin-top: 290px !important;
  height: max-content;
}

.conditionsgenralestextLP {
  background-color: #ffffff;
  border-width: 1px;
  margin-left: 360px;
  margin-right: 130px;
  margin-top: 0px !important;
  height: max-content;
}

.conditiongeneralmaintxt {
  margin-top: 70px;
  margin-left: 50px;
  margin-right: 50px;
  height: max-content;
}

.espacediv {
  height: 80px;
}

.condgenpinterlign {
  line-height: 2em;
}

.pmar10 {
  margin-top: 10px;
}

.pmar7 {
  margin-top: 7px;
}

.condgenmail {
  font-weight: 450;
  text-decoration: underline;
  color: #6d77fb;
}

.realisemissionsoutitre {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 15px;
}

.realisemissionsousoutitre {
  font-size: 14px;
  font-weight: 450;
  margin-bottom: 15px;
  margin-top: 15px;
}

.condgenpdef {
  margin-top: 70px;
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 700;
}

.condgensomtitl {
  font-size: 28px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
}

.condgenseparator {
  border-width: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  height: 1px;
}

.condgensomitem {
  font-size: 16px;
  font-weight: 650;
  line-height: 2em;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.condgensomsouitem {
  line-height: 2em;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.condgensomsousouitem {
  font-size: 12px;
  font-weight: 400;
  line-height: 2em;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.marlef20 {
  margin-left: 20px;
}

.condgenelem {
  font-weight: 700;
}

.suggestcontent {
  margin-top: 20px;
  min-height: 1420px;
  height: max-content;
  width: 100%;
}

.suggestfiltre {
  border-width: 1px;
  margin-top: 0px !important;
  height: 1400px;
  width: 300px;
  float: left;
}

.blc-suggest-content {
  margin-left: 300px;
  height: max-content;
}

.blc-suggest-scroll {
  height: max-content;
  display: flex;
  flex-wrap: wrap;
}

.blc-suggest-noscroll {
  max-height: 1150px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.isScrollable {
  overflow: auto;
  overflow-x: hidden;
}

.suggestbtshowmore {
  margin-top: 30px;
  color: #000;
  font-weight: 600;
}

.suggestprofil {
  margin-top: 30px;
  margin-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  /*height: 350px;*/
  width: 285px;
  border-width: 1px;
  background-color: white;
}

.suggestprofilhaut {
  display: flex;
  align-items: flex-start;
}

.suggestpinfo {
  margin-left: 15px;
  /*height: 100px;*/
  width: 250px;
}

.suggesticon {}

.suggestpicto {
  position: absolute;
  margin-top: 3px;
  margin-left: 15px;
  margin-right: -10px;
}

.suggestprofilfunc {
  margin-top: 10px;
  color: #999;
  font-weight: bold;
}

.suggestspscient {
  color: #6c6c6c;
  font-weight: 500;
  margin-left: 20px;
  margin-top: -22px;
}

.suggestschool {
  margin-top: 3px !important;
  margin-left: 1px;
  font-weight: 500;
  color: #4a4a4a;
}

.suggestloc {
  margin-top: 2px !important;
  margin-left: 2px;
  font-weight: 500;
  color: #4a4a4a;
}

.suggestploc {
  margin-left: -2px;
}

.suggestlang {
  margin-top: 1px !important;
  margin-left: 1px;
  font-weight: 500;
  color: #4a4a4a;
}

.marg5 {
  margin-left: 5px;
}

.suggestblcetude {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: -10px;
}

.suggestprofseparation {
  margin-top: 95px;
  border-width: 1px;
  height: 2px;
  margin-left: 20px;
  margin-right: 20px;
}

.suggestprofilblcbtn {
  height: 50px;
  display: flex;
}

.btn-suggest-vp {
  width: 35%;
  margin-left: 20px;
  margin-top: 10px;
}

.btn-suggest-pm {
  width: 40%;
  margin-left: 10px;
  margin-top: 10px;
}

.suggestprofilpertinence {
  margin-top: 0;
  width: 150px;
  height: 30px;
  background-color: #efe7f3;
}

.suggestprofiltxtpertinence {
  padding-top: 1mm;
  font-weight: bold;
  color: #af23f5;
}

.tpertinence {
  margin-top: 5px;
}

.suggestbtfav {
  margin-top: 3px;
  margin-left: 95px;
}

.lpicto-favorisbis:after {
  content: '';
  background: url(./images/ico-favoris.svg) no-repeat;
  display: inline-block;
  height: 24px;
  width: 23px;
  opacity: 0.7;
  margin-left: -10px;
}

.imgsuggest {
  margin-left: 20px;
  margin-top: 10px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 24px;
}

.ronddispo {
  margin-left: 20px;
  margin-top: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #703dca;
  display: flex;
}

.pdispo {
  margin-left: 15px;
  color: #e1b8f9;
  font-weight: 900;
}

.suggestimg-name-certif {
  display: flex;
  width: 100%;
  height: max-content;
}

.namedispo {
  max-width: 180px;
}

.suggest-icon-certif {
  width: 20px;
  margin-left: 5px;
}

.suggestcertif {
  margin-top: 0px !important;
  margin-left: 0px !important;
  height: 13px !important;
  width: 13px !important;
}

.lpicto-certifiedbis:after {
  content: '';
  background: url(./images/ico-certif-bleu.svg) no-repeat center center;
  display: block;
  height: 13px;
  width: 15px;
  border-radius: 50%;
  margin-top: 20px;
}

.suggestimg {
  width: 45px;
  margin-top: 2px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.suggestnamep {
  margin-top: 10px;
  margin-left: 0px;
  font-weight: bold;
}

.suggestpiedpage {
  position: relative;
  margin-top: 100px;
  height: 100px;
  width: 915px;
}

.suggestpiedpagecontent {
  margin-left: 20px;
  margin-top: 10px;
  height: 90px;
  border-width: 1px;
  display: flex;
  align-items: center;
}

.suggestdiv {
  height: 400px !important;
}

.annonce-form .header-annonce h1 {
  margin-top: 85px;
  margin-bottom: 75px;
  padding: 0;
}

.annonce-form .blc-annonce-form {
  background: #fff;
  margin: 0 105px 118px 105px;
  position: relative;
}

.annonce-form .btn-goback {
  top: -30px;
  left: 0;
}

.annonce-form .layout-small {
  width: 420px;
}

.annonce-form .lselect {
  width: unset;
}

.annonce-form .rowForm {
  margin: 0;
}

.annonce-form .rowForm.partedtwo {
  margin: 0;
}

.annonce-form .label-form,
.editProfil-page .label-form,
.blc-company .label-form,
.form-any-creation .label-form {
  margin-bottom: 15px;
  letter-spacing: -0.5px;
  line-height: 2;
  font-size: 16px;
  color: #333;
}

.blc-dialogForm .label-form {
  margin: 8px 0;
  min-height: 18px;
}

.label-popup-cookies {
  min-height: 34px;
  position: relative;
  right: 5%;
  bottom: -40px;
  border-bottom: 3px solid;
}

.popupContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupMain {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.popupMainText {
  border-bottom: 2px solid;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.annonce-form .sublabel-form {
  display: block;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
  line-height: 1.5;
  margin-top: 20px;
  color: #333;
}

.annonce-form .radio-container .lradio-mark {
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex: 0 0 20px;
  position: relative;
  height: 20px;
}

.annonce-form .lpicto-check-secondary:after {
  background: url(images/check-radio.svg) no-repeat center center;
  height: 22px;
  width: 22px;
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.annonce-form .radio-container .lradio {
  margin-right: 0px;
}

.annonce-form .radio-container .lradio .lradio-text {
  margin-right: 0px;
}

.annonce-form .lradio-text {
  font-size: 12px;
  color: #333;
}

.annonce-form .blc-select-container {
  height: 40px;
}

.annonce-form .blc-annonce-user .blc-select-container,
.annonce-form .blc-form-annonce-category .blc-select-container {
  width: auto;
  border: 1px solid;
}

.blc-select-container.form-organisme-input:after {
  background: none;
}

.annonce-form .blc-form-annonce-category .blc-select-container.form-organisme-input {
  padding: 0;
  cursor: pointer;
}

.annonce-form .blc-form-annonce-category .blc-select-container.form-organisme-input div {
  width: 100%;
  /* padding:0 20px; */
}

.annonce-form .blc-form-annonce-category .blc-select-container.form-organisme-input input {
  width: 100%;
  padding: 0 20px;
}

/* .annonce-form .blc-annonce-user .blc-select-container select,
    .annonce-form .blc-form-annonce-category .blc-select-container select{
    padding: 20px;
    font-size: 11px;
    border: none;
} */
.annonce-form .textarea-form-description {
  border: rgba(51, 51, 51, 0.3) solid 1px;
  height: 200px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px;
  margin-top: 10px;
}

.annonce-form .lselect,
.annonce-form .lselect input {
  height: 40px;
}

.annonce-form .lselect .selectedItem.put-inline {
  justify-content: unset;
}

.annonce-form .lselect .selectedItem .blc-contact-img,
.annonce-form .lselect .selectedItem .blc-contact-info .profession {
  display: none;
}

.annonce-form .lselect .selectedItem .blc-contact-info .name {
  color: rgba(51, 51, 51, 0.6);
  font-weight: 200;
  font-size: 11px;
}

.blc-dialogForm .selectedItem .blc-contact-info {
  width: calc(100% - 36px);
}

.annonce-form .lselect .lselect-list {
  top: 40px;
  max-height: 280px;
  overflow: auto;
}

.annonce-form .lselect .lselect-list .name {
  color: #333;
}

.annonce-form .lselect .lselect-list .profession {
  width: unset;
}

.annonce-form .max-characters-indice {
  line-height: 1;
  margin-top: 10px;
  /*margin-bottom: -20px;*/
}

.linkinnov-premium-user .notif-premium-user {
  display: inline-flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.linkinnov-premium-user .be-premium-link {
  display: inline-flex;
  align-items: center;
}

.linkinnov-premium-user .be-premium-link .chevron-right {
  margin-right: 11px;
  width: 10px;
  height: 10px;
  background-size: 100%;
  background-position: center !important;
}

.linkinnov-premium-user .checkbox-slider-container.lcheckbox {
  display: inline-flex;
  align-items: center;
  width: unset;
}

/*
.checkbox-slider-container input[desabled]~*{
    opacity: 0.3;
    cursor: unset;
}*/
.linkinnov-premium-user .checkbox-slider-container {
  margin-top: 22.5px;
}

.lpicto-checkbox-slider {
  display: inline-flex;
  position: relative;
  min-width: 38px;
  height: 18px;
  background: #59c1e5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.lpicto-checkbox-slider::after {
  content: '';
  position: absolute;
  top: 1px;
  right: 21px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.lpicto-checkbox-slider::after {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.lpicto-checkbox-slider+.lcheckbox-text {
  margin-left: 15px;
}

.annonce-form .lradio-text {
  padding-left: 15px;
  width: 100%;
}

.annonce-form input[disabled]~ {
  opacity: 0.5;
}

.annonce-form input:checked+.lpicto-checkbox-slider::after,
input:checked+.lpicto-checkbox-slider::after {
  right: 1px;
}

.annonce-form input:not(:checked)+.lpicto-checkbox-slider,
input:not(:checked)+.lpicto-checkbox-slider {
  background: rgba(51, 51, 51, 0.4);
}

.annonce-form .formAction {
  margin-top: 60px;
  display: inline-flex;
  justify-content: center;
}

.annonce-form .formAction button {
  padding: 0 30px;
}

.annonce-form .formAction button:last-child {
  margin-right: 0;
}

.annonce-form .blc-profil-header-form {
  margin: 0;
}

.annonce-form .blc-profil-header-form .rowForm {
  margin: 0;
}

.annonce-form .annonce-visuel-img {
  height: 98px;
  margin-right: 37px;
  width: 98px;
  background: #f9f9f9 url('images/groupe-empty-icon.svg') center no-repeat;
  background-size: 100%;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.annonce-form .annonce-visuel-img .blc-img-profil {
  /* flex: 0 0 auto;
    min-height: 100%;*/
  object-fit: cover;
  flex: 0 0 100%;
  height: 100%;
}

.annonce-form .in-file-photos {
  border: rgba(51, 51, 51, 0.3) solid 1px;
  padding: 12px 20px;
  font-size: 11px;
}

.annonce-form .competence-selectioned.put-inline {
  padding: 10px 0 5px 0;
  justify-content: left;
}

.annonce-form .competence-selectioned .competence-item {
  padding: 0px 30px;
  position: relative;
  left: -10px;
}

.annonce-form .layout-small .react-datepicker__input-container:after {
  display: none;
}

.annonce-form .react-datepicker__input-container {
  border: none;
  margin: 0;
  width: 120px;
  height: 100%;
  font-size: 12px;
  height: auto;
}

/* .annonce-form .layout-small .react-datepicker__input-container::before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background: url(./images/chevron-down.svg) no-repeat center center;
    right: 14px;
    background-size: 76%;
    top: 27px;
} */
.annonce-form .fourchette-container {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.annonce-form .fourchette-container .inpForm {
  margin: 0;
}

.annonce-form .formAction-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.annonce-form .blc-form-training .formAction {
  position: absolute;
  width: auto;
}

/** --- wall university --- **/
.wall-university .blc-contact-item {
  padding: 0;
}

.wall-university .img-container {
  position: relative;
}

.wall-university .major-first .img-container::after {
  content: '';
  box-sizing: content-box;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(./images/ico-fav-active-white.svg) no-repeat;
  background-size: 9px auto;
  background-position: center;
  background-color: #59c1e5;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.wall-university .lprofilPM-categorie.relations .dot-separator {
  display: inline-flex;
  margin: auto 4px;
  font-weight: 900;
  line-height: 0.5;
  position: relative;
  bottom: 2px;
}

.wall-university .major-first .lprofilPM-categorie.relations {
  font-weight: bold;
  font-size: 10px;
}

.wall-university .major-first .lprofilPM-categorie .classement {
  color: #59c1e5;
}

.wall-university .lprofilPM-name,
.wall-university .lprofilPM-categorie {
  margin-bottom: 4px;
}

/** LinkedIn - ResearchGate -- buttons signin **/
.form-signin-ctnr .short_signin_container {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 30px;
  margin-bottom: -10px;
  padding-bottom: 45px;
  border-bottom: solid 1px rgba(51, 51, 51, 0.2);
}

.btn-short_signin.btn {
  height: 40px;
  align-items: center;
  line-height: 1;
  font-weight: 600;
  font-size: 12px;
  margin: 0 3px;
  padding: 0 30px;
  border-radius: 26px !important;
  -webkit-border-radius: 26px !important;
  -moz-border-radius: 26px !important;
  -ms-border-radius: 26px !important;
  -o-border-radius: 26px !important;
}

.logo-shortsignin:before {
  content: '';
  display: inline-block;
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.logo-linkedin:before {
  background: url('images/In-White-14@2x.png') no-repeat;
  background-size: cover;
  background-position: center;
}

.logo-researchgate:before {
  background: url('images/logo-researchgate.png') no-repeat;
  background-size: 128%;
  background-position: center;
}

.btn-short_signin.btn-ResearchGate {
  background: #00ccbb;
  color: #fff;
}

.popup_short_signin.profilInfoDialog {
  padding: 25px 150px 45px 150px !important;
}

.profilInfoDialog .rowForm .inpForm {
  margin-top: 0;
  border-width: 1px !important;
  color: #333333 !important;
}

.rowForm .inpForm:hover,
.rowForm .inpForm:focus,
.rowForm textarea:hover,
.rowForm .blc-select-container:hover,
.landing .in-file-photos:hover,
.rowForm textarea:focus,
.landing .in-file-photos.selected {
  border: 1px solid #59c1e5 !important;
}

.react-datepicker__input-container input:hover,
.react-datepicker__input-container input:focus {
  border-color: #59c1e5;
}

.profilInfoDialog .rowForm.log .inpForm {
  border-width: 1px !important;
}

.profilInfoDialog .rowForm.pw .inpForm {
  letter-spacing: 2px;
  /* font-weight: 700; */
}

.btn-dialog-container button {
  margin: auto;
}

.popup_short_signin .login-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 60px 10px;
  font-family: 'Montserrat', sans-serif;
  width: 400px;
  padding-bottom: 10px;
  align-self: center;
}

.popup_short_signin .login-title div {
  font-weight: 200;
  font-size: 19px;
  line-height: 10px;
}

.popup_short_signin .subscribe-with-label {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.popup_short_signin .linkedin_logo_lg {
  display: flex;
  background: url('images/LinkedIn-Blue-48.png') no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 125px;
  height: 50px;
  margin-top: 25px;
}

.popup_short_signin .researchgate_logo {
  display: block;
  background: url('images/logo-researchgate.png') no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-right: 6px;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.popup_short_signin .researchgate_logo_lg {
  display: flex;
  background: url('images/logo-RG-long.png') no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 150px;
  height: 50px;
}

/** creation - entreprise - company */
.company-main-bg {
  background-color: transparent;
  display: flex;
}

.form-any-creation.blc-company {
  font-family: Montserrat;
  padding-top: 69px;
  padding-bottom: 132px;
  position: relative;
  background-color: #fff;
  margin: 60px 108px 46px 102px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blc-company .in-file-photos {
  border: rgba(51, 51, 51, 0.3) solid 1px;
  padding: 12px 20px;
  font-size: 11px;
}

.blc-company .blc-company-title {
  letter-spacing: -1px;
  margin: 0;
}

.blc-company .profil-any-container {
  margin: 30px 0;
}

.blc-company .blc-select-container,
.blc-any-creation-inside .blc-select-container {
  width: auto;
  border: 1px solid;
}

.blc-any-creation-inside .rowForm .inpForm {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

/* .blc-company .blc-select-container select,
.blc-company .rowForm .blc-select-container select{
    padding: 20px;
    font-size: 11px;
    border: none;
} */
.blc-company .blc-select-container .css-epgpks,
.blc-company .blc-select-container .css-1pcexqc-container,
.blc-company .blc-select-container .css-1hwfws3,
.blc-select-container input {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

/* .blc-company .blc-select-container .css-1hwfws3,
.blc-any-creation-inside .lautocomplete .blc-select-container input{
    padding:0 18px;
} */
.lautocomplete.no_chevron .blc-select-container::after {
  display: none;
}

.blc-company .blc-select-container .css-1hwfws3 {
  border: none;
}

.blc-company .label-form {
  margin-top: 30px;
}

.autocomplete-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper>div {
  width: 100%;
}

.autocomplete-wrapper input {
  padding: 12px 15px;

  width: 100%;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
}

/* .autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
} */

/* .autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #fafbfc;
} */

/* .autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #fafbfc;
} */

.txt-li {
  font-size: 12px !important;
}

.rowForm .textoHs,
.blc-company .label-form~textarea,
.blc-any-creation-inside .label-form~textarea,
.form-any-creation .label-form~textarea {
  height: 200px;
  padding: 20px;
  font-size: 11px;
  border: rgba(51, 51, 51, 0.3) solid 1px;
}

.react-datepicker-wrapper {
  height: 40px;
  /* border:rgba(51, 51, 51, 0.3) solid 1px; */
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  /* padding:0 0 0 20px; */
  font-style: normal;
  position: relative;
}

.react-datepicker__input-container:after {
  display: none;
}

.react-datepicker__input-container {
  border: none;
  margin: 0;
  width: 120px;
  height: 100%;
  font-size: 12px;
}

.blc-newprod-content .react-datepicker__input-container,
.pageCreateForm .react-datepicker__input-container {
  height: 100%;
  width: 130px;
}

.react-datepicker__input-container::before {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  background: url(./images/chevron-down.svg) no-repeat center center;
  right: 14px;
  background-size: 76%;
  top: 12px;
}

.rowForm .react-datepicker__input-container input,
.rowForm .react-datepicker__input-container span.inpForm {
  font-size: 12px;
  /* height: 100%; */
  margin: 0;
}

.rowForm .react-datepicker__input-container span.inpForm {
  align-items: center;
  display: flex;
}

.blc-company .actions-centered .btn {
  margin: 0;
}

.blc-company .actions-centered .btn-valider {
  margin-left: 10px;
}

.btn-valider.desabled {
  pointer-events: none;
  opacity: 0.5;
}

.text-error-validation {
  display: block;
  text-align: center;
  margin: 15px 0;
  color: #f00;
}

.error-msg {
  color: #f00;
  display: block;
  margin: 10px 0 20px;
}

.blc-msg-red {
  padding: 6px 35px;
  font-size: 11px;
  font-weight: 700;
  color: #a03ee6;
  text-align: center;
  background: #fff;
  border: #a03ee6 1px solid;
  margin: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.landing .tab-standard-body .signin-submit {
  margin-top: 50px;
}

/** ----- CHAT - 2 ---- **/
.chat-container,
.chat-container [class*='MuiTypography-body'] {
  font-family: Montserrat;
}

.chat-container .blc-chat,
.chat-container .blc-chat .chat-info {
  height: 100%;
}

.chat-container .blc-chat .chat-info header.chatInfo-user {
  flex: 1 2;
  overflow: auto;
}

.chat-container .blc-chat .chat-info .custom-label-statut,
.chat-info .custom-label-statut {
  font-family: Montserrat;
  text-align: left;
  display: block;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

.chat-container .blc-chat .chat-info [class*='MuiAppBar-root-'] {
  z-index: 99;
}

.chat-container .blc-chat .btn-goback {
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
}

.chat-container .blc-chat .chat-sidebar {
  box-shadow: none !important;
  border: none;
}

.chat-container .blc-chat .chat-sidebar .new-message-container .chat-search {
  margin-bottom: 0;
  height: 100%;
  color: rgba(51, 51, 51, 0.6);
  padding: 5px 0px;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.chat-container .blc-chat .chat-sidebar .chat-search-container {
  max-height: 100px;
  height: 100%;
  border-bottom: 1px rgb(234, 234, 234) solid;
  padding: 20px;
}

.chat-container .blc-chat .chat-search .label-send-to {
  font-weight: 700;
  position: absolute;
  line-height: 18px;
}

.chat-container .container-input-message2 .input-message {
  max-height: 50px;
  padding-right: 8px;
}

.chat-new-container .chat-search .search-textarea {
  height: 72px;
  resize: none;
  text-indent: var(--indent);
  line-height: 18px;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
}

.chat-new-container .chat-search textarea.search-textarea::-webkit-input-placeholder {
  text-indent: var(--indent);
}

.chat-new-container .chat-search textarea.search-textarea::-moz-placeholder {
  text-indent: var(--indent);
}

.chat-new-container .chat-search textarea.search-textarea:-moz-placeholder {
  text-indent: var(--indent);
}

.chat-new-container .chat-search textarea.search-textarea:-ms-input-placeholder {
  text-indent: var(--indent);
}

.chat-new-container .chat-search textarea.search-textarea::placeholder {
  text-indent: var(--indent);
}

/*------------------------*/
.btn-add-plus-text {
  font-size: 18px;
  font-weight: 500;
}

/*=====end=====*/

/* @media(min-height: 1024px) and (min-width: 1024px) {
    .footer-home {
        position: absolute;
        bottom: 0;
    }
} */

@media screen and (max-width: 1024px) {
  .annonce-body {
    /* margin: 0 2px; */
  }

  /* .wrapper-annonce .date-fin-annonce{
        flex: 0 0 40%;
    } */

  /* ------- group view -------*/
  .group-view-actu .profil-info-center {
    width: 100%;
  }

  .group-view-actu .profil-info-right {
    display: none;
  }

  .group-view-actu .blc-profil-info {
    margin: 0;
  }

  .group-view-actu .wall-center.group-actu-feed,
  .profil-enterprise .wall-center.corporation-feed {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
  }

  .group-view-actu span+.member-status-text {
    display: inline-block;
    margin-left: 10px;
  }

  /* ----- group list ----- */
  .group-list .blc-group-filter {
    width: 100%;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
  }

  .group-list .blc-group-filter .blc-group-search-bar {
    max-width: 100%;
    min-width: 45px;
    width: 100%;
    flex: unset;
    margin-left: 10px;
  }

  .group-list .blc-group-filter .blc-group-action {
    margin-top: 0;
    margin-left: 10px;
  }

  .group-list .blc-group-filter .blc-new-group .btn {
    margin: 0;
    font-weight: 700;
  }

  .group-list .blc-group-filter .blc-select-container,
  .group-list .blc-group-filter .blc-group-search-bar {
    flex: 1 1;
  }

  .group-list.container {
    padding: 0 40px;
  }

  .group-list .wall-left {
    margin-right: 0;
  }

  .group-list .wall .wall-center {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 45px;
    width: 100%;
  }

  /* paramètre de compte */
  .blc-parametrage .blc-param-left {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .signin-submit .container {
    /*flex-direction: column;*/
    flex-direction: column-reverse;
  }

  .landing .tab-standard-header {
    padding: 0 20px;
  }

  .landing .signin-submit button {
    margin-top: 15px;
  }

  /* ----- group list ----- */
  .group-list .blc-group-filter {
    width: 100%;
    /* margin: 20px auto 0 auto; */
  }

  .group-list.container {
    padding: 0 40px;
  }

  /* paramètre de compte */
  .blc-parametrage .blc-param-left .paramMenulist {
    padding: 30px;
  }

  .blc-parametrage .blc-param-right {
    margin-left: 0;
    padding-left: 5px;
    flex: 0 0 62%;
  }

  .blc-parametrage .blc-param-right .accordionForm-body {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .blc-choice {
    border: rgba(51, 51, 51, 0.3) solid 1px;
  }

  .form-signin-ctnr {
    padding-bottom: 22px;
  }

  .form-signin-ctnr .short_signin_container {
    flex-direction: column;
    padding: 0 0 30px 0;
  }

  /* .form-signin-ctnr .short_signin_container button{
        margin-top: 10px
    } */
  .sector p {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .blc-company.form-any-creation .blc-any-creation-inside h1.blc-company-title {
    font-size: 16px;
    margin-top: 20px;
  }

  /*CONNEXION*/

  .profilInfoDialog.login-dialog {
    height: 500px;
    max-width: 280px;
    padding: 20px 35px 20px 35px !important;
  }

  .imageResizerBackground .profilInfoDialog.login-dialog {
    height: 500px;
    width: 100% !important;
    max-width: 450px;
    padding: 20px 35px 20px 35px !important;
  }

  .login-title {
    font-size: 26px;
    line-height: 40px;
    padding: 50px 10px;
  }

  .login-dialog .rowForm input,
  .login-dialog .rowForm .btn-connexion {
    height: 40px;
    padding: 10px;
  }

  .login-dialog .login-form .pwd-forgot {
    margin-left: auto;
    margin-right: 0;
    display: block;
    width: auto;
    margin-bottom: 32px;
  }

  .login-form .pwd-forgot {
    text-decoration: underline;
    font-size: 9px !important;
  }

  .login-dialog .login-quit {
    right: 5px;
    top: 5px;
  }

  /*INSCRIPTION*/

  .header-title {
    padding-bottom: 0px;
  }

  .header-title .container {
    height: 158px;
  }

  /* .header-title h1{
        font-size:26px;
        line-height: 32px;
        padding: 20px 30px 15px;
    } */
  .header-title p {
    font-size: 12px;
    line-height: 22px;
    color: #000;
    padding: 0 20px;
  }

  .rowForm .inpForm {
    max-width: 420px;
    /* margin: 10px auto 0 auto; */
    display: block;
    width: 99.06%;
  }

  /* .rowForm .inpForm.border-cobalt{
        border: 1px solid #59c1e5 !important;
    } */

  .blc-signin {
    padding: 0 34px;
    margin: 0 auto 17px !important;
  }

  .sign-mail,
  .sign-pw {
    padding: 0;
  }

  .sign-condition {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .sign-condition input {
    width: 16px;
    height: 16px;
    top: 30px;
  }

  .sign-condition input+label {
    font-size: 11px;
    line-height: 18px;
    padding-left: 30px;
  }

  .sign-condition input+label::before {
    border: 2px solid #59c1e5;
    width: 16px;
    height: 16px;
  }

  .sign-condition input:checked+label:after {
    left: 3px;
    top: 17px;
  }

  .label-form-inscription {
    margin-top: 30px;
    font-size: 14px;
    letter-spacing: -0.6px;
  }

  .landing .signin-submit button {
    margin: 0 35px;
    text-transform: none;
    letter-spacing: 1px;
    font-size: 12px;
    height: 40px;
    width: 210px;
  }

  .signin-submit .container {
    padding: 0 35px;
  }

  /*group creation form*/
  .form-group-creation,
  .form-any-creation,
  .form-any-creation.blc-company {
    margin: 18px;
    padding: 10px 20px 12px 20px;
  }

  .form-any-creation .pageCreateForm .blc-action-bottom .btn.plr-35px {
    padding: 0 20px !important;
  }

  /* group detail - group view*/
  .group-view-actu .profil-info-right {
    display: none;
  }

  .group-view-actu .profilInfo.blc-profil-info {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .group-view-actu .blc-select-container.lselect-secondary:after {
    top: 12px;
  }

  .group-view-actu .groupeProfil-info {
    justify-content: center;
    text-align: center;
  }

  .group-view-actu .profil-page .lpicto-favoris {
    display: block;
  }

  .group-view-actu .blc-img-profil .lpicto-edit:after {
    right: 20px;
    top: 80px;
  }

  .group-view-actu .groupe-profil {
    margin-bottom: 0;
  }

  .group-view-actu .newsfeed .btn-action-item:nth-of-type(3) {
    margin-left: unset;
  }

  .group-view-actu .blc-btn-action-publication {
    justify-content: space-between;
  }

  .group-view-actu .blc-btn-action-publication .btn-action-item {
    flex: unset;
  }

  /* ---- groupe liste ---- */
  .group-list .blc-group-filter {
    padding: 0;
  }

  .group-list .blc-group-filter .blc-new-group {
    width: 100%;
  }

  .group-list .blc-group-filter .blc-group-action button {
    width: auto;
    margin-right: 10px;
  }

  .group-list .blc-group-action i+span {
    display: none;
  }

  .group-list .blc-group-action button {
    width: auto;
  }

  .group-list .blc-select-container.lselect-secondary:after {
    top: 13px;
  }

  .group-list .search-form>.search-text {
    padding-right: 0;
    width: 100%;
  }

  .group-list .blc-group-filter .blc-group-action .blc-new-group .btn {
    padding: 0 20px;
    font-size: 15px;
  }

  .group-list .blc-group-filter .blc-group-action .btn>i.lpicto-filter {
    margin: 0;
  }

  .group-list .blc-group-filter .blc-group-action .btn>i.lpicto-filter:after {
    margin: 0;
    width: 15px;
    height: 11px;
    background-size: 100% 100%;
  }

  .group-list .btn-add-plus-text {
    font-size: 22px;
  }

  .group-list .blc-group-list-item {
    height: auto;
    padding: 20px 0;
  }

  /* paramètre de compte */
  .blc-parametrage .blc-param-compte .blc-param-content.container {
    flex-flow: column;
    padding: 0 60px;
  }

  .blc-parametrage .blc-param-left {
    flex: unset;
  }

  .blc-parametrage .blc-param-left .paramMenulist {
    padding-top: 0;
    padding-bottom: 0;
  }

  .blc-parametrage .blc-param-left .paramMenulist .title-right {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .blc-parametrage .paramMenulist ul {
    display: none;
  }

  .blc-parametrage .blc-param-left .paramMenulist,
  .blc-parametrage .blc-param-right {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .blc-parametrage .blc-param-compte .blc-param-right .blc-param-title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .blc-parametrage .blc-param-compte .blc-param-right .accordionForm-subtitle {
    margin-top: 7px;
  }

  .blc-parametrage .blc-param-compte .blc-param-right section {
    margin-bottom: 30px;
  }

  .blc-parametrage .blc-param-right .accordionForm-body {
    padding: 20px 55px;
  }

  /* ---- CHAT ---- */

  .chat-container .blc-chat.mobile-active,
  .chat-container .blc-chat.mobile-active [class*='Connect-ChatApp--drawerPaper'],
  .chat-container .blc-chat.mobile-active .chat-info {
    width: 100%;
  }

  .chat-container .blc-chat .chat-info .sidetchat-rb {
    height: auto;
  }

  .chat-container .chat-new-container {
    overflow-y: auto;
    height: calc(100vh - 110px);
  }

  .chat-container .chat-new-container .blc-chat.mobile-active.left-chat {
    flex: unset;
  }

  .chat-container .chat-new-container .blc-chat.left-chat .chat-toobar .chat-avatar {
    display: inline-block;
    margin-right: 15px;
  }

  .chat-container .bg-249 {
    background-color: rgb(249, 249, 249);
  }

  .chat-container .blc-chat.mobile-active.custom-bg-white .chat-WrapHeader.bg-249 {
    background-color: rgb(249, 249, 249) !important;
    border: none;
  }

  .chat-container .blc-chat .btn-goback {
    position: absolute;
    top: 0;
    left: 5px;
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .chat-container .blc-chat .btn-goback .chevron-left {
    width: 10px;
    height: 10px;
    background-size: 10px 10px !important;
  }

  .chat-container .tchat-center .header-user-contact {
    justify-content: flex-start;
    padding-left: 35px;
  }

  .chat-container .tchat-center .status-user {
    text-align: left;
  }

  .chat-container .tchat-center .group-action {
    position: absolute;
    right: 0;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .chat-container .tchat-center .group-action button.btn {
    height: 100%;
    width: 54px;
    padding: 15px 20px;
  }

  .chat-container .tchat-center .group-action .lpicto-menu:after {
    position: unset;
  }

  .chat-container .container-input-message {
    width: calc(100% - 40px);
    margin: auto;
  }

  .chat-container .uploads-btn-container button:first-child {
    padding-left: 0;
  }

  .chat-container .container-input-message .chat-textarea input {
    height: auto;
  }

  .chat-container .my-list-chat {
    max-height: unset;
    height: auto;
  }

  .chat-container .blc-chat .chat-info header.chatInfo-user {
    flex: none !important;
  }

  .chat-container .blc-chat .chat-sidebar .new-message-container .chat-search {
    height: 45px;
  }

  .chat-container .blc-chat .chat-sidebar .chat-search-container {
    max-height: unset;
    height: 75px;
  }

  .chat-new-container .chat-search .search-textarea {
    height: 45px;
  }
}

@media screen and (max-width: 575px) {
  .blc-choice {
    width: 100%;
  }

  /* .landing .signin-submit button{
        width: 100%;
    } */
  .form-signin-ctnr .put-inline .lradio {
    width: 100%;
  }

  /* diplome dialog form*/
  .blc-dialogForm.diplome-form .dialogForm-inside {
    width: 100%;
  }

  /*------group creation form-------*/
  .form-group-creation .radio-container .lradio {
    margin-bottom: 20px;
  }

  .form-group-creation .radio-container.put-inline {
    margin-bottom: 5px;
  }

  .form-group-creation .groupeProfil-img-creation,
  .anyProfil-img-creation {
    width: 71px;
    height: 71px;
  }

  .blc-group-creation-inside,
  .blc-any-creation-inside {
    width: 100%;
  }

  .blc-group-creation-inside .form-big-title,
  .blc-any-creation-inside .form-big-title {
    text-align: left;
    font-size: 14px;
  }

  .form-group-creation .groupeProfil-img-creation,
  .anyProfil-img-creation {
    margin-right: 15px;
  }

  .form-group-creation .in-file-photos,
  .form-any-creation .in-file-photos {
    width: 100%;
  }

  .form-group-creation .file-photos,
  .form-any-creation .file-photos {
    flex: 1 0;
  }

  .form-group-creation .inpForm {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .form-group-creation .label-form-group {
    font-size: 13px;
  }

  .form-group-creation .label-form-group.first {
    margin-top: 0;
  }

  /* .label-form-group + .inpForm{
        margin:0;
    } */
  .form-group-creation .blc-profil-header-form {
    margin: 0;
  }

  .blc-group-creation-inside .big-title-container.put-inline {
    justify-content: flex-start;
  }

  .file-chooser-container {
    width: 100%;
  }

  .file-chooser-container .groupeProfil-img-creation,
  .file-chooser-container .anyProfil-img-creation {
    align-self: flex-start;
    margin-top: 10px;
  }

  a.btn-goback {
    display: none;
  }

  .invitation-group a.btn-goback {
    display: block;
  }

  .file-photos .img-format-desc {
    text-align: left;
    font-size: 9px;
    color: rgba(51, 51, 51, 0.6);
  }

  .max-characters-indice {
    font-size: 10px;
  }

  .form-group-creation .lradio-text {
    font-size: 11px;
  }

  .form-group-creation .blc-contact-item-right .blc-contact-action .btn {
    height: 32px;
    padding: 3px 14px;
  }

  .list-contact-group-creation .blc-contact-item-right .blc-contact-action,
  .list-contact-group-creation .blc-contact-item-right .blc-contact-action .btn {
    min-width: 43px;
  }

  .form-group-creation .contactActions {
    padding: 0;
  }

  .form-group-creation .required-field-notif {
    margin: 25px 0;
  }

  .form-group-creation .formAction.put-inline {
    flex-flow: row;
  }

  .form-group-creation .btn-validate,
  .form-group-creation .btn-cancel {
    width: 100%;
  }

  .form-group-creation .formAction-left {
    margin-right: 15px;
  }

  .form-group-creation .formAction-left,
  .form-group-creation .formAction-right {
    flex: 1 1;
  }

  .form-group-creation .seeMore-contacts {
    margin: 15px 0;
  }

  .form-group-creation .blc-contact-action .dropdown .dropbtn {
    margin: 0 0 0 4px;
  }

  .form-group-creation .blc-contact-action .dropdown .dropbtn+span {
    display: none;
  }

  /* -------group detail - group view ------- */
  .group-view-actu .blc-group-action i+span {
    display: none;
  }

  .group-view-actu .blc-group-action button {
    width: auto;
  }

  .group-view-actu .blc-group-filter {
    margin-top: 15px;
    margin-bottom: 0;
    flex-flow: nowrap;
  }

  .group-view-actu .blc-group-filter .blc-group-search-bar {
    min-width: 40px;
    margin-left: 12px;
  }

  .group-view-actu .blc-group-filter .blc-group-action,
  .group-view-actu .blc-group-filter .blc-group-search-bar .search-form,
  .group-view-actu .blc-group-filter .blc-select-container,
  .group-list .blc-group-filter .blc-group-action,
  .group-list .blc-group-filter .blc-select-container,
  .group-list .blc-group-filter .blc-group-search-bar .search-form {
    height: 28px;
  }

  .group-view-actu .blc-group-filter .blc-group-action {
    margin: 5px 0 0 10px;
  }

  .group-view-actu .blc-group-filter .blc-group-action .btn,
  .group-list .blc-group-filter .blc-group-action .btn {
    padding: 0 13px;
  }

  .group-view-actu .blc-group-filter .blc-group-action .btn>i.lpicto-filter {
    margin: 0;
  }

  .group-view-actu .blc-group-filter .blc-group-action .btn>i.lpicto-filter:after {
    margin: 0;
    width: 15px;
    height: 11px;
    background-size: 100% 100%;
  }

  .group-view-actu .blc-select-container.lselect-secondary:after,
  .group-list .blc-select-container.lselect-secondary:after {
    top: 6px;
  }

  .group-view-actu .btn-manage-members.btn {
    font-size: 11px;
  }

  .group-view-actu .blc-group-filter .btn-send-comment .lpicto-search,
  .group-list .blc-group-filter .btn-send-comment .lpicto-search {
    right: 0px;
  }

  .group-view-actu .blc-group-filter .btn-send-comment .lpicto-search:after,
  .group-list .blc-group-filter .btn-send-comment .lpicto-search:after {
    width: 13px;
    height: 13px;
    background-size: 100%;
  }

  .group-view-actu .blc-group-filter .search-text,
  .group-list .blc-group-filter .search-text {
    width: 100%;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 0;
  }

  .group-view-actu span+.member-status-text,
  .group-view-actu .member-status-text {
    display: none;
  }

  .group-view-actu .lpicto-check-secondary:after {
    margin: 0;
    height: 16px;
    width: 16px;
  }

  .group-view-actu .sub-menu-group {
    padding: 0;
  }

  .group-view-actu .groupe-profil .groupeProfil-info h4 {
    font-size: 18px;
  }

  /* ----- group list -----*/
  .group-list.container {
    padding: 0 15px;
  }

  .group-list .btn-add-plus-text {
    font-size: 18px;
  }

  .group-list .blc-group-filter .blc-group-action .blc-new-group .btn {
    padding: 0 15px;
    font-size: 15px;
  }

  .group-list .blc-group-list-item {
    padding: 14px 0;
  }

  .group-list .blc-group-title {
    font-size: 12px;
    padding: 18px;
  }

  .group-list .blc-group-list {
    padding: 0 10px 0 18px;
  }

  .group-list .blc-group-list .blc-group-item-content .group-action {
    align-self: flex-start;
  }

  .group-list .blc-group-list .lpicto-menu:after {
    height: 14px;
    background-size: 100% 100%;
  }

  /* paramètre de compte */
  .blc-parametrage .blc-param-right .accordionForm-body {
    padding: 10px 20px;
  }

  .blc-parametrage .blc-param-right .link-param li {
    height: 28px;
  }

  .blc-parametrage .blc-param-right .link-param a {
    font-size: 13px;
  }
}

/*Début popup medium*/
.blc-dialogForm .title-annonce,
.blc-dialogForm .info-date {
  text-align: center;
}

.blc-dialogForm.publish-dialog {
  box-shadow: none;
  border-radius: 3px;
  position: relative;
  border: solid 1px #eaeaea;
  min-height: 340px;
  max-width: 600px;
  padding: 30px;
}

.publish-dialog .lselect-list {
  max-height: 280px;
  overflow: hidden auto;
}

.publish-dialog .lselect-list .blc-contact-info {
  width: calc(100% - 46px);
}

.publish-dialog .lselect-list .blc-contact-info span {
  max-width: unset;
}

.publish-dialog .popClosebtn {
  top: 13px;
  right: 12px;
  /* right: 20px; */
}

.propose-mission.blc-dialogForm.publish-dialog {
  min-height: 526px;
  max-width: 900px;
  padding: 100px 150px;
  justify-content: space-between;
}

/* .publish-dialog textarea::-webkit-scrollbar,.publish-dialog textarea::-moz-scrollbar {
    width: 15px;
    height: 15px;
}
.publish-dialog textarea::-webkit-scrollbar-thumb:vertical{
    height: 30px;
    background-color: #0A4C95;
} */

.userAvatarImage::before {
  background: url(images/user-avatar.svg);
  background-size: cover;
  color: grey;
  content: '';
  display: block;
  height: 40px;
  width: 40px;
}

.userAvatarImageSmall::before {
  background: url(images/user-avatar.svg);
  background-size: cover;
  color: grey;
  content: '';
  display: block;
  height: 30px;
  width: 30px;
}

.userAvatarImageTiny::before {
  background: url(images/user-avatar.svg);
  background-size: cover;
  color: grey;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.closeCircleBtn:hover,
.closeCircleBtn:focus {
  opacity: 0.6;
}

.downloadIcon::before {
  background: url(images/download-icon.svg);
  background-size: cover;
  color: grey;
  content: '';
  display: block;
  height: 28px;
  width: 28px;
}

.closeCircleBtn:hover,
.closeCircleBtn:focus {
  opacity: 0.6;
}

.closeCircleBtn::before {
  background: url(images/ico-close_circle-blue.svg);
  background-size: cover;
  content: '';
  display: block;
  height: 22px;
  width: 22px;
}

.closeCircleBtn:hover,
.closeCircleBtn:focus {
  opacity: 0.6;
}

.popClosebtn::before,
.removeBtn::before {
  background: url(images/ico-close-grey.svg);
  background-size: cover;
  content: '';
  display: block;
  height: 14px;
  width: 14px;
}

.removeBtn::before {
  height: 12px;
  width: 12px;
  background-size: 100%;
}

.removeBtn:hover::before {
  background: url(images/ico-close-primary.svg);
  background-size: 100%;
}

.popClosebtn:hover::before {
  background: url(images/ico-close-secondary.svg);
}

.popup-spec .popClosebtn::before {
  background: url(images/ico-close-grey.svg);
  background-size: cover;
  content: '';
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  right: -6px;
}

.css-bg1rzq-control {
  background: transparent !important;
  height: 38px;
  border: none !important;
}

.css-bg1rzq-control:hover,
.css-bg1rzq-control:focus {
  border-color: #59c1e5 !important;
}

.css-1szy77t-control {
  border: none !important;
  /* box-shadow:0 0 0 1px #59c1e5 !important; */
  box-shadow: none !important;
  border-radius: 4px;
}

.css-1szy77t-control:focus {
  border-color: #59c1e5 !important;
}

.select-pictoAdd {
  /* border:none;  */
  width: 100%;
}

.blc-select-container .css-1pcexqc-container {
  position: initial;
}

.css-1pcexqc-container:hover {
  /* padding-left:1px !important; */
  border: none;
}

/* .popup-spec .rowForm .inpForm:hover, .popup-spec .rowForm .inpForm:focus,
.popup-spec .rowForm textarea:hover, .popup-spec .rowForm textarea:focus {
    border:#59c1e5 solid 1px;
} */
/* .popup-spec .select-pictoAdd:focus, .popup-spec .select-pictoAdd:hover{
    border:#59c1e5 solid 1px !important;
} */
.publish-dialog .blc-img-profil img {
  height: 60px;
  width: 60px;
  padding: 0;
}

/* .publish-dialog .blc-pub-area {
    height: auto;
    min-height: 120px;
} */
.publish-dialog .blc-pub-area textarea.feedComposerField {
  /*height: auto;*/
  height: 120px;
  padding: 10px;
  margin-bottom: 5px;
}

.publish-dialog .lmention textarea {
  max-height: calc(100vh - 400px);
  overflow: auto !important;
}

.publish-dialog .lmention .lmention__highlighter {
  max-height: calc(100vh - 400px);
}

.comment-text .lmention textarea,
.comment-text .lmention .lmention__highlighter {
  max-height: 200px;
}

.comment-text .lmention textarea {
  overflow: auto !important;
}

.publish-dialog .blc-pub-area textarea {
  font-family: Montserrat;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.7px;
  line-height: 1.53;
  color: #333333;
  order: 0;
  /* max-height: 250px; */
  min-height: 120px;
  /* overflow: auto!important; */
}

.publish-dialog .blc-pub-area-action {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  height: auto;
  margin-bottom: 30px;
  padding-top: 19px;
  padding-bottom: 19px;
  /* min-height: 120px; */
}

.publish-dialog .feedComposerBackground {
  border: none;
  box-shadow: none;
  /*margin-bottom: 14px;
    margin-top: 14px;
    padding-left: 14px;
    padding-right: 14px;*/
  margin-top: 0;
  height: auto;
}

.publish-dialog .publish-top {
  align-items: flex-start;
  display: flex;
  position: relative;
  flex: 0 0 auto;
  /* flex:0 0 600px; */
}

.publish-dialog .blc-img-profil {
  margin: inherit;
  /*padding: 10px 0;*/
  padding: 0;
  flex: 0 0 60px;
}

.feedComposerBackground .feedComposerField {
  height: auto;
  min-height: 120px;
  display: inline-block;
}

.feedComposerBackground .feedComposerField span {
  font-size: 16px;
  background-color: #fff !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #333333;
}

.publish-dialog .blc-pub-area {
  /*width: calc(100% - 64px);*/
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
}

.publish-dialog .first-area-container {
  display: flex;
  flex-direction: row;
}

.publish-dialog .second-area-container {
  width: calc(100% - 60px);
  padding: 5px 5px 5px 25px;
}

.publish-dialog .blc-pub-area .feedPicture {
  width: auto;
  height: 350px;
  max-height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #eee;
}

.publish-dialog .blc-pub-area .feedPicture img {
  max-height: 100%;
  width: auto;
  margin: 0 auto;
}

.blc-pub-area .video-react.video-react-fluid {
  max-height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #eee;
}

/* .video-react.video-react-fluid video{
    max-height:250px;
} */
.lvideo {
  max-height: calc(100vh - 200px);
  overflow: hidden;
  position: relative;
}

.lvideo-small {
  max-height: 320px;
  overflow: hidden;
  position: relative;
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.blc-pub-area .video-react.video-react-fluid+.login-quit.i-close {
  position: absolute;
  left: 89%;
  top: calc(100% - 242px) !important;
}

.publish-dialog .blc-pub-area .error-msg {
  margin: 0;
  padding: 10px 0 20px;
  border-top: 1px #fee solid;
}

.blc-pub-area .login-quit.i-close {
  position: absolute;
  left: 89%;
  z-index: 10;
  right: 16px;
  top: 18px;
}

.login-quit.i-close {
  display: block;
  width: 30px;
  height: 30px;
  background: #6b696c;
  border-radius: 4px;
  right: 16px;
  top: 12px;
}

.login-quit.i-close:hover {
  background: #777;
}

.publish-dialog .postFeedButton {
  border-radius: 60px;
  color: #fff;
  padding: 5px 20px;
  width: 120px;
}

.publish-dialog .publish-member {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.blc-select-container.chevron-down:before {
  content: '';
  display: block;
  position: absolute;
  background: url(images/public-dark-purple.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  border: none;
  top: 9px;
  background-size: 10px;
  left: 10px;
  border: none;
}

.blc-select-container.chevron-down:after {
  content: '';
  pointer-events: none;
  display: block;
  position: absolute;
  background: url(images/chevron-down.svg) no-repeat center center;
  width: 10px;
  height: 10px;
  border: none;
  top: 9px;
  background-size: 10px;
  right: 17px;
  border: none;
}

.blc-select-container.chevron-down .categorySelect {
  background: #fff;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 700;
  padding-left: 25px;
}

select.categorySelect {
  /*for chevron after : .blc-select-container.chevron-down:after*/
  /*z-index: 2;*/
}

.txt-small {
  color: rgba(51, 51, 51, 0.6);
  font-family: Montserrat;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

.publish-dialog .btn {
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 4px;
}

.publish-dialog .postButtons {
  justify-content: space-between;
}

.publish-dialog .blc-pub-area-action .btn {
  /*width: 120px*/
}

.publish-dialog .blc-pub-action-left .btn {
  /*width: 120px*/
  padding-left: 0;
  padding-right: 30px;
}

.publish-dialog .blc-pub-action-right {
  width: auto;
  justify-self: flex-end;
}

/*Fin popup medium*/
/* Début popup partage */
.css-bgvzuu-indicatorSeparator {
  background-color: rgba(0, 0, 0, 0) !important;
}

.publish-dialog .popTitle-ctnr {
  margin: -31px -31px 0 -30px;
  padding: 16px 30px;
  background: #f9f9f9;
  position: relative;
  /* z-index:0; */
  height: 55px;
}

.publish-dialog .lselect-one {
  font-weight: 700;
  position: relative;
}

/* .lselect-one::after{
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    right: 17px;
    background-size: 90% !important;
    opacity: .6;
    top: 13px;
    display: block;
    background:url(./images/chevron-down.svg) no-repeat center;
}  */
.css-dvua67-singleValue {
  /* position: relative !important; */
  /* margin-right: 20px !important; */
  padding-right: 30px !important;
  overflow: visible !important;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: rgba(51, 51, 51, 0.6) !important;
}

/* .css-dvua67-singleValue::before,
.css-dvua67-singleValue::after {
    content: "";
    width: 2px;
    height: 8px;
    position: absolute;
    background-size: 60% !important;
    opacity: .6;
    top: 15px;
    display: block;
    background: #999999; */
/* background:url(./images/chevron-down.svg) no-repeat center !important; */
/* }

.css-dvua67-singleValue::before {
    transform: rotate(45deg);
    right: 0;
}

.css-1szy77t-control .css-dvua67-singleValue::before {
    transform: rotate(-45deg);
}

.css-dvua67-singleValue::after {
    transform: rotate(-45deg);
    right: 5px;
}

.css-1szy77t-control .css-dvua67-singleValue::after {
    transform: rotate(45deg);
} */

/* .css-1g6gooi div{
    display: none;
} */
.lselect-one .css-1hwfws3:after {
  content: '';
  display: block;
  background: url(./images/chevron-down.svg) no-repeat center;
  background-size: contain;
  height: 14px;
  position: absolute;
  opacity: 0.6;
  right: 10px;
  width: 14px;
}

.lselect-open .css-1hwfws3:after {
  transform: rotate(180deg);
}

.blc-newprod-content .css-1g6gooi div,
.select-custom .css-1g6gooi div {
  display: block;
}

.popTitle-ctnr .ico-caret-down {
  /* left: 0; */
}

/* .publish-dialog .lselect-one span{
    position: absolute;
    top: 6px
} */
.popTitle-ctnr .css-1thkkgx-indicatorContainer,
.popTitle-ctnr .css-1wy0on6 {
  display: none !important;
}

/*
.publish-dialog .css-1pcexqc-container.lselect-one::after {
    background: none !important;
} */

.publish-dialog .lselect-one>div {
  background: none;
  border: none;
  min-height: inherit;
  z-index: 100;
}

.publish-dialog .lselect-one .css-1hwfws3 {
  padding: 0;
}

.publish-dialog .css-151xaom-placeholder {
  font-family: Montserrat;
  font-size: 1.6rem;
  font-weight: bold;
}

.publish-dialog .popContent {
  padding: 0 9px;
}

.publish-dialog .popContent+.blc-pub-area-action {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.publish-dialog .new-padding {
  margin-left: 9px;
  margin-right: 9px;
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - 18px);
}

.blc-dialogForm .feedComposerBackground .blc-pub-area-action,
.blc-dialogForm .feedComposerBackground .blc-pub-area {
  cursor: auto;
}

.blc-dialogForm .feedComposerBackground .blc-pub-area-action:hover {
  background: none;
}

.feedComposerBackground .blc-pub-area-action {
  cursor: pointer;
}

.feedComposerBackground .blc-pub-area-action:hover {
  background: rgba(150, 250, 250, 0.2);
}

.feedContainer .feedComposerBackground .blc-pub-area-action span {
  max-width: 100%;
  display: inline-block;
  padding-top: 3px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.publish-dialog .popContent .ltextarea {
  color: rgba(51, 51, 51, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  margin-top: 15px;
  position: relative;
  z-index: 7;
}

.publish-dialog .popContent .ltextarea:focus {
  color: rgba(51, 51, 51, 0.8);
}

.publish-dialog .popContent .ltextarea:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.publish-dialog .post-ToShare {
  border-radius: 3px;
  border: none;
  width: 100%;
}

.publish-dialog .publish-reset,
.popUpSignal .publish-reset {
  border: solid 2px rgba(51, 51, 51, 0.3);
  padding-bottom: 6px;
  padding-top: 6px;
}

.publish-video {
  position: relative;
}

.publish-video .blc-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* .publish-video .blc-loader .loader-ctnr {
    background-color: rgba(0,0,0,0.4);
} */
/* Fin popup partage */

/*Tchat*/
#root.with_header .blc-chat-content {
  margin-top: 20px;
}

.chat-new-container {
  background: transparent;
  align-items: flex-start;
  height: calc(100vh - 120px);
}

.chat-new-container .left-chat {
  /* border: solid 1px #eaeaea;
    border-right: none; */
  width: 313px;
  /* height: calc(100% - 98px); */

  flex: 0;
  /* height: 100vh; */
}

.custom-header-left {
  background: #fff !important;
  box-shadow: none;
}

.txt-messager {
  color: rgba(51, 51, 51, 0.6);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.btn-new-chat {
  color: #59c1e5;
  font-size: 11px;
  font-weight: 700;
  margin-right: 12px;
}

.lbtn-chat.btn-new-chat {
  padding: 0;
  margin-right: 0;
}

.btn-new-chat:before {
  background: url(images/chevron-right-gray.svg) no-repeat;
  background-size: cover;
  content: '';
  display: inline-block;
  height: 8px;
  margin-right: 5px;
  width: 8px;
}

.chat-sidebar .chat-search,
.custom-header-left .chat-search {
  border: solid 1px rgba(51, 51, 51, 0.3);
  border-radius: 20px;
  height: 40px;
}

.custom-header-left .chat-search input {
  color: #333333;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 9px;
  padding-top: 9px;
}

.custom-header-left .chat-search input::-moz-placeholder,
.blc-pub-area textarea.feedComposerField::-moz-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.custom-header-left .chat-search input::-webkit-input-placeholder,
.blc-pub-area textarea.feedComposerField::-webkit-input-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.custom-header-left .chat-search input:-ms-input-placeholder,
.blc-pub-area textarea.feedComposerField:-ms-input-placeholder {
  color: #333333 !important;
  opacity: 1;
}

.custom-header-left .chat-search input::placeholder,
.blc-pub-area textarea.feedComposerField::placeholder {
  color: #333333 !important;
  opacity: 1;
}

.custom-header-left .chat-search .lpicto-search-primary {
  background: url(./images/ico-search-black.svg) no-repeat;
  background-size: cover;
}

.chat-item.highlight {
  background: #f8f1fd !important;
}

.chat-item {
  padding: 15px 2px 15px 16px !important;
}

body .chat-item.highlight .chatItem-text,
body .chat-item.highlight .chatItem-about,
body .chat-item.highlight .chatItem-header span,
body .chat-item.highlight .chatItem-header p,
body .chat-item.highlight .ico-menu {
  color: #333 !important;
}

/*tchat center*/
.header-menuChat {
  display: none;
}

.sidetchat-rb {
  /* height: calc(100% - 560px); */
  /*height: 220px;*/
  max-height: 268px;
  flex: 0 0 auto;
  padding-top: 0;
}

.sidetchat-rb span.MuiIconButton-root-57 {
  padding: 6px 12px 6px 6px;
}

.sidetchat-rb fieldset.noicon {
  margin-bottom: 0;
}

.chat-WrapHeader .chat-search {
  height: 74.5px;
}

.my-list-chat {
  padding: 0 21px 21px 21px;
  /*max-height: calc(100vh - 590px);*/
  /*height: calc(100vh - 590px);*/
  min-height: 125px;
  overflow: auto;
}

.chatInfo-user .chat-avatar {
  width: 100%;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  margin-top: -40px;
}

.chat-avatar.chat-small-avatar {
  width: auto !important;
  border: none;
  padding: 0;
  margin-top: 0;
}

.chatInfo-user .chat-avatar .avatar-right {
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.chatInfo-user .chat-avatar span {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.chatInfo-user .chat-avatar span.user-name {
  margin-top: 10px;
}

.chatInfo-user .chat-avatar span.user-poste {
  width: 75%;
}

.chatInfo-user button {
  position: relative;
  z-index: 1000;
}

.chat-container .uploads-btn-container {
  display: flex;
  align-items: center;
}

.chat-container .btn-send-message-container {
  /* padding-right: 0; */
  position: relative;
  right: -12px;
}

.chat-container .blc-chat .chat-new {
  padding: 0 20px 0 0;
}

.chat-container .blc-chat .chat-new .lbtn-chat {
  background-color: transparent;
}

.chat-container .blc-chat .chat-new .btn-goback {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

/* .chatInfo-user .chat-avatar{
    margin-top: -40px;
} */
.chat-info {
  width: 285px;
  flex: 0 1 auto !important;
  z-index: 10;
}

.chat-info .MuiRadio-colorSecondary-232.MuiRadio-checked-229 {
  color: #59c1e5;
}

.new-msg {
  height: 81px;
  line-height: 81px;
  padding: 0 0 0 69px;
  position: relative;
}

.new-msg::before {
  content: '';
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #eaeaea url(./images/ico-relations-grey.svg) no-repeat center center;
  position: absolute;
  left: 15px;
  top: 20px;
}

.chat-container {
  max-height: 763px;
}

main.tchat-center {
  border: 1px solid #eaeaea;
  border-left: none;
  /* height: calc(100% - 98px); */
  height: 100%;
  /* max-width: calc(100% - 610px); */
  flex: 1;
}

main.tchat-center.full-width {
  max-width: calc(100% - 325px);
}

main.tchat-center.full-width+.tchat-right {
  display: none;
}

.chat-sidebar-overlay {
  overflow: auto;
}

.tchat-right {
  border: 1px solid #eaeaea;
  border-left: none;
  padding: 20px;
  width: 285px;
}

.chat-wrap [class*='Connect-Chat--messageRow-'].contact,
.chat-wrap [class*='Connect-Chat--messageRow-'].me {
  margin-bottom: 15px;
}

.chat-wrap [class*='Connect-Chat--messageRow-'].contact div+[class*='Connect-Chat--bubble-'] {
  background: rgba(160, 62, 230, 0.07) !important;
  border-radius: 20px 20px 20px 0 !important;
}

.chat-wrap [class*='Connect-Chat--messageRow-'].contact div+[class*='Connect-Chat--bubble-'] [class*='Connect-Chat--message-'] {
  color: #333;
  font-size: 11px;
  font-weight: 500;
  word-break: break-all;
}

.chat-wrap [class*='Connect-Chat--messageRow-'].me [class*='Connect-Chat--bubble-'] {
  background-image: linear-gradient(258deg, #af42f0, #1f379a);
  border-radius: 20px 20px 0 20px !important;
}

.chat-wrap [class*='Connect-Chat--messageRow-'].me [class*='Connect-Chat--bubble-'] [class*='Connect-Chat--message-'] {
  color: #fff;
  word-break: break-all;
}

.custom-bg-white,
.custom-bg-white .chat-WrapHeader {
  background: #fff !important;
}

.custom-bg-white .chat-WrapHeader {
  border-bottom: solid 1px #eaeaea;
  box-shadow: none;
}

.txt-user {
  color: #333;
}

.status-user {
  display: block;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}

.status-user.connected {
  color: #59c1e5;
}

.container-input-message {
  margin-left: 35px;
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - 70px);
}

.container-input-message .chat-textarea input {
  border: none;
  /*color: rgba(51, 51, 51, 0.6);  */
  color: #333;
  font-size: 12px;
  font-weight: 500;
  padding-left: 0;
}

.btn-attach-file {
  background: url(./images/attachement-fichier.svg) no-repeat 0 center;
}

.btn-send-message {
  display: inline-block;
  width: 110px !important;
  height: 40px !important;
  border-radius: 20px;
  background-image: linear-gradient(219deg, #af42f0, #1f379a);
  color: #fff !important;
  font-size: 13px !important;
  line-height: 38px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
}

.btn-send-message:hover,
.btn-attach-file:hover {
  opacity: 0.9;
}

.btn-send-message:before {
  /* color: #fff;
    content: "";
    display: inline-block;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 700;
    width: 100%; */
}

.btn-send-message.clr-grey {
  background: #ccc;
}

.tchat-center .MuiIconButton-root-57:hover {
  background: none !important;
}

/*Fin tchat center*/
/*Début tchat right*/
.user-statut {
  height: 20px;
}

.new-right-container {
  left: inherit !important;
  width: 285px !important;
}

.new-right-container .noshadow {
  border-left: 1px solid #eaeaea;
}

.custom-label-statut {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
  /* margin-top: 29px; */
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.lradio-block {
  width: 100% !important;
  justify-content: flex-start !important;
}

.lradio-block input[type='radio']:checked+.lradio-mark+.lradio-text {
  color: #59c1e5;
}

.lradio-block .statut-role input[type='radio']:checked+.lradio-mark+.lradio-text {
  color: #333;
}

.lradio-block .lradio-img {
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  margin-right: 10px;
  object-fit: contain;
  vertical-align: middle;
  width: 40px;
}

.statut-container .flex-direction-mobile {
  flex-wrap: wrap;
}

.statut-container .lradio-block {
  justify-content: flex-start !important;
  width: 100%;
}

/* ANNONCE PAGE */
/* .blc-annonce-item .annonce-body .annonce-about{
    margin-left: -20px;
    margin-right: -20px;
} */
.showLabel {
  min-height: 40px;
  border: rgba(51, 51, 51, 0.3) solid 1px;
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 12px;
}

.annonce-page {
  background-image: linear-gradient(to bottom,
      rgba(250, 244, 254, 1),
      #f9f9f9) !important;
  width: 100% !important;
}

.annonce-item h2.title-annonce {
  text-overflow: ellipsis;
  /* overflow-wrap: break-word; */
  line-height: 22px;
}

.annonce-item .text-annonce p {
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  line-height: 18px;
}

.blc-annonce-search h2 {
  margin-bottom: 40px;
}

.blc-annonce-search .search-form {
  margin-bottom: 30px;
}

.blc-annonce-category {
  width: 192px;
}

.blc-annonce-search .categorySelect {
  width: 100%;
  margin: 0;
}

.blc-annonce-search .blc-select-container {
  width: auto;
  border: 1px solid;
}

.blc-annonce-search .search {
  padding-bottom: 0;
}

.blc-annonce-search .blc-select-container {
  height: 40px;
}

/* .annonce-page{
    height: 100vh;
} */
.wrapper-annonce {
  padding-bottom: 30px;
}

.wrapper-annonce .blc-content-tab .blc-tab-title ul>li {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.wrapper-annonce .blc-content-tab .blc-tab-title ul>li {
  position: relative;
}

.wrapper-annonce .container {
  display: flex;
}

.wrapper-annonce .wall-right {
  margin: 0 0 0 30px;
}

.blc-validate-annonce-ctnr {
  padding: 20px 37.5px;
}

.title-new-annonce {
  margin-bottom: 15px;
}

.blc-annonce-filter .blc-select-container {
  width: 222px;
  border: 1px solid;
}

.wrapper-annonce .tab-standard-body>ul,
.search-wrap .tab-standard-body>ul.list-annonce {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.search-wrap .tab-standard-body>ul.list-annonce {
  padding-top: 15px;
}

.wrapper-annonce .tab-standard-body>ul>li,
.search-wrap .tab-standard-body>ul.list-annonce>li {
  width: calc(50% - 15px);
  align-self: stretch;
  margin-bottom: 30px;
}

.annonce-header {
  height: 120px;
  display: block;
  overflow: hidden;
}

.img-annonce {
  display: block;
  height: 100%;
}

.wrapper-annonce .blc-annonce-center {
  width: calc(100% - 315px);
}

.img-annonce img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.annonce-body .info-date {
  margin-bottom: 20px;
}

.annonce-body .annonce-center {
  /* border-bottom: 1px solid #eaeaea; */
}

.annonce-body .info-annonce {
  /* border-top: 1px solid #eaeaea; */
  margin-top: auto;
}

.annonce-body .info-author {
  flex: 1 1 50%;
  padding-right: 4px;
  margin-top: 0;
}

.annonce-tags {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin: 20px 0;
}

.annonce-tags span {
  margin-right: 5px;
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-fin-annonce span {
  align-self: center;
}

.expiredAnnonce {
  display: flex;
  padding-left: 10px;
  line-height: 17px;
}

.expiredAnnonce p {
  font-weight: 400;
  color: rgba(51, 51, 51, 0.6);
}

.annonce-body .lpicto-date::after {
  background: url(./images/ico-date-annonce.svg) no-repeat 0 center;
  height: 22px;
  width: 22px;
}

.localtarif-annonce {
  margin: 20px 0;
  height: 64px;
  border-top: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  position: relative;
}

.localtarif-annonce::before {
  position: absolute;
  width: 2px;
  height: 64px;
  content: '';
  background-color: #eaeaea;
}

.localtarif-annonce span {
  flex: 0 0 50%;
  text-align: center;
}

.localtarif-annonce span.lpicto-loc {
  width: 30px;
  flex: 0;
}

.town-loc {
  flex: 1;
  margin-left: 10px;
  text-align: left !important;
}

.annonce-body .annonce-location {
  width: auto;
  justify-content: center;
}

/* ANNONCE FORM */
.annonce-form .container {
  position: relative;
  top: -83px;
}

.header-annonce {
  height: 328px;
  background: url(./images/bg-header-annonce-form.png) no-repeat top center,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), #f9f9f9) !important;
  background-size: 100%;
  margin-bottom: 0 !important;
}

.soscienceImg {
  width: 95px;
  height: 20px;
  margin-left: 6px;
}

.annonce-form .blc-content-tab .blc-tab-title ul>li {
  width: 100%;
  position: relative;
  color: rgba(51, 51, 51, 0.6);
}

.annonce-form .blc-content-tab .blc-tab-title ul>li .steps {
  letter-spacing: 2.8px;
  text-transform: uppercase;
  font-weight: 700;
}

.annonce-form .blc-content-tab .blc-tab-title ul>li.active .steps {
  color: #59c1e5;
  font-weight: 700;
  font-size: 12px;
}

.annonce-form .blc-content-tab .blc-tab-title ul>li .step-title {
  color: rgba(51, 51, 51, 0.6);
  font-weight: 700;
  font-size: 19px;
  letter-spacing: -0.7px;
}

.annonce-form .blc-content-tab .blc-tab-title ul>li.active .step-title {
  color: #333333;
}

.annonce-form .blc-content-tab .blc-tab-title ul>li.active:after {
  background: linear-gradient(to right, #1578fb, #17ead7);
}

.blc-dashboard-center .blc-content-tab .blc-tab-title ul>li.active:after {
  background: linear-gradient(to right, #1578fb, #17ead7);
}

.blc-content-tab .blc-tab-title ul>li.unactive:after {
  background: #e4e4e4;
}

.blc-dashboard-center .blc-content-tab .blc-tab-title ul>li {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.blc-dashboard-center .blc-content-tab .blc-tab-title ul>li:nth-child(2):after {
  margin-left: 50%;
}

.blc-dashboard-center .blc-content-tab .blc-tab-title ul>li.active {
  color: black;
}

.blc-dashboard-center .blc-content-tab .blc-tab-title ul>li.unactive {
  color: #828282;
}

/* ANNONCE DETAIL */
.detailAnnonce-footer {
  align-items: flex-start;
  display: flex;
  font-weight: 200;
  /* justify-content: flex-end; */
  justify-content: space-around;
  padding-bottom: 65px;
  padding-top: 65px;
}

.detailAnnonce-footer div {
  margin: 0 20px;
}

.detailAnnonce-footer .blc-share-menu-group {
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 0;
  width: 156px;
  padding: 20px 0;
  /* position: absolute;
    right: 0; */
}

.blc-share-menu-group .put-inline {
  height: 40px;
}

.blc-share-menu-group button.lpicto-share-primary {
  margin-right: 0;
}

.blc-share-menu-group .put-inline button:nth-of-type(1) {
  padding-left: 0;
  padding-right: 0;
}

.detailAnnonce-footer .blc-share-menu-group button {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.detailAnnonce-footer .blc-share-menu-group .btn-annonce {
  flex: 1 0 100%;
  margin: 0;
  width: 100%;
}

.interet-txt {
  line-height: 1.22;
  padding-right: 96px;
}

.detailAnnonce-footer .interet-txt {
  padding-right: 0;
}

.date-publicate {
  width: 20%;
  flex: 0 0 auto;
}

.tags-wrapper-annonce .date-publicate {
  /* width: 30%; */
  width: auto;
  white-space: nowrap;
  text-align: right;
}

.date-publicate span {
  font-weight: 300;
}

.detailAnnonce .info-date {
  align-items: center;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}

.detailAnnonce .info-date span {
  /* margin-right: 5px; */
  /* margin-left: 5px; */
}

.detailAnnonce .lpicto-date:after {
  background: url(./images/calendar.svg) no-repeat;
  background-size: 100%;
  margin-right: 10px;
}

.detailAnnonce .bloc-annonce-body {
  width: calc(100% - 315px);
  height: 100%;
}

.date-publicate {
  margin-left: auto;
  padding-bottom: 10px;
}

.titleAndPrice {
  display: flex;
  align-items: center;
}

.titleAndPrice>h2 {
  width: 65%;
}

.titleAndPrice section {
  text-align: center;
}

.detailAnnonce .annonce-body {
  margin: 0;
  padding-right: 105px;
  padding-left: 105px;
  top: 0;
  width: 100%;
  max-height: 100%;
}

.detailAnnonce .container {
  display: flex;
  position: relative;
  top: -76px;
}

.detailAnnonce .wall-right {
  margin: 0 0 0 30px;
}

.detailAnnonce .title-annonce {
  line-height: 36px;
  margin-bottom: 16px;
  padding-top: 17px;
  padding-right: 10px;
}

.detailAnnonce .annonce-type {
  border-radius: 3px;
  padding: 0 18px;
}

.detailAnnonce .bloc-annonce-body .img-author .user-profil {
  width: 50px;
  height: 50px;
}

.infos-wrapper-annonce .desc-annonce h2 {
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.infos-wrapper-annonce {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.infos-wrapper-annonce .interet-txt {
  padding-right: 26px;
}

.infos-wrapper-annonce .blc-share-menu-group a {
  padding: 0 18px;
  margin: 0 20px 10px;
  text-align: center;
  line-height: 1.15;
}

.detailAnnonce .taglist-annonce {
  margin: 10px 0;
  padding: 0;
  /* flex: 0 0 80%; */
}

.detailAnnonce .tag-list ul {
  flex-flow: row wrap;
}

.detailAnnonce .tag-usr {
  margin: 5px 5px 5px 0;
}

.tags-wrapper-annonce {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
}

.detailAnnonce .tags-wrapper-annonce {
  padding-bottom: 40px;
}

.detailAnnonce .accordion {
  border-radius: 3px;
  padding: 20px 105px;
}

.detailAnnonce .blc-contents-accordion {
  line-height: 1.71;
  padding: 0 0 28px 0;
}

.detailAnnonce .blc-contents-accordion p {
  font-size: 12px;
  line-height: 2;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.detailAnnonce .blc-contents-accordion .p-relation {
  margin: 0;
}

.detailAnnonce .blc-contents-accordion .p-relation .blc-contact-relation {
  padding: 0;
}

/* .profil-items-ctnr .accordion .blc-contents-accordion .compt-icon-add button,
.blc-compt-item .btn-profil-compt.compt-icon-add button{
    z-index: 1 ;
} */
.detailAnnonce .blc-title-accordion {
  border: none;
  height: 90px;
}

.detailAnnonce.blc-title-accordion span {
  font-size: 12px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
}

.blc-share-menu-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.detailAnnonce .info-annonce {
  background-image: linear-gradient(to top, #182da8, #a007a0);
  border-radius: 3px;
  /* flex: 1 0 30%; */
  height: 100px;
  padding: 3px;
  min-width: 120px;
}

.detailAnnonce .annnonce-price {
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  font-weight: 300;
  justify-content: center;
  padding: 1.9rem 15px;
  text-align: center;
}

.detailAnnonce .desc-annonce {
  flex: 0 1 60%;
}

.detailAnnonce .blc-annonce-item .desc-annonce {
  flex: 0 1 100%;
  max-width: 100%;
}

.detailAnnonce .desc-annonce .annonce-ref {
  justify-content: flex-start;
}

.blc-smallbr-item .brevet-img {
  height: 152px;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.blc-smallbr-item .label-img-upl {
  width: 100%;
  /* height: auto; */
  height: 100%;
  object-fit: cover;
}

.blc-smallbr-item .brevet-description {
  /* height: 120px; */
  height: 80px;
}

.blc-smallbr-item .number-profil {
  left: 0;
}

.detailAnnonce .annonce-location {
  height: 30px;
}

.detailAnnonce .lpicto-loc:after {
  background-size: 100%;
  margin-right: 10px;
}

.annonce-page .lpicto-loc:after {
  background-position: center center;
}

.blc-annonce-right {
  width: 285px;
}

.blc-annonce-right .wall-right {
  height: 100%;
}

.detailBrevet.blc-brevet {
  margin-top: 93px;
}

.brevet-img {
  margin-left: -1px;
  margin-right: -1px;
  max-height: 500px;
  overflow: hidden;
}

.brevet-img img {
  object-fit: cover;
}

.detailBrevet .header-brevet .lpicto-favoris {
  /* position: relative; */
}

.detailBrevet .header-brevet .lpicto-favoris:after {
  height: 24px;
  width: 27px;
}

.detailBrevet .wall-right {
  height: auto;
}

.detailBrevet {}

/* page recherche */
.search-wrap .blc-contact-item-right .btn {
  margin-right: 15px;
}

.search-wrap .lpicto-menu:after {
  top: 0;
  left: 24px;
}

.labo-members .search-wrap .blc-contact-relation .btn {
  padding: 0 0;
}

.search-wrap .blc-contact-relation .blc-contact-item {
  border: none;
}

.search-wrap .blc-contact-item-left span {
  /* margin: 2px 0; */
  margin: 2px 5px;
}

.search-wrap .blc-contact-item-left,
.favoris-page .blc-contact-item-left {
  flex: 0 0 52%;
}

.search-wrap .blc-contact-relation .blc-contact-item-right,
.fullItem-search-result .blc-contact-relation .blc-contact-item-right {
  /* max-width: 210px; */
  max-width: 164px;
  padding: 0;
  flex: 0 0 48%;
  justify-content: flex-start;
}

.search-wrap .blc-contact-relation .blc-contact-item-right .btn-container {
  margin-right: auto;
}

.search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect .lpicto-share-secondary {
  display: flex;
  align-items: center;
  height: 10px;
}

.search-wrap .blc-contact-relation .blc-contact-item-right .btn-container .btn-connect .lpicto-share-secondary::before {
  height: 16px;
  width: 15px;
  background-size: auto 100%;
  margin: 0 10px 0 0;
}

.search-wrap .blc-contact-item-right,
.favoris-page .blc-contact-item-right {
  flex: 0 0 48%;
  justify-content: flex-end;
}

.search-wrap .search-content {
  margin: 20px 0;
  width: calc(100% - 315px);
}

.search-wrap .blc-content-tab {
  border: solid 1px #eaeaea;
  border-radius: 3px;
}

.search-wrap .tab-standard-header .blc-tab-title,
.search-wrap .tab-standard-body {
  margin-top: 0;
  max-width: 910px;
  width: 100%;
}

.search-wrap .tab-standard-body>ul {
  background: #fff;
  border: solid 1px #eaeaea;
  border-radius: 3px;
  border-top: none;
}

.search-wrap .clr-brown-grey a {
  color: rgba(51, 51, 51, 0.4) !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.search-wrap .clr-brown-grey.active {
  border-bottom: 3px solid;
  border-image-source: linear-gradient(267deg, #af42f0, #1f379a);
  border-image-slice: 1;
  position: relative;
}

.search-wrap .search .blc-param-title {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 3px 3px 0 0;
  color: #333 !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 25px 30px;
}

.search-wrap .search .blc-param-title+.blc-param-form+.blc-param-title {
  margin-top: 20px;
}

.search-wrap .search .blc-param-form {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-top-color: transparent;
}

.search-wrap .search .blc-param-form .bloc-param-action {
  margin-bottom: 20px;
  margin-top: 0;
}

/* .search-wrap .blc-contact-relation .blc-contact-item{
    border:none;
} */
.search-wrap .search .blc-contact-item,
.search-wrap .search .blc-group-list-item,
.search-wrap .search .centered>li {
  box-shadow: none;
  margin: auto;
  width: 92%;
}

.search-wrap .search .profil-list .blc-contact-item {
  width: 95%;
  max-height: 55px;
  padding: 5px 10px;
  margin: 0 auto 0 10px;
}

.search-wrap .search .profil-list .blc-contact-item:first-child {
  padding-top: 25px;
}

.search-wrap .blc-contact-item .blc-contact-img {
  border: none;
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
}

.search-wrap .blc-contact-item .blc-contact-img img,
.list-corporation-item .img-corporation-container,
.list-corporation-item .img-corporation-container img.img-corporation {
  height: 50px;
  width: 50px;
}

.list-corporation-item .img-corporation-container {
  flex: none;
  margin: 0;
}

.search-wrap .blc-contact-info {
  display: flex;
  flex-direction: column;
}

.search-wrap .blc-contact-info .clr-dark-green {
  color: #333 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
}

.search-wrap .btn-connect,
.favoris-page .btn-connect,
.interested-list .btn-connect,
.search-result .btn-connect {
  background-image: linear-gradient(74deg, #1578fb, #17ead7);
  border-radius: 20px;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
  width: 122px;
}

.Search-itemList .search-result .btn-connect.corporation-info {
  background-color: #f1ffff;
  background-image: none;
  color: #59daef;
  min-width: 122px;
  cursor: default;
  font-weight: 400;
}

.search-result .btn-connect {
  max-height: 35px;
}

.search-wrap .search .blc-param-form .bloc-param-action button {
  border: none;
  color: #333333 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.search-wrap .search .blc-param-form .bloc-param-action button:before {
  content: '';
  width: 14px;
  height: 14px;
  position: relative;
  background: url(./images/chevron-down.svg) no-repeat center center;
  background-size: 76%;
  top: 3px;
  display: inline-block;
  margin-right: 5px;
}

.search-wrap .search .blc-param-form .st-content-tab {
  border: none !important;
}

.search-wrap .search-right {
  margin: 20px 0 20px 30px;
}

/* RELATION */
.contactPage .more-btn {
  display: none;
}

.p-relation {
  padding-left: 0;
  padding-right: 0;
  /*margin-top: 200px;*/
  margin-top: 90px;
}

.p-relation.layout.wall.container {
  width: calc(100% - 40px);
}

.p-relation .wall-center {
  width: calc(100% - 315px);
  position: relative;
  padding-bottom: 30 px;
}

.p-relation .wall-right,
.group-list .wall-right {
  margin: 30px 0 0 30px;
}

.nbRelationContainer {
  margin: 0;
  font-size: 26px;
  position: absolute;
  display: block;
  top: -30px;
}

.p-relation .contactActions {
  padding-bottom: 10px;
}

.p-relation .blc-select-container {
  min-width: 156px;
}

.blc-contact-relation {
  padding: 0 26.5px 10px 36.5px;
}

.p-relation .blc-contact-relation {
  padding-bottom: 0;
}

.blc-contact-relation .blc-contact-item {
  height: 94px;
  border-bottom: 1px solid #eaeaea;
  padding: 0;
  padding: 0 0 0 16px;
}

.interested-list .blc-contact-relation .blc-contact-item {
  padding: 0;
}

.blc-contact-relation .blc-contact-item:nth-last-of-type() {
  border: none;
}

.p-relation .blc-contact-relation .blc-contact-item {
  background-color: transparent;
}

.p-relation .blc-contact-relation:hover {
  background-color: #f3fbfd;
}

.p-relation .blc-contact-img {
  border-radius: 50%;
  display: block;
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
}

.p-relation .blc-contact-img img {
  width: 50px;
  height: 50px;
}

.p-relation .blc-contact-info {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.p-relation .blc-contact-info span {
  margin: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-relation .blc-contact-info span span {
  padding-left: 5px;
}

.p-relation .blc-contact-item-right {
  width: 90px;
}

.p-relation .blc-contact-item-right .btn {
  margin: 0;
  padding: 0 9px;
}

.p-relation .blc-contact-item-right .ldropdown.toggleDropDown {
  margin-left: 15px;
}

.p-relation .blc-contact-item-right .btn.toggleDropDown {
  width: 25px;
  height: 22px;
}

/* Détail Brevet */

.detailBrevet .wall-center {
  width: calc(100% - 315px);
}

.detailBrevet .wall-right {
  margin: 0 0 0 30px;
}

.detailBrevet .btn-share-brevet button {
  margin: 0 10px;
  flex: 0 0 auto;
  width: auto;
}

.detailBrevet .header-brevet .brevet-head {
  margin: 0 31px;
  border: none;
  padding-right: 0;
  padding-left: 0;
  padding: 30px 0;
  height: auto;
}

.detailBrevet .header-brevet .brevet-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.detailBrevet .header-brevet .brevet-ref {
  text-transform: uppercase;
  word-break: break-all;
}

.detailBrevet .header-brevet .brevet-desc-content {
  padding-right: 0;
  padding-left: 0;
  margin: 0 30px;
  border-bottom: 2px solid #eaeaea;
  padding-top: 0;
}

.detailBrevet .header-brevet .brevet-link-info {
  box-shadow: none;
  padding: 20px 30px;
}

.brevet-link-info .brevet-bottom-info {
  width: 50%;
}

.brevet-link-info .list-profil-icon {
  width: auto;
}

.blc-smallbr-item .list-profil-icon {
  width: auto !important;
}

/* .profil-icon-item.second */
.detailBrevet .header-brevet .brevet-bottom {
  display: block;
  margin-top: 0;
  text-align: right;
}

.brevet-bottom .brevet-tag-list li {
  margin-right: 5px;
}

.headcenter-brevet {
  display: flex;
  /* width:100px; */
}

.detailBrevet .header-brevet .brevet-description {
  padding: 64px;
}

.detailBrevet .brevet-desc-content .brevet-title {
  height: auto;
  display: block;
}

.detailBrevet .lpicto-goback {
  display: flex;
}

.detailBrevet .lpicto-goback:before {
  content: '';
  background: url(./images/chevron-right.svg) no-repeat center center;
  display: block;
  height: 18px;
  width: 18px;
  background-size: 62%;
  opacity: 0.4;
}

.detailBrevet .lpicto-goback:after {
  background: none;
}

.btn-share-brevet {
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0;
}

.btn-share-brevet span {
  margin-right: 5px;
  padding-left: 40px;
}

.btn-share-brevet button span {
  padding-left: 0;
}

.blc-brevet .btn-share-brevet button span {
  line-height: 0.2;
}

/* page personne morale */
.profil-left {
  width: 35%;
}

.profil-left+.blc-social {
  width: 50%;
}

.content-form {
  padding-top: 40px;
  padding-bottom: 10px;
}

.embedded-link {
  color: #59c1e5 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0;
}

.brevet-link .embedded-link {
  color: #000 !important;
}

.newsfeed .lshare .embedded-link {
  /* padding: 10px 30px; */
}

.layout-entreprise {
  padding: 0 60px;
  border: 1px solid #eaeaea !important;
  border-radius: 3px !important;
  box-shadow: none;
  background-color: #ffffff;
}

.layout-entreprise .put-inline.title-bloc {
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
}

.profil-enterprise .layout-entreprise .layout-medium {
  width: 100%;
}

.profil-enterprise .layout-entreprise .layout-medium h2.clr-cobalt {
  margin-top: 18px;
}

.profil-enterprise .layout-entreprise .layout-medium .corporation-feed {
  width: 100%;
}

/*share links*/

.display-link a {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
}

.display-txt {
  display: block;
  padding: 10px 30px;
  font-size: 12px;
  line-height: 20px;
}

.feedContainer .blc-text-publication .display-txt {
  padding: 10px 0;
  overflow: hidden;
}

.feedContainer .blc-text-publication .display-txt .display {
  padding: 10px 0px;
}

.feedContainer .blc-text-publication .display-txt .display div {
  padding: 0 30px;
}

.feedContainer .blc-text-publication .display-txt .linkurl {
  padding: 20px 30px;
}

.feedContainer .blc-text-publication .display-txt .display .linkAnnonce {
  padding: 0;
}

.feedContainer .blc-text-publication .display-txt .display .linkAnnonce p {
  padding: 0 30px;
}

.linkurl.link-video {
  margin-top: 20px;
}

.feedContainer .blc-text-publication .display-txt .txtDisplay+.linkurl {
  margin-top: 60px;
}

.feedContainer .blc-text-publication .display-txt .display div div {
  padding: 0;
}

.feedContainer .blc-text-publication .show-more {
  float: right;
}

.feedContainer .newsfeed .blc-head-publication .btn-pub,
.favoris-page .feedContainer .blc-head-publication .btn-pub {
  margin-left: auto;
}

.display-txt .display {
  padding-right: 0;
  padding-left: 0;
}

/* Profil Entreprise */
.blc-profil-head-info .word-break {
  line-height: 24px !important;
  display: block;
  padding-right: 10px;
}

.profil-enterprise .blc-profil-head-info .blc-profil-name .btn-edit {
  right: -40px;
}

.profil-enterprise .profil-left .blc-profil-head-info span.block {
  line-height: 22px;
  margin-top: 10px;
}

.profil-enterprise .profilInfo .blc-profil-actions {
  width: 100%;
}

.profil-enterprise .blc-tags {
  /* padding-bottom: 40px; */
}

.profil-enterprise .blc-profil-actions button {
  margin-right: 0;
}

.profil-enterprise .blc-profil-actions div button {
  margin-right: 0;
}

.add-feed {
  margin-right: 23px !important;
}

.profil-enterprise .blc-resume .show-more {
  top: 0;
}

.blc-dialogForm .blc-resume .show-more {
  top: 0;
}

.layout-entreprise .corporation-feed {
  margin-top: 25px;
}

/* Paramétrage */
.blc-parametrage {
  font-family: 'Montserrat', sans-serif;
}

.header-param {
  position: relative;
  height: 250px;
  width: 100%;
  background: url(./images/bg-header-annonce-form.png) no-repeat top center,
    linear-gradient(to bottom, rgba(250, 244, 254, 1), #f9f9f9) !important;
}

.blc-parametrage .container {
  position: relative;
  /* top: 250px; */
}

.blc-parametrage .blc-profil-header {
  width: 50%;
  justify-content: center;
}

.blc-parametrage .blc-img-profil img {
  padding: 0;
  border: none;
}

.blc-param-left {
  width: 285px;
}

.blc-dialogForm.adminEntreprise .list-contact .blc-contact-item-right {
  flex: none;
  width: auto;
}

.adminEntreprise .blc-param-compte .lpicto-search {
  position: relative;
}

.blc-param-compte {
  padding-top: 50px;
}

.blc-param-compte .blc-param-content.container {
  padding: 0 70px;
}

.blc-parametrage .blc-param-compte {
  overflow: unset;
}

.blc-parametrage .title-right {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: -1.5px;
  color: #333;
  margin-bottom: 40px;
}

.blc-parametrage .paramMenulist {
  position: sticky;
  position: -webkit-sticky;
  /* top: 80px; */
  top: 5px;
}

.blc-parametrage .paramMenulist li {
  height: 42px;
}

.blc-parametrage .paramMenulist li:not(.active) a {
  font-weight: 500;
}

.blc-parametrage .blc-param-compte section {
  display: block;
  width: 100%;
  margin-bottom: 90px;
}

.blc-parametrage .blc-param-compte .rowForm {
  margin-bottom: 15px;
}

.blc-parametrage .blc-param-compte .blc-param-title {
  margin-bottom: 35px;
  letter-spacing: -1px;
}

.blc-parametrage .blc-param-compte .blc-param-title.active {
  color: #a03ee6;
}

.blc-parametrage .blc-param-form>*:not(:last-child) .accordionForm {
  padding-bottom: 35px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.blc-parametrage .blc-param-form .rowForm input {
  background-color: #fff !important;
  color: #333;
  font-size: 12px;
}

.blc-parametrage .blc-param-compte .accordionForm-header {
  align-items: center;
  padding-bottom: 0;
  box-shadow: none;
}

.blc-parametrage .blc-param-compte .blc-check-label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  letter-spacing: -1px;
}

.blc-parametrage .blc-param-compte .lcheckbox {
  width: auto;
}

.blc-parametrage .blc-param-compte .accordionForm-title {
  font-size: 14px;
  font-weight: 700;
  color: #222;
  letter-spacing: -1px;
}

.blc-parametrage .blc-param-compte .accordionForm-subtitle {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}

.blc-parametrage .popClosebtn {
  font-size: 0;
  right: 0;
  width: auto;
}

.blc-parametrage .popClosebtn::before {
  background: url(images/ico-close-grey.svg);
  background-size: cover;
  content: '';
  top: 20px;
  display: block;
  height: 15px;
  width: 15px;
  margin: 0;
}

.blc-parametrage .popClosebtn:hover::before {
  background-color: #333;
}

/* #root.with_header .lpicto-edit:after{
    background: none;
    background: url(./images/icon-edit-pencil-draw.svg) no-repeat center center ;
    background-size: 120%;
    opacity: .5;
} */
.ico-edit-pencil {
  width: 21px;
  height: 21px;
  position: absolute;
  background: url(./images/icon-edit-pencil-draw.svg) no-repeat center center;
  right: 0;
  background-size: 120%;
  opacity: 0.6;
}

/* .parcours-action .lpicto-edit, .diplom-action .lpicto-edit {
    top: -50px;
    right: -61px !important;
} */

.accordionForm-header:hover .ico-edit-pencil,
.ico-edit-pencil:hover,
#root.with_header .lpicto-edit:hover:after {
  opacity: 1;
}

.blc-param-compte .abonnement-subtitle .day-left {
  display: inline-block;
  margin-left: 10px;
}

/* Dashboard créer command */

.blc-dialogForm.popup-spec {
  max-width: 860px;
}

.popup-spec .popTitle {
  font-size: 40px;
  margin: 0;
  /* height: 160px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
}

.popup-spec .popForm {
  max-width: 500px;
  margin-top: 0;
  padding: 0 10px;
}

.popup-spec .rowForm {
  margin-bottom: 10px;
}

.popup-spec .rowForm textarea {
  font-size: 11px;
  min-height: 140px;
}

/* dashboard */

.dashboard .container {
  display: flex;
  padding-bottom: 48px;
}

.blc-dashboard-right {
  width: 350px;
}

.blc-dashboard-right .btn-40 {
  width: 100%;
}

.blc-dashboard-right .wall-right {
  height: 100%;
  width: 100%;
}

.blc-dashboard-center {
  width: calc(100% - 315px);
}

.blc-dash-filter {
  margin-left: 20px;
}

.dashboard .blc-content-tab .blc-tab-title ul>li {
  border-bottom: 3px solid #e5e5e5;
  width: 100%;
  position: relative;
}

.dashboard .blc-content-tab .blc-tab-title ul>li.active::after {
  background-image: linear-gradient(to right, #1578fb, #17ead7) !important;
}

.dashboard .wall-right {
  margin: 0 0 0 30px;
}

.favoris-page .blc-tab-title .ldropdown.toggleDropDown.sm-show,
.search-wrap .blc-tab-title .ldropdown.toggleDropDown.sm-show {
  display: none;
}

.favoris-page .blc-contact-relation .blc-contact-item {
  border: none;
}

.favoris-page .blc-contact-relation .blc-contact-item:hover {
  background-color: #f3fbfd;
}

.favoris-page .blc-contact-relation .blc-contact-item-left {
  flex: 1 1 100%;
  cursor: pointer;
}

.detailAnnonce .interested-list .blc-contact-item-left {
  flex: none;
  width: auto;
  margin-right: auto;
  max-width: calc(100% - 140px);
  overflow: hidden;
}

.detailAnnonce .interested-list .blc-contact-relation .blc-contact-item-right {
  flex: none;
  width: auto;
  padding: 0;
}

.favoris-page .blc-contact-relation .blc-contact-item-right {
  flex: 0 0 300px;
}

.favoris-page .blc-contact-relation .blc-contact-item-right .ldropdown {
  order: 2;
}

.favoris-page .blc-contact-relation .blc-contact-item-right .lpicto-favoris {
  order: 0;
}

.favoris-page .group-action {
  display: none;
}

/* .favoris-page .blc-contact-item-right{
    flex: 0 0 ;
} */
.favoris-page .blc-content-tab ul {
  border-bottom: 3px solid #eaeaea;
}

.favoris-page .tab-standard-body ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background: #fff;
  padding: 14px 20px;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.favoris-page .tab-standard-body ul.pagination {
  justify-content: center;
}

.favoris-page .tab-standard-body ul li {
  width: 100%;
  padding: 0 10px;
}

.favoris-page .corporation-list ul li:hover {
  background-color: #f3fbfd;
}

.favoris-page .production-list ul {
  padding: 20px;
}

.favoris-page .production-list ul li {
  padding: 0;
}

.favoris-page .tab-standard-body ul.pagination li {
  width: auto;
}

.favoris-page .tab-standard-body ul li.annonce-item {
  width: 45%;
}

.favoris-page .tab-standard-body .tag-list ul {
  border: none;
}

.favoris-page .wrapper-annonce .role-author,
.annonce-about .role-author {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6ch;
}

.favoris-page .tab-standard-body .list-profil-icon ul {
  background: none;
  border: none;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0px 20px;
}

.favoris-page .tab-standard-body .list-profil-icon ul li {
  margin: 0 5px;
}

.favoris-page .tab-standard-body .production-list .list-profil-icon ul {
  padding: 0;
}

.favoris-page .tab-standard-body .production-list .list-profil-icon ul li {
  margin: 0 7px 0 0;
  padding: 0;
}

.favoris-page .blc-smallbr-item .brevet-info {
  max-width: calc(100% - 80px);
  padding-right: 20px;
}

.favoris-page .blc-smallbr-item .brevet-action {
  width: 60px;
}

.favoris-page .tab-standard-body .brevet-tag-list ul {
  border: none;
  justify-content: flex-start;
  padding: 0;
}

.favoris-page .production-list .blc-smallbr-item .brevet-action {
  width: 60px;
}

.favoris-page .tab-standard-body .brevet-tag-list ul li {
  width: auto;
  margin: 0 2px;
}

.favoris-page .blc-prod-filter {
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 0 0;
}

.favoris-page .tab-standard-body {
  max-width: 900px;
}

.favoris-page .blc-center {
  margin-bottom: 30px;
}

.favoris-page .blc-head-publication {
  /*align-items: center;*/
}

.favoris-page .blc-prod-filter .blc-select-container select {
  line-height: normal;
}

.favoris-page .blc-head-publication .usr-pub {
  flex: none;
}

.favoris-page .feedContainer .usr-info-pub {
  width: 100%;
}

/*.favoris-page .blc-btn-action-publication{
    padding-left:30px;
    padding-right:30px;
}
.favoris-page .blc-add-comment{
    padding-left:30px;
    padding-right:30px;
}*/
.favoris-page .lpicto-video:after {
  height: 23px;
  width: 29px;
}

.favoris-page .feedContainer .btn-option-pub {
  margin: 0 0 0 15px;
  padding: 0;
}

.favoris-page .btn-option-pub .btn.toggleDropDown {
  margin: 0;
  padding: 3px 0;
}

.favoris-page .corporation-list .nameJob-ctnr {
  width: 100%;
}

.favoris-page .corporation-list .tag-ctnr {
  min-width: 125px;
  width: auto;
}

.popForm .popTitle {
  text-align: center;
  padding-bottom: 20px;
  line-height: 1;
}

.formAction.formAction-spec>button {
  flex: 0 0 45%;
}

.popFrom-indication .strong {
  display: block;
  padding: 20px;
  margin: 20px 0;
  width: 100%;
}

/* dashboard annonce */

.dashboard .blc-annonce-item {
  display: flex;
}

.dashboard .annonce-body {
  width: 65%;
  margin: 0;
  top: 0;
  padding: 30px 0 30px 40px;
}

.dashboard .annonce-header {
  width: 20%;
  height: auto;
}

.dashboard .annonce-modif {
  width: 19%;
  padding: 18px;
}

.dashboard .img-annonce {
  overflow: hidden;
  height: 100%;
}

.dashboard .img-annonce img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard .annonce-body .info-date {
  text-align: left;
}

.dashboard .title-annonce {
  text-align: left;
}

.dashboard .localtarif-annonce {
  display: none;
}

.dashboard .annonce-ref {
  display: none;
}

.dashboard .annonce-body .annonce-center {
  border: none;
}

.dashboard .annonce-body .info-author {
  display: none;
}

.share-annonce-bottom,
.btn-cmd {
  display: none;
}

.dashboard .share-annonce-bottom {
  display: flex;
  flex: 0 0 auto;
  min-width: 120px;
  width: 14%;
}

.dashboard .btn-cmd {
  display: block;
}

.dashboard .annonce-about {
  justify-content: space-between;
}

.dashboard .annonce-about .btn-cmd {
  display: block;
  /* flex: 0 0 auto; */
  min-width: 120px;
  width: 40%;
}

.dashboard .annonce-about .btn-cmd .btn {
  padding: 0 18px;
}

.dashboard .annonce-about .btn-cmd span {
  padding: 0 24px;
}

.dashboard .date-fin-annonce {
  border-right: 1px solid rgb(234, 234, 234);
  border-left: 1px solid rgb(234, 234, 234);
  display: flex;
  flex: 0 0 auto;
  /* height: 26px; */
  justify-content: center;
  position: relative;
  margin: 0 10px;
  padding: 0 15px;
  min-width: 100px;
}

.dashboard .budget-od {
  margin: 0 10px;
}

.dashboard .lpicto-edit:after {
  height: 30px;
  width: 26px;
  /*background: url(./images/icon-edit-gris.png) no-repeat;*/
}

.dashboard .lpicto-delete:after {
  background-size: 90%;
  opacity: 0.5;
}

.dashboard .lpicto-share:before {
  background-size: 90%;
  opacity: 0.5;
}

/* Invitation */

.blc-validate-contact {
  padding: 26px 30px;
}

.blc-validate-contact p span {
  color: #59c1e5;
}

.invitation .blc-content-tab .blc-tab-title ul>li {
  height: 60px;
  line-height: 60px !important;
  padding: 0 !important;
}

.invitation-group .blc-content-tab .blc-tab-title ul>li {
  width: 50%;
}

.invitation .sticky-block {
  margin-bottom: 25px;
}

.invitation-group.invitation .wall-right .wall-left {
  margin: 30px 25px 25px 15px;
}

.invitation-group.invitation .wall-right {
  margin: 30px 15px 25px 25px;
}

.invitation .blc-content-tab .blc-tab-title ul>li.active::after {
  bottom: 3px;
}

.invitation .container {
  display: flex;
}

.invitation .subTab-title {
  max-width: 1223px;
  margin: 0 auto;
}

.invitation .wall-left {
  margin: 30px 30px 30px 15px;
}

.invitation .wall-right {
  margin: 30px 15px 0 30px;
}

.wall-right .blc-contact-item {
  display: block;
}

.wall-right .blc-contact-item .lprofilPm-item {
  width: 100%;
}

.embedded-link,
.feedText a {
  color: #59c1e5 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0;
}

.popContent>iframe {
  width: 100%;
  min-height: 400px;
  border: 0 none;
}

.controls {
  border-bottom: 1px solid black;
  padding: 10px;
  background-color: Gainsboro;
}

.controls>div {
  margin-bottom: 10px;
}

.controls>div:last-child {
  margin-bottom: 0px;
}

.introjs-helperLayer {
  background-color: transparent !important;
  border: 2px solid #59c1e5 !important;
}

.introjs-overlay {
  opacity: 0 !important;
}

.introjs-helperNumberLayer {
  background-image: linear-gradient(to right, #1f379a, #af42f0) !important;
}

.introjs-button {}

.introjs-tooltip {
  border: 2px solid #59c1e5 !important;
}

.introjs-skipbutton {
  color: #333 !important;
}

.blc-choice-container .tooltip {
  position: relative !important;
  visibility: initial !important;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 550px;
  background-color: #d7dbdd;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 63%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.deleteItem {
  position: absolute;
  right: -25px;
  cursor: pointer;
}

@media only screen and (min-width: 750px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 650px;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: 100;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (max-width: 750px) and (min-width: 501px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 500px;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: 80;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (max-width: 500px) and (min-width: 401px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: 30;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (max-width: 400px) and (min-width: 300px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 270px;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -30px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (max-width: 300px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    text-align: justify;
    text-justify: auto;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -30px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.tooltip .tooltiptext1 {
  visibility: hidden;
  width: 550px;
  background-color: #d7dbdd;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext1::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 63%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext1 {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 851px) {
  .tooltip .tooltiptext1 {
    visibility: hidden;
    width: 500px;
    text-size-adjust: auto;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (min-width: 699px) and (max-width: 850px) {
  .tooltip .tooltiptext1 {
    visibility: hidden;
    width: 350px;
    text-size-adjust: auto;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (max-width: 600px) {
  .tooltip .tooltiptext1 {
    visibility: hidden;
    width: 200px;
    text-size-adjust: 12;
    background-color: #d7dbdd;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (min-width: 1000px) {
  .tooltip .tooltiptext1 {
    visibility: hidden;
    width: 400px;
    background-color: #d7dbdd;
    color: black;
    text-align: justify;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@media only screen and (min-width: 1000px) {
  .tooltip .tooltiptext1 {
    visibility: hidden;
    width: 400px;
    background-color: #d7dbdd;
    color: black;
    text-align: justify;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    right: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}