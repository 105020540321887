@charset "UTF-8";

@font-face {
  font-family: 'icomoon';
  src: url(fonts/icomoon.eot);
  src: url(fonts/icomoon.eot) format('embedded-opentype'),
    url(fonts/icomoon.ttf) format('truetype'),
    url(fonts/icomoon.woff) format('woff'), url(fonts/icomoon.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(fonts/Montserrat.svg) format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-infinite:before {
  content: '\EA2F';
}

.icon-radio:before {
  content: '\E908';
}

.icon-checkbox:before {
  content: '\E909';
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.btn-container>div {
  display: flex;
}

.playvideo {
  display: flex;
  align-items: center;
  color: rgba(22, 160, 239, 255);
  border: 2px solid rgba(22, 160, 239, 255);
  border-radius: 25px;
  text-transform: uppercase;
  padding: 8px 15px;
  font-weight: 600;
  letter-spacing: 3px;
  cursor: pointer;
}

.icon-checkbox-ok:before {
  content: '\E90A';
}

.icon-radio-ok:before {
  content: '\E90B';
}

.icon-cross:before {
  content: '\E901';
}

.icon-user:before {
  content: '\E902';
}

.icon-twitter:before {
  content: '\E900';
}

.icon-chevron:before {
  content: '\E903';
}

.icon-mail:before {
  content: '\E904';
}

.icon-linkedin:before {
  content: '\E905';
}

.icon-facebook:before {
  content: '\E906';
}

.icon-check:before {
  content: '\E907';
}

body {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-weight: 300;
  margin: 0;
  font-size: 14px;
}

/* .btn-connexion {
  font-size: 14px !important;
  font-weight: bold !important;
  position: relative;
} */

a#connectLink {
  color: black;
  text-decoration: none;
}

/* .btn-connexion:before {
  content: "";
  background: transparent
    url(https://betatest.linkinnov.com/static/media/account.9e21087d.svg)
    no-repeat 50%;
  width: 20px;
  padding: 10px 0;
  height: 20px;
  position: absolute;
  left: -2px;
  background-size: 80%;
  top: 9px;
} */

@media (max-width: 480px) {
  main {
    margin: 63px auto 0;
  }
}

@media (max-width: 480px) and (max-width: 768px) {
  main {
    overflow-x: hidden;
  }
}

/* @media (max-width: 480px) {
  .btn-connexion {
    padding: 10px 0px;
    color: black;
    font-weight: bold !important;
    text-decoration: none;
    width: 51px;
    position: relative;
    margin-right: 16px;
    font-size: 14px !important;
    position: relative;
  }

  .btn-connexion:before {
    content: "";
    background: transparent
      url(https://betatest.linkinnov.com/static/media/account.9e21087d.svg)
      no-repeat 50%;
    width: 20px;
    padding: 10px 0;
    height: 20px;
    position: absolute;
    left: -18px;
    background-size: 53%;
    top: 6px;
  }
} */

/* @media (max-width: 480px) and (max-width: 768px) {
  .btn-connexion:before {
    left: -7px;
  }
} */

@media (max-width: 480px) {
  a#connectLink {
    padding: 10px 0;
    color: black;
    font-weight: bold;
    width: 51px;
    position: relative;
  }

  .free-premium .btn-container .btn {
    width: 250px;
  }

  .logo-container {
    width: 41%;
    margin: auto;
  }

  /* .header nav {
    flex-direction: column;
    align-items: center;
  } */
  nav .btn2 {
    font-size: 0.675em !important;
    font-weight: 600 !important;
    font-weight: 300 !important;
  }

  /* .header nav {
    flex-direction: column;
    align-items: center;
  } */
}

@media (max-width: 768px) {
  .partner-container .partner-list li {
    flex: 50%;
  }
}

/* inline_style_edits.inline.css */

.tester-list {
  flex: 0 1 50%;
}

/* .header {
  box-shadow: 4px -3px 6px 2px grey;
} */
/* main {
  margin: 155px auto 0;
*/

h1,
h2,
h3,
h4 {
  font-weight: 100;
}

h1 {
  font-size: 3.8em;
  letter-spacing: -2px;
  margin-bottom: 45px;
}

@media (max-width: 480px) {
  h1 {
    font-size: 2em;
  }
}

h2 {
  font-size: 3.1em;
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.75em;
  }
}

/* h3 {
  font-size: 2.2em;
  letter-spacing: -2px;
} */

@media (max-width: 480px) {
  h3 {
    font-size: 1.25em;
  }
}

h4 {
  font-size: 0.75em;
}

/* header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
} */

img {
  max-width: 100%;
}

section {
  max-width: 1195px;
  margin: 0 auto;
}

@media (max-width: 992px) {
  section {
    /*padding: 0 30px;*/
  }
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

figure {
  margin: 0;
}

/* input[type="text"] {
  margin: 10px 5px;
  padding: 13px 20px;

  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.3);
} */

input[type='email'] {
  margin: 10px 5px;
  padding: 13px 20px;
  width: 170px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.3);
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  position: relative !important;
  margin: 3px 5px 0 10px;
  cursor: pointer;
  border: none transparent;
  background-color: transparent;
  height: auto;
  width: auto;
}

input[type='checkbox']:focus,
input[type='radio']:focus {
  box-shadow: none;
  outline: none;
}

input[type='checkbox']::before,
input[type='radio']::before {
  display: block;
  width: 16px;
  height: 16px;
  font-family: 'icomoon';
  font-size: 1.2em;
}

input[type='checkbox']::before {
  /*content: '\E909';*/
}

input[type='radio']::before {
  content: '\E908';
}

/*input[type="checkbox"]:checked::before {*/
/*  content: "\E90A";*/
/*}*/

input[type='radio']:checked::before {
  content: '\E90B';
  color: #59c1e5;
}

.button2,
.btn2 {
  display: inline-block;
  padding: 15px 40px;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
}

button2,
.btn_cookie {
  display: inline-block;
  padding: 12px 40px;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  margin-left: 7px;
}

button a,
.btn a {
  color: inherit;
  text-decoration: none;
}

.btn-small2 {
  padding: 13px;
}

.btn-gradient2 {
  background-image: linear-gradient(243deg, #af42f0, #1f379a);
  border: 0;
  letter-spacing: 3px;
  color: #fff;
  text-transform: uppercase;
}

.btn-purple {
  color: #a03ee6;
}

.btn-blue {
  color: #59c1e5;
}

.btn-light {
  border: 1px solid;
}

.logo-container {
  width: 18%;
}

@media (max-width: 992px) {
  .logo-container {
    width: 30%;
    text-align: center;
  }
}

body.noscroll {
  overflow: hidden;
}

.hidden {
  display: none;
}

li.tester-li {
  flex: 20% !important;
}

@media (max-width: 768px) {
  li.tester-li {
    flex: 50% 0 !important;
    width: 50%;
    padding: 0;
  }
}

.flex-container,
#user_form_gender,
.form-container form div:not(.expertFormik div) {
  display: flex;
}

.card-1 {
  font-size: 16px;
  align-self: start;
  max-width: calc(100vw - 75%);
}

.card-1 img {
  max-width: 90%;
}

.card-2 {
  max-width: calc(100vw - 80%);
  align-self: start;
}

#cacl-margin-top {
  margin-top: -18.5%;
}

.left-padding-acc {
  padding-left: 11rem;
  padding-top: 2rem;
}

@media (max-width: 2560px) {
  #cacl-margin-top {
    margin-top: -16.1%;
  }

  .presentation1 .card.remove-width {
    margin-top: 22rem;
  }
}



@media (max-width: 1999px) {
  #cacl-margin-top {
    margin-top: -18.3%;
  }

  .presentation1 .card.remove-width {
    margin-top: 14rem;
  }

}

@media (max-width: 1440px) {
  #cacl-margin-top {
    margin-top: -22.4%;
  }

  .presentation1 .card .top img {
    width: 40% !important;
    height: 7rem;
    margin-top: 0.99rem;
  }

  .card-2 {
    max-width: calc(100vw - 74%);
  }

  .presentation1 .card.remove-width {
    margin-top: 6rem;
  }
}

@media (max-width: 1024px) {
  #cacl-margin-top {
    margin-top: -27.9%;
  }

  .left-padding-acc {
    padding-left: 8rem;
  }

  .card-2 {
    max-width: calc(100vw - 63%);
  }

  .presentation1 .card .top img {
    width: 37%;
    height: 8rem;
    margin-top: 1.6rem;
  }
}

@media (max-width: 768px) {
  #cacl-margin-top {
    margin-top: -34.7%;
  }
}

.remove-width {
  margin-top: 10rem;
  margin-left: 10%;
}

@media (max-width: 992px) {

  .flex-container,
  #user_form_gender,
  .form-container form div {
    flex-direction: column;
  }

  .card-1,
  .card-2 {
    max-width: none;
  }

  .card-2 {
    margin-left: 0;
  }

  .left-padding-acc {
    padding-left: 0;
  }

  .remove-width {
    margin-top: 3rem;
  }
}

nav {
  max-width: 1495px;
  margin: 0 auto;
  padding: 22px;
}

nav .partner-container {
  margin: auto 20px;
}

nav .partner-container li {
  max-width: 15%;
}

nav.flex-container,
nav#user_form_gender {
  justify-content: space-between;
}

nav .nav-tools {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .nav-tools>div {
  float: right;
}

nav .nav-tools>span:not(.btn) {
  font-weight: bold;
  padding-right: 30px;
}

nav .nav-tools>span:not(.btn) i {
  padding-right: 10px;
}

nav .nav-tools .language-select {
  font-size: 14px;
  margin-left: 20px;
}

nav .nav-tools .language-select.open .dropdown-toggle {
  box-shadow: none;
}

nav .nav-tools .language-select .dropdown-menu {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  right: 0;
}

nav .nav-tools .language-select a {
  color: inherit;
  text-decoration: none;
  padding-left: 20px;
  background: left center no-repeat;
  background-size: 16px;
  display: block;
}

nav .nav-tools .language-select>a:after {
  content: ' ';
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAiIGhlaWdodD0iNTUiIHZpZXdCb3g9Ii0yLjUgLTUgNzUgNjAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9ICJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KPHBhdGggZD0iTTAsMCBsMzUsNTAgbDM1LC01MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz4KPC9zdmc+') center center no-repeat;
  background-size: 15px 10px;
  width: 15px;
  height: 20px;
  display: block;
  float: right;
  line-height: 20px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  nav .nav-tools {
    width: 100%;
    display: flex;
    align-items: center;
  }

  nav .nav-tools>* {
    flex: 1;
    text-align: center;
  }

  nav .nav-tools>* .btn {
    width: 85px;
    text-align: center;
  }

  nav .nav-tools>*:first-child {
    text-align: left;
  }

  /* nav .nav-tools > *:first-child .btn-connexion:before {
    top: 8px;
  } */
  nav .nav-tools>*:first-child .btn-connexion:before {
    top: 8px;
  }

  nav .nav-tools>*>* {
    font-size: 12px !important;
  }

  nav .nav-tools .language-select {
    float: right;
  }

  nav .nav-tools .language-select a {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px !important;
  }

  nav .nav-tools .language-select>a:after {
    width: 11px;
    height: 13px;
  }
}

nav .btn2 {
  font-size: 0.975em;
  letter-spacing: normal;
  padding: 10px 20px;
  text-transform: unset;
  border-radius: 25px;
  font-weight: 600;
  font-weight: 300;
  display: block ruby;
}

@media (max-width: 768px) {
  nav .btn {
    padding: 10px;
  }
}

.header {
  transition: background-color 0.3s ease-in-out;
  background-color: #fff;
}

/* .header nav {
  display: flex;
  justify-content: space-between;
} */

@media (max-width: 768px) {

  .flex-container ul.check-list li,
  #user_form_gender ul.check-list li,
  .form-container form div ul.check-list li {
    padding-bottom: 15px;
  }

  .flex-container ul.check-list li span,
  #user_form_gender ul.check-list li span,
  .form-container form div ul.check-list li span,
  .flex-container ul.check-list li p,
  #user_form_gender ul.check-list li p,
  .form-container form div ul.check-list li p {
    display: inline-block;
  }

  .flex-container ul.check-list li span,
  #user_form_gender ul.check-list li span,
  .form-container form div ul.check-list li span {
    width: 10%;
    margin: 0;
    padding: 0;
    vertical-align: top;
    top: 10px;
    position: relative;
  }

  .flex-container ul.check-list li p,
  #user_form_gender ul.check-list li p,
  .form-container form div ul.check-list li p {
    width: 90%;
  }
}

@media (max-width: 768px) {

  .flex-container ul.list-offer li i,
  #user_form_gender ul.list-offer li i,
  .form-container form div ul.list-offer li i {
    vertical-align: initial;
    position: static;
  }
}

.presentation {
  justify-content: flex-end;
  padding: 0 15px;
  margin-bottom: 10%;
}

@media (max-width: 992px) {
  .presentation {
    flex-direction: column;
    padding: 0 30px;
    margin-top: 200px;
  }
}

@media (min-width: 992px) {
  .presentation {
    margin-bottom: 5%;
  }
}

.presentation .text {
  width: 50%;
}

.presentation1 .card {
  padding: 40px;
  /* box-shadow: 0 0 0 2px rgb(255 255 255), 0.5em 0.5em 1em rgb(0 0 0 / 30%); */
  background-color: white;
  box-shadow: 0.5em 0.5em 1em rgb(0 0 0 / 30%);
}


.presentation1 .card .top {
  display: flex;
  padding-bottom: 35px;
}

.presentation figure {
  max-width: 770px;
}

.presentation1 .card .top img {
  margin-right: 40px;
  width: 30%;
}

.presentation1 .card .top span {
  font-size: 15px;
}

.presentation1 figure {
  margin: 1em 50px;

}

.presentation figure img {
  width: 100%;
  margin: 20px 0 0 -1%;
}

@media (max-width: 992px) {
  .presentation figure img {
    margin: 50px 0 0;
  }
}

@media (max-width: 992px) {

  .presentation .text,
  .presentation figure {
    width: 100%;
    max-width: initial;
  }
}

.presentation p {
  line-height: 2;
  max-width: 475px;
  margin-bottom: 45px;
}

@media (max-width: 992px) {
  .presentation p {
    max-width: initial;
  }
}

@media (max-width: 992px) {
  .presentation .btn-light {
    padding: 0 11px;
  }
}

@media (max-width: 992px) {
  .presentation .icon-mail {
    font-size: 0.5em;
    line-height: 4;
  }
}

.presentation .btn-gradient2 {
  margin: 0 15px 15px 0;
  border-radius: 25px;
  font-weight: 600;
}

@media (max-width: 992px) {
  .presentation .btn-gradient2 {
    font-size: 0.7em;
  }
}

.text-presentation {
  max-width: 90%;
  margin: 0 auto 50px;
  text-align: center;
}

.text-presentation p {
  line-height: 2;
  font-size: 14px;
}

.text-presentation h4 {
  text-transform: uppercase;
}

.background-wrapper {
  position: relative;
}

.background-wrapper h4 {
  font-weight: bold;
  letter-spacing: 3px;
}

.background-wrapper .oval {
  position: absolute;
  height: auto;
  z-index: -1;
}

.background-wrapper .oval.oval-offer:nth-child(2) {
  top: 130px;
  left: 6%;
  width: 35px;
}

.background-wrapper .oval.oval-offer:nth-child(3) {
  top: 410px;
  left: 12%;
  width: 22px;
}

.background-wrapper .oval.oval-offer:nth-child(4) {
  top: 110px;
  left: 27.3%;
  width: 12px;
}

.background-wrapper .oval.oval-offer:nth-child(5) {
  top: 104px;
  right: 25.2%;
  width: 22px;
}

.background-wrapper .oval.oval-offer:nth-child(6) {
  top: 222px;
  right: 18%;
  width: 37px;
}

.background-wrapper .oval.oval-offer:nth-child(7) {
  top: 485px;
  right: 11.3%;
  width: 17px;
}

.background-wrapper .oval.oval-offer:nth-child(8) {
  top: 145px;
  right: 6.8%;
  width: 9px;
}

.background-wrapper .oval.oval-offer:nth-child(9) {
  top: 280px;
  left: 5%;
  width: 104px;
}

.background-wrapper .oval.oval-offer:nth-child(10) {
  top: 218px;
  left: 20.4%;
  width: 118px;
}

.background-wrapper .oval.oval-offer:nth-child(11) {
  top: 270px;
  right: 4.9%;
  width: 118px;
}

.background-wrapper .oval.oval-partner:nth-child(2) {
  top: 264px;
  left: 5.3%;
  width: 37px;
}

.background-wrapper .oval.oval-partner:nth-child(3) {
  top: 157px;
  left: 14.7%;
  width: 13px;
}

.background-wrapper .oval.oval-partner:nth-child(4) {
  top: 141px;
  right: 17.8%;
  width: 37px;
}

.background-wrapper .oval.oval-partner:nth-child(5) {
  top: 278px;
  right: 8.1%;
  width: 10px;
}

.background-wrapper .oval.oval-partner:nth-child(6) {
  top: 187px;
  right: 3%;
  width: 23px;
}

.background-wrapper .oval.oval-target:nth-child(2) {
  top: 20px;
  right: 20%;
  width: 1.6%;
}

.background-wrapper .oval.oval-target:nth-child(3) {
  top: 180px;
  right: 16%;
  width: 1%;
}

.background-wrapper .oval.oval-target:nth-child(4) {
  top: 250px;
  right: 18%;
  width: 1.2%;
}

.background-wrapper .oval.oval-target:nth-child(5) {
  top: 20px;
  left: 18%;
  width: 2%;
}

.background-wrapper .oval.oval-target:nth-child(6) {
  top: 100px;
  left: 13%;
  width: 1%;
}

.background-wrapper .oval.oval-target:nth-child(7) {
  top: 180px;
  left: 20%;
  width: 1.3%;
}

@media (max-width: 1200px) {
  .background-wrapper .oval.personna {
    display: none;
  }
}

@media (max-width: 992px) {
  .background-wrapper .oval {
    display: none;
  }
}

.background-wrapper.purple-background h4,
.background-wrapper.powder-background h4 {
  color: #a03ee6;
}

.background-wrapper.purple-background {
  background-image: linear-gradient(to bottom,
      rgba(160, 62, 230, 0.08) 0%,
      rgba(160, 62, 230, 0.08) 70%,
      rgba(255, 255, 255, 0.08) 100%);
}

.background-wrapper.powder-background {
  background-image: linear-gradient(to bottom,
      rgba(255, 178, 211, 0.1) 0%,
      rgba(255, 178, 211, 0.1) 60%,
      rgba(255, 255, 255, 0.08) 100%);
}

.background-wrapper.trial {
  margin-top: 100px;
  padding: 0 0 70px;
}

.background-wrapper.trial h4 {
  color: #59c1e5;
}

.background-wrapper.offer,
.background-wrapper.partner {
  padding: 100px 0 220px;
}

.background-wrapper.target {
  padding: 40px 0 20px;
  margin-top: 10px;
}

@media (max-width: 768px) {

  .background-wrapper.offer,
  .background-wrapper.partner {
    padding: 80px 0 80px;
  }
}

.background-wrapper.black-background {
  background: no-repeat #262628;
  background-size: contain;
}

@media (max-width: 992px) {
  .background-wrapper.black-background {
    background-size: cover;
  }
}

.card-container {
  justify-content: center;
  max-width: 920px;
  margin: 50px auto 0;
}

.card-container .card {
  width: 50%;
  margin: 15px;
  padding: 0 15px 65px;
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}

.card-container .card ul {
  max-width: 330px;
  margin: 0 auto 40px auto;
}

.card-container .card ul li {
  text-align: initial;
  padding: 25px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}

.card-container .card ul li:not(.active) {
  color: #c1c1c1;
}

.card-container .card ul li .icon_wrapper {
  display: inline-block;
  vertical-align: middle;
}

.card-container .card ul li p {
  display: inline-block;
  width: 83%;
  vertical-align: middle;
  margin: 0;
}

.card-container .card h3 {
  font-weight: bold;
  margin: 55px 0;
}

.card-container .card i {
  margin-right: 30px;
  font-size: 0.7em;
}

.card-container .card img {
  position: absolute;
}

.card-container .card .btn {
  min-width: 240px;
}

@media (max-width: 992px) {
  .card-container .card .btn {
    min-width: initial;
  }
}

.card-container .card.card-free h3 {
  color: #59c1e5;
}

.card-container .card.card-free img {
  top: 0;
  left: 0;
}

.card-container .card.card-free .icon-check {
  color: #59c1e5;
}

.card-container .card.card-premium h3 {
  color: #a03ee6;
  margin-bottom: 0;
}

.card-container .card.card-premium h4 {
  margin: 0 0 40px;
  font-weight: normal;
  color: #000;
  letter-spacing: -1px;
}

.card-container .card.card-premium img:first-of-type {
  top: 0;
  right: 0;
}

.card-container .card.card-premium img:last-child {
  bottom: 0;
  left: 0;
}

.card-container .card.card-premium .icon-infinite {
  color: #a03ee6;
}

.card-container .card.card-premium .icon-check {
  color: #a03ee6;
}

.card-container .card:after {
  width: 90%;
  right: 50%;
  content: '';
  z-index: -1;
  height: 95px;
  display: block;
  -webkit-filter: blur(29px);
  filter: blur(29px);
  position: absolute;
  transform: translateX(50%);
  background-color: rgba(160, 62, 230, 0.3);
}

@media (max-width: 992px) {
  .card-container .card {
    width: 450px;
    margin-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .card-container .card {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
}

.trial-section .trial-presentation {
  padding: 0 15px;
  text-align: center;
}

.trial-section .trial-presentation p {
  margin-top: 25px;
  line-height: 1.67;
  font-size: 14px;
}

.trial-section .trial-presentation p b {
  display: block;
}

.trial-section .flex-container img {
  max-width: fit-content;
}

@media (max-width: 992px) {
  .trial-section .trial-presentation {
    margin-bottom: 30px;
  }
}

.free-premium {
  padding: 70px 0 85px;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

.free-premium .text-container {
  width: 60%;
}

.free-premium .text-container p {
  font-size: 2.875em;
}

.free-premium .text-container p b {
  display: block;
}

@media (max-width: 480px) {
  .free-premium .text-container p {
    font-size: 1.5em;
  }
}

.free-premium .btn-container {
  width: 40%;
}

.free-premium .btn-container .btn {
  min-width: 340px;
  padding: 18px;
  font-size: 0.875em;
  font-weight: bold;
  letter-spacing: 4px;
}

@media (max-width: 992px) {
  .free-premium .btn-container .btn {
    margin: 15px 0;
    min-width: initial;
  }
}

@media (max-width: 480px) {
  .btn-container.btn-landing-page {
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 480px) {

  .btn-container.btn-landing-page span,
  .btn-container.btn-landing-page img {
    margin: 10px auto;
  }
}

.free-premium .btn-container .flex-container,
.free-premium .btn-container #user_form_gender,
.free-premium .btn-container .form-container form div,
.form-container form .free-premium .btn-container div {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
}

@media (max-width: 992px) {

  .free-premium .btn-container .flex-container,
  .free-premium .btn-container #user_form_gender,
  .free-premium .btn-container .form-container form div,
  .form-container form .free-premium .btn-container div {
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .free-premium {
    padding: 70px 30px 85px 30px;
  }

  .free-premium .btn-container,
  .free-premium .text-container {
    width: 100%;
  }
}

.footer-nav {
  padding: 35px 0;
  max-width: 1195px;
}

.footer-nav ul li {
  display: inline-block;
}

.footer-nav ul li:nth-child(2) {
  padding: 0 30px;
}

.footer-nav ul.socials a {
  transition: color 0.3s ease-in-out;
  color: rgba(151, 151, 151, 0.5);
}

.footer-nav ul.socials a:hover {
  color: #979797;
  text-decoration: none;
}

.footer-nav ul.landing-page li a {
  color: #000;
}

.footer-nav ul.landing-page li a:hover {
  text-decoration: none;
}

.footer-nav ul.landing-page li:nth-child(2) {
  padding: 0 15px;
}

.footer-nav .footer-element {
  min-width: 150px;
  margin: auto 10px;
}

.logo-container.footer-element p {
  width: 180%;
  margin-top: 15px;
}

.socials-container.footer-element {
  display: flex;
  justify-content: center;
}

.socials-container.footer-element img {
  width: 40px;
  height: 26px;
  color: gray;
  padding: 0 7px;
}

@media (max-width: 992px) {
  .footer-nav .footer-element {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .footer-nav .footer-element {
    display: flex;
    justify-content: center;
    width: initial;
  }
}

.footer-nav .socials-container {
  text-align: center;
  margin: auto;
}

@media (max-width: 992px) {
  .footer-nav {
    text-align: center;
  }
}

.blue-background {
  background-color: rgba(43, 252, 226, 0.09);
}

.blue-background h4 {
  color: #59c1e5;
  text-align: center;
}

.blue-background p {
  line-height: 1.5;
  margin-top: 30px;
}

.blue-background i {
  color: #59c1e5;
  padding-right: 20px;
  font-size: 0.7em;
}

.blue-background .text-presentation {
  max-width: 80%;
  margin-bottom: 0;
}

.svg-container {
  margin-bottom: -20px;
}

.platform {
  padding: 75px 4% 140px;
  justify-content: space-around;
}

.platform h4 {
  margin: 30px 0;
  font-size: 1.5em;
}

.platform ul li {
  line-height: 2.25;
  font-weight: bold;
}

@media (max-width: 768px) {
  .platform ul li {
    font-size: 12px;
  }
}

.text-presentation .fourthSection_description {
  font-size: 16px;
}

@media (max-width: 768px) {
  .text-presentation .fourthSection_description {
    font-size: 12px;
    text-align: initial;
  }
}

.platform .platform-item {
  padding: 0 15px;
  position: relative;
}

.platform .platform-item img {
  transition: all 0.3s ease-in-out;
}

.platform .platform-item img:hover {
  transform: scale(1.1);
}

@media (max-width: 992px) {
  .platform .platform-item img:hover {
    transform: scale(1);
  }
}

.platform .platform-item.platform-businessman h4,
.platform .platform-item.platform-businessman i {
  color: #a03ee6;
}

.platform .platform-item.platform-businessman img {
  transform-origin: bottom left;
}

.platform .platform-item.platform-finder img {
  transform-origin: bottom right;
}

@media (max-width: 992px) {
  .platform .platform-item {
    margin-bottom: 70px;
  }
}

@media (max-width: 768px) {
  .platform {
    padding: 75px 0 50px;
  }
}

.target-container {
  margin-left: 100px;
}

.target-container .target-first {
  display: flex;
}

.target-container .target-element {
  margin: 10px;
}

.target-container .multi-col ul {
  column-count: 2;
}

@media (max-width: 480px) {
  .target-container .multi-col ul {
    column-count: 1;
  }
}

.target-container h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 5px;
}

.target-container .target-first {
  margin-bottom: 10px;
}

.target-container li {
  line-height: 2;
}

@media (max-width: 480px) {
  .target-container {
    margin-left: 0px;
    font-size: 12px;
  }
}

@media (min-width: 480px) {
  .target-element.target2 {
    margin-left: 100px;
  }
}

.lp-targets-img {
  margin: auto;
}

@media (max-width: 480px) {
  .lp-targets-img {
    display: none;
  }
}

.testimonial {
  color: #fff;
  align-items: center;
  padding: 0 15px;
}

.testimonial h4 {
  color: #fafafa;
  font-size: 25px;
}

.testimonial blockquote {
  max-width: 500px;
  font-size: 1.4em;
  line-height: 1.5;
  margin: 40px 0;
}

.testimonial cite {
  font-size: 1em;
  font-style: normal;
  line-height: 3.23;
  font-weight: 600;
}

.testimonial img {
  position: inherit;
}

.testimonial div {
  margin-left: 8%;
}

@media (max-width: 1200px) {
  .testimonial div {
    margin-left: 4%;
  }
}

@media (max-width: 992px) {
  .testimonial div {
    margin: 30px 0;
  }
}

.partner section {
  padding: 0 15px;
}

@media (max-width: 992px) {
  .partner section {
    padding: 0 30px;
  }
}

.partner-container .projects-list {
  /*align-items: center;*/
  justify-content: center;
}

.partner-container .projects-list h3 {
  width: 100%;
  font-size: 20px;
  display: block;
  font-weight: 650;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.partner-container .tooltip_on_hover {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(216, 216, 216, 0.24);
  border-radius: 2px;
  font-size: 14px;
  display: flex;
  width: auto;
  margin: 10px auto;
  padding: 3px;
  transition: visibility 0s, opacity 0.5s linear;
}

.partner-container .example_tooltip_on_hover:hover .tooltip_on_hover {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .partner-container .tooltip_on_hover {
    visibility: visible;
    opacity: 1;
    margin: 5px auto;
    background: none;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .with_header.landing_page h3 {
    width: 100%;
    font-size: 1em;
    display: block;
    font-weight: 650;
    letter-spacing: 0;
    margin-top: 15px;
  }
}

.partner-container .projects-list li {
  display: inline-block;
  padding: 0px 15px;
  flex: 1;
  text-align: center;
}

.partner-container .projects-list li .RI_talent_title {
  margin-bottom: 44px;
}

@media (max-width: 768px) {
  .partner-container .projects-list li {
    flex: 50% 0;
    padding: 0;
    text-align: inherit;
  }
}

@media (max-width: 992px) {
  .partner-container .projects-list .example_tooltip_on_hover {
    max-width: 20%;
  }

  .partner-container .projects-list .example_tooltip_on_hover h3 {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .partner-container .projects-list .example_tooltip_on_hover {
    flex: 100% 0;
    padding: 0;
    max-width: fit-content;
  }

  .thirdSection_li_content {
    display: flex;
  }

  .thirdSection_li_content div {
    padding-left: 7px;
    font-size: 12px;
  }

  .partner-container .projects-list li.example_tooltip_on_hover img {
    max-width: 30%;
    margin: auto;
  }

  .partner-container .projects-list .example_tooltip_on_hover h3 {
    margin: 10px auto;
  }

  .partner-container .projects-list li .RI_talent_title {
    margin: 10px auto;
  }
}

@media (max-width: 768px) {
  nav .partner-container .projects-list li {
    display: none;
  }
}

@media (max-width: 480px) {
  .with_header.landing_page nav {
    padding: 10px;
  }
}

.partner-container .projects-list li:first-child {
  padding-left: 0;
}

.partner-container .projects-list li:last-child {
  padding-right: 0;
}

.partner-container .projects-list li p {
  margin: 10px auto;
}

.partner-container .projects-list li img {
  transition: all 0.3s ease-in-out;
  padding: 2px;
  max-width: 80%;
  margin: auto;
}

.partner-container .projects-list li img.users {
  max-width: 60%;
  transition: all 0.3s ease-in-out;
}

.partner-container .projects-list li img.users:hover {
  transform: scale(1.1);
}

@media (max-width: 992px) {
  .partner-container .projects-list li img.users {
    max-width: 100%;
    margin: auto;
  }
}

@media (max-width: 992px) {
  .partner-container .projects-list li img {
    max-width: 80%;
    margin-bottom: 35px;
  }
}

.partner-container .projects-list .more {
  font-size: 4em;
  font-weight: bold;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.partner-container .projects-list .more>a:hover {
  text-decoration: none;
}

.partner-container .how-it-works-list {
  align-items: center;
  justify-content: center;
}

.partner-container .how-it-works-list li {
  display: inline-block;
  padding: 15px 15px;
  flex: 1;
  text-align: center;
}

@media (max-width: 768px) {
  .partner-container .how-it-works-list li {
    width: 100%;
    flex: 50% 0;
    padding: 0;
  }

  .partner-container .how-it-works-list li::after {
    content: '';
    width: 100%;
    border-bottom: 1px solid lightgray;
    height: 1px;
    display: block;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.partner-container .how-it-works-list li:first-child {
  padding-left: 0;
}

.partner-container .how-it-works-list li:last-child {
  padding-right: 0;
}

.partner-container .how-it-works-list li p {
  font-size: 1.5em;
  width: 100%;
  margin: 10px auto;
}

.partner-container .how-it-works-list li img {
  width: 200px;
  transition: all 0.3s ease-in-out;
  border-radius: 100%;
  height: 200px !important;
  border: 0.2px solid black;
  background: white;
  padding: 2px;
}

.partner-container .how-it-works-list li img.users {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transform: scale(1);
}

@media (max-width: 992px) {
  .partner-container .how-it-works-list li img {
    width: 150px;
    height: 150px !important;
    margin-bottom: 35px;
  }
}

.partner-container .how-it-works-list .more {
  font-size: 4em;
  font-weight: bold;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.partner-container .how-it-works-list .more>a:hover {
  text-decoration: none;
}

.partner-container .tester-list {
  align-items: center;
  justify-content: center;
}

.partner-container .tester-list li {
  display: inline-block;
  padding: 15px 25px;
  flex: 1;
}

@media (max-width: 768px) {
  .partner-container .tester-list li {
    flex: 50% 0;
    width: 50%;
    padding: 0;
  }
}

.partner-container .tester-list li:first-child {
  padding-left: 0;
}

.partner-container .tester-list li:last-child {
  padding-right: 0;
}

.partner-container .tester-list li img {
  max-width: 50%;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transform: scale(1);
}

.partner-container .tester-list li img.users {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transform: scale(1);
}

.partner-container .tester-list li img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  transform: scale(1.1);
}

@media (max-width: 992px) {
  .partner-container .tester-list li img {
    max-width: 150px;
    margin-bottom: 35px;
  }
}

.partner-container .tester-list .more {
  font-size: 4em;
  font-weight: bold;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.partner-container .tester-list .more>a:hover {
  text-decoration: none;
}

.partner-container .partner-list {
  align-items: center;
  justify-content: center;
}

.partner-container .partner-list li {
  display: inline-block;
  padding: 15px 25px;
  flex: 1;
}

@media (max-width: 768px) {
  .partner-container .partner-list li {
    flex: inherit;
    padding: 0;
  }
}

.partner-container .partner-list li:first-child {
  padding-left: 0;
}

.partner-container .partner-list li:last-child {
  padding-right: 0;
}

.partner-container .partner-list li img {
  max-width: 50%;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transform: scale(1);
}

.partner-container .partner-list li img.users {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transform: scale(1);
}

.partner-container .partner-list li img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  transform: scale(1.1);
}

@media (max-width: 992px) {
  .partner-container .partner-list li img {
    max-width: 150px;
    margin-bottom: 35px;
  }
}

.partner-container .partner-list .more {
  font-size: 4em;
  font-weight: bold;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.partner-container .partner-list .more>a:hover {
  text-decoration: none;
}

.partner-container>li:last-child {
  padding: 25px 25px;
}

.offers-list {
  justify-content: space-around;
  margin: 50px 0 100px;
  text-align: center;
}

.offers-list b {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.63;
  letter-spacing: 4.4px;
  margin-bottom: 20px;
}

.offers-list li {
  display: inline-block;
}

.offers-list li p {
  line-height: 1.82;
  letter-spacing: normal;
}

.offers-list li img {
  min-height: 100px;
  width: auto;
}

.offers-list .partnership {
  color: #e880f0;
}

.offers-list .thechnology {
  color: #a03ee6;
}

.offers-list .coursework {
  color: #212cfa;
}

.offers-list .job-offer {
  color: #59c1e5;
}

.partner-list li,
.projects-list li,
.offers-list li {
  text-align: center;
}

@media (max-width: 768px) {

  .partner-list li,
  .projects-list li,
  .offers-list li {
    padding: 0;
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {

  .partner-list li,
  .projects-list li,
  .offers-list li {
    width: 100%;
  }
}

@media (max-width: 992px) {

  .partner-list,
  .projects-list,
  .offers-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.linkinnov-service,
.linkinnov-service-wrapper,
.linkinnov-video,
.linkinnov-video-wrapper {
  background: no-repeat;
}

.linkinnov-service {
  position: relative;
  margin-top: 100px;
  z-index: 2;
  background-size: 4% auto;
  background-position-y: center;
  overflow-x: hidden;
}

.linkinnov-service .flex-container,
.linkinnov-service #user_form_gender,
.linkinnov-service .form-container form div,
.form-container form .linkinnov-service div {
  align-items: center;
  justify-content: flex-end;
}

.linkinnov-video-wrapper {
  background-position: right;
  background-size: contain;
  padding: 140px 0;
}

.linkinnov-service-wrapper {
  background-position: right;
  background-size: 20% auto;
  padding: 140px 0;
}

.linkinnov-service-wrapper section,
.linkinnov-video-wrapper section {
  position: relative;
  margin: 0 0 0 auto;
  max-width: initial;
}

.linkinnov-service-wrapper section h4,
.linkinnov-video-wrapper section h4 {
  font-weight: bold;
  letter-spacing: 2.8px;
  color: #59c1e5;
}

@media (max-width: 992px) {
  .linkinnov-service-wrapper {
    background-size: 0% 0%;
  }

  .linkinnov-video-wrapper {
    padding: 30px 0;
    background-size: 210px;
  }
}

.linkinnov-video {
  margin-top: 0;
  position: relative;
  z-index: 2;
  background-size: 220px auto;
  background-position-y: center;
  background-position-x: left;
  overflow-x: hidden;
}

.linkinnov-video section {
  text-align: center;
}

.linkinnov-video section h5 {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.linkinnov-video section .video-container>iframe {
  border: 8px solid #000;
  border-radius: 3px;
}

.service-item {
  margin: 10px;
  width: 30%;
  min-width: 120px;
}

@media (max-width: 992px) {
  .service-item {
    margin: 10px 0 20px 0;
    text-align: inherit;
    width: 100%;
  }
}

.service-item .square-title {
  border: 3px solid #a03ee6;
  border-radius: 2px;
  line-height: 1;
  text-align: center;
  height: 80px;
  display: flex;
}

@media (max-width: 480px) {
  .service-item .square-title {
    font-size: 0.9em;
  }
}

.service-item .square-title:not(.active) {
  border: 1px solid black;
}

.service-item b {
  font-size: 1.3em;
  font-weight: 650;
  margin: auto;
}

.service-item p {
  margin-top: 10px;
  max-width: fit-content;
}

@media (max-width: 992px) {
  .service-item p {
    margin: 10px 0 20px 0;
  }
}

.service {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 40%;
  justify-content: center;
}

.service div h3 {
  font-size: 3.125em;
  font-weight: bold;
  letter-spacing: -1.5px;
  cursor: inherit;
}

.service div:not(.active) h3 i {
  display: initial;
}

.service-item .square-title:hover {
  cursor: pointer;
}

.partnersList .map-global figure:not(.active) {
  opacity: 0.1;
}

.partnersList .map-global figure {
  opacity: 1;
}

.partnersList .map-idf figure:not(.active) {
  visibility: hidden;
  opacity: 0;
}

.partnersList .map-global,
.partnersList .map-idf {
  cursor: pointer;
}

.partnersList .map-idf figure {
  visibility: visible;
  max-width: 70%;
  margin: 10px auto -500px auto;
  opacity: 1;
  z-index: 2;
  position: relative;
  top: -800px;
  transition: visibility 0s, opacity 0.7s;
}

@media (max-width: 992px) {
  .partnersList .map-idf figure {
    margin: 10px auto -400px auto;
    position: absolute;
    top: -600px;
  }
}

.partnersList img {
  margin: auto;
  width: 80%;
}

.service i {
  margin-right: 25px;
  font-size: 0.7em;
}

@media (max-width: 1200px) {
  .service {
    max-width: 400px;
    margin: 0px;
  }
}

@media (max-width: 992px) {
  .service {
    max-width: 100%;
  }
}

.service-img figure {
  max-width: 840px;
}

.service-img figure:not(.active) {
  display: none;
}

@media (max-width: 992px) {
  .service-img figure {
    display: none;
  }
}

.back {
  padding-left: 170px;
}

.back .more {
  font-weight: bold;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.back .more .icon-chevron {
  display: inline-block;
  transform: rotate(180deg);
}

.purple-star {
  color: #a03ee6;
}

.mention {
  color: #979797;
  font-size: 0.875rem !important;
}

.invis {
  visibility: hidden;
}

.d-block {
  display: block !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.popin-action {
  cursor: pointer;
}

.popin-action.fix {
  display: flex;
  position: fixed;
  padding: 15 30;
  z-index: 20;
  bottom: 20px;
  right: 8%;
}

.popin-action.video-button {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  text-align: center;
  width: 230px;
}

.video-img {
  max-height: 49px;
  margin: 0 15px 15px 0;
}

.overlay {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.popin-container {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 6;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.popin-container.no-popin {
  display: flex;
  background: transparent;
}

.popin-container.no-popin .popin {
  display: block;
  background: transparent;
  box-shadow: none !important;
  -webkit-box-shadow: none;
}

.popin-container.auth-container {
  padding: 30px 10px;
  overflow: auto;
  cursor: auto;
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .popin-container.auth-container {
    padding: 10px;
  }
}

.popin-container .popin {
  display: none;
  position: relative;
  background: #fff url(images/login-bkg.png) 0 0 no-repeat;
  border-radius: 2px !important;
  box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4);
  max-width: 661px;
  padding: 22px 64px 40px !important;
  width: auto !important;
  font-family: Montserrat, sans-serif;
  margin: auto !important;
}

.popin-container .popin.login-popin {
  padding: 22px 136px 40px;
}

/* .popin-container .popin.registration-popin .btn-connexion:before {
  content: none !important;
} */

.popin-container .popin.legals-popin {
  padding: 22px 128px 40px !important;
  width: 661px !important;
}

@media (max-width: 768px) {
  .popin-container .popin.legals-popin {
    padding: 25px !important;
  }
}

.popin-container .popin.video-popin {
  padding: 0 !important;
  margin: 0 !important;
  width: 80% !important;
  position: relative;
  max-width: 100%;
}

.popin-container .popin.video-popin .popin-content {
  position: relative;
  padding-top: 56.25% !important;
  width: 100%;
  max-width: 100%;
}

.popin-container .popin.video-popin .popin-content .iframevideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.popin-container .popin h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
}

.popin-container .popin ul li {
  margin: 0;
  margin-bottom: 5px;
}

.popin-container .popin button:focus,
.popin-container .popin input:focus {
  outline: none;
}

.popin-container .popin .rowForm {
  position: relative;
  margin-bottom: 15px;
}

.popin-container .popin .rowForm.closer {
  margin-bottom: 5px;
}

.popin-container .popin .rowForm.single-input {
  padding: 0 72px;
}

.popin-container .popin .rowForm .checkbox-wrapper {
  display: inline-block;
  text-align: left;
  width: 100%;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox']:not(:checked),
.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox']:checked {
  position: absolute !important;
  margin: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  pointer-events: none;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox']+span:not(.lever) {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-height: 20px;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox']+span:not(.lever):before,
.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox']:not(.filled-in)+span:not(.lever):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border-radius: 1px;
  margin-top: 3px;
  transition: 0.2s;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in+span:not(.lever):before,
.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in+span:not(.lever):after {
  content: '';
  left: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in:not(:checked)+span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in+span:not(.lever):after {
  border-radius: 2px;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in+span:not(.lever):before,
.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in+span:not(.lever):after {
  content: '';
  left: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in:not(:checked)+span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid rgba(51, 51, 51, 0.3);
  top: 0;
  z-index: 0;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in:not(:checked)+span:not(.lever):after:hover {
  border: 1px solid #59c1e5 !important;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in:checked+span:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

.popin-container .popin .rowForm .checkbox-wrapper [type='checkbox'].filled-in:checked+span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #59c1e5;
  background-color: #59c1e5;
  z-index: 0;
}

.popin-container .popin .rowForm>span {
  display: inline-block;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

/* .popin-container .popin .rowForm .inpForm,
.popin-container .popin .rowForm .select-pictoAdd,
.popin-container .popin .rowForm .select-pictoAdd:hover {
  min-height: 40px;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-radius: 4px;
  padding: 0 20px;
  font-style: normal;
  position: relative;
  margin: 8px 0;
} */
/*
.popin-container .popin .rowForm .inpForm,
.popin-container .popin .rowForm .inpRedForm {
  background: none;
  font-size: 16px;
  height: 30px;
  width: 100%;
} */

/* .popin-container .popin .rowForm .inpForm {
  margin-top: 0;
  border-width: 1px !important;
  color: #333 !important;
  letter-spacing: 1px;
}

.popin-container .popin .rowForm .inpForm::-webkit-input-placeholder {
  letter-spacing: 0;
}

.popin-container .popin .rowForm .inpForm::-moz-placeholder {
  letter-spacing: 0;
}

.popin-container .popin .rowForm .inpForm:-ms-input-placeholder {
  letter-spacing: 0;
}

.popin-container .popin .rowForm .inpForm::-ms-input-placeholder {
  letter-spacing: 0;
}

.popin-container .popin .rowForm .inpForm::placeholder {
  letter-spacing: 0;
} */

.popin-container .popin .rowForm input:not([type='submit']),
.popin-container .popin .rowForm textarea {
  color: rgba(51, 51, 51, 0.6);
  font-size: 11px;
}

.popin-container .popin .rowForm input:not([type='submit']):hover,
.popin-container .popin .rowForm input:not([type='submit']):focus,
.popin-container .popin .rowForm textarea:hover,
.popin-container .popin .rowForm textarea:focus {
  border: 1px solid #59c1e5 !important;
}

.popin-container .popin .rowForm.pw {
  margin-bottom: 10px;
}

.popin-container .popin .rowForm.pw .inpForm[type='password'] {
  letter-spacing: 2px;
}

.popin-container .popin .rowForm.pw .show-mdp {
  position: absolute;
  background: transparent;
  border-style: none;
  padding: 10px;
  right: 0;
  top: 0;
}

.popin-container .popin .popin-title {
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 60px 10px;
  font-family: Montserrat, sans-serif;
  text-align: center;
}

.popin-container .popin .popin-title span {
  font-weight: 200;
}

.popin-container .popin button.btn {
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  border-radius: 26px;
  text-transform: uppercase;
  border-width: 0;
  background: transparent;
}

.popin-container .popin button.btn.clr-white {
  color: #fff;
}

.popin-container .popin button.btn.bg-primary {
  background: #7693fd;
}

.popin-container .popin button.btn.bkg-gradient-2 {
  background-image: linear-gradient(90deg, #1578fb, #17ead7) !important;
}

button.bkg-grey {
  background-color: #dedede;
  padding: 0 6%;
}

.popin-container .popin button.btn.bkg-gradient-3 {
  background-image: linear-gradient(90deg, #1f379a, #af42f0) !important;
}

.popin-container .popin button.btn.clr-cobalt {
  color: #59c1e5 !important;
}

.btn.btn-40.square-rounded.fnt-12.border-cobalt.clr-cobalt.b.noAnnouncePopUp {
  background-image: linear-gradient(90deg, #1578fb, #17ead7) !important;
  border: none !important;
  color: white !important;
}

.popin-container .popin button.btn.border-cobalt {
  border: 2px solid #59c1e5 !important;
}

.popin-container .popin button.btn.popup-close {
  position: relative;
}

.popin-container .popin .pwd-forgot {
  font-size: 11px !important;
  text-align: right;
  margin-bottom: 18px;
  line-height: 1.15;
  background: transparent;
  padding: 0;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  color: #858585;
}

.popin-container .popin .popin-content {
  max-width: 400px;
  margin: 0 auto;
}

.popin-container .popin .popin-content .popin-content {
  max-width: 100%;
  margin: 19px auto !important;
}

@media (max-width: 992px) {
  .popin-container .popin .popin-content {
    max-width: initial;
  }
}

.popin-container .popin [class^='user-'] {
  margin-bottom: 30px;
}

.popin-container .popin.active {
  display: block;
}

.popin-container .popin .popup-close {
  padding: 10px;
  right: 10px;
  top: 10px;
  position: absolute;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  background: transparent;
  border-style: none;
  z-index: 666;
}

.popin-container .popin .popup-close img {
  border-style: none;
  max-width: 100%;
}

.popin-container .popin label.label-form {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  font-size: 19px;
  margin: 0;
}

.popin-container .popin p {
  color: rgba(51, 51, 51, 0.6);
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
}

.popin-container .popin .flex-direction-mobile {
  align-items: center;
  flex-direction: row;
  display: flex;
}

.popin-container .popin .flex-direction-mobile>div {
  flex: 1;
  padding: 0 8px;
}

.popin-container .popin .flex-vertical {
  flex-direction: column !important;
  display: flex;
}

.popin-container .popin .flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popin-container .popin .blc-choice-container>div {
  position: relative;
}

.popin-container .popin .blc-choice-container input[type='checkbox'],
.popin-container .popin .blc-choice-container input[type='radio'] {
  opacity: 0;
  display: block;
  position: absolute !important;
  top: 0;
  left: 0;
  height: 40px;
  padding: 0;
  border: 2px solid rgba(51, 51, 51, 0.3);
  border-radius: 4px;
  font-size: 11px;
  width: 180px;
  margin: 5px 7.5px;
}

.popin-container .popin .blc-choice-container input[type='checkbox']:checked+.blc-choice,
.popin-container .popin .blc-choice-container input[type='radio']:checked+.blc-choice {
  border-color: #59c1e5;
  color: #59c1e5 !important;
}

.popin-container .popin .blc-choice-container .blc-choice {
  height: 40px;
  padding: 0 10px;
  border: 2px solid rgba(51, 51, 51, 0.3);
  border-radius: 4px;
  font-size: 11px;
  width: 180px;
  margin: 5px 7.5px;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popin-container .popin .blc-choice-container .blc-choice:hover {
  border-color: #59c1e5;
}

@media (max-width: 1200px) {
  .popin-container .popin {
    top: 15px;
  }
}

@media (max-width: 768px) {
  .popin-container .popin {
    top: 0;
    overflow-y: scroll;
    background-size: 15%;
    padding: 25px !important;
    height: auto;
  }

  .popin-container .popin .flex-direction-mobile,
  .popin-container .popin .blc-choice-container {
    flex-direction: column;
  }

  .popin-container .popin .popin-title {
    padding: 20px 10px;
  }

  .popin-container .popin .rowForm>div {
    min-width: 80%;
  }

  .popin-container .popin .rowForm.single-input {
    padding: 0;
  }

  .popin-container .popin .rowForm>button.btn {
    margin: 0 40px;
    width: calc(100% - 80px);
  }
}

.popin-container.active {
  display: flex;
}

.registration-popin {
  text-align: center;
}

.legals-popin {
  height: 90vh;
  overflow: scroll;
  box-sizing: border-box;
}

.form-title {
  margin-bottom: 15px;
}

.form-title label {
  display: inline-block;
}

.form-title p,
.form-title label {
  margin-bottom: 10px;
  font-size: 1.1875em;
  font-weight: bold;
  line-height: 1.53;
  letter-spacing: -0.7px;
  color: #000;
}

.form-title.required p:after,
.form-title.required label:after {
  color: #a03ee6;
  content: ' *';
}

#user_form_save,
#delete_user_form_save {
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 1px;
}

#user_form_save {
  background-image: linear-gradient(243deg, #af42f0, #1f379a);
  color: #fff;
  margin: 10px 0;
}

#delete_user_form_save {
  border: 0;
  padding: 0;
  margin-right: 0;
  text-decoration: underline;
  background-color: transparent;
}

#user_form_gender {
  justify-content: center;
}

#user_form_gender label {
  margin-right: 40px;
}

@media (max-width: 480px) {
  #user_form_gender label {
    margin: 10px 30px;
    text-align: left;
  }
}

@media (max-width: 992px) {
  #user_form_gender {
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  #user_form_gender {
    flex-direction: column;
  }

  #user_form_gender input[type='radio'] {
    top: 28px;
  }
}

#user_form div:first-of-type {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

@media (max-width: 992px) {
  #user_form label {
    text-align: left;
  }
}

.form-container {
  margin: 20px 0;
  border-top: 1px solid #c1c1c1;
  padding-top: 15px;
}

.form-container form div {
  /*justify-content: center;*/
  align-items: center;
  /*margin-bottom: -20px;*/
}

@media (max-width: 768px) {
  .form-container form div {
    margin-bottom: 30px;
  }
}

#delete_user_form_email {
  margin-right: 20px;
}

.alert {
  text-align: center;
}

.alert-success {
  color: #32cd32;
}

.alert-error {
  color: #dc143c;
}

.inverse div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.inverse label {
  display: inline !important;
}

.inverse label .terms {
  color: #000;
  text-decoration: underline;
}

#buorg.buorg {
  z-index: 111111;
  border-bottom: none;
  text-align: center;
  font: inherit;
  box-shadow: none;
  padding: 30px 10px;
  overflow: auto;
  cursor: auto;
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-animation: none;
  animation: none;
  display: flex;
}

#buorg.buorg .buorg-pad {
  line-height: inherit;
  display: block;
  position: relative;
  background: #fff url(images/login-bkg.png) 0 0 no-repeat;
  border-radius: 2px !important;
  box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: -4px -2px 31px -19px rgba(0, 0, 0, 0.4);
  max-width: 80%;
  padding: 22px !important;
  width: auto !important;
  font-family: Montserrat, sans-serif;
  margin: auto !important;
  background-size: 7%;
}

#buorg.buorg .buorg-pad .buorg-moremsg,
#buorg.buorg .buorg-pad .buorg-moremsg {
  display: inline-block;
}

#buorg.buorg #buorgpermanent {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  font-weight: inherit;
  background: transparent;
  white-space: nowrap;
  margin: 0;
  display: inline-block;
}

#buorg.buorg .buorg-buttons {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
}

#buorg.buorg .buorg-buttons a {
  display: block;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  padding: 15px 0;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  border-radius: 26px;
  text-transform: uppercase;
  border-width: 0;
  margin: 20px 5% 0;
  box-shadow: none;
  width: 35%;
}

#buorg.buorg .buorg-buttons a#buorgul {
  color: #fff;
  background: #7693fd;
  background-image: linear-gradient(90deg, #1f379a, #af42f0) !important;
}

#buorg.buorg .buorg-buttons a#buorgig {
  border: 2px solid #59c1e5 !important;
  color: #59c1e5 !important;
  background: transparent;
}

@media (max-width: 768px) {
  #buorg.buorg .buorg-pad {
    max-width: 95%;
  }

  #buorg.buorg .buorg-buttons {
    width: 100%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 420px) {
  .cookiesPopupBtns {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .cookiesPopupLogo {
    margin: 0 auto;
  }
}

.cookiesPopupLogo {
  padding: 0 7px;
}

.cookiesPopupBtns {
  margin: 10 auto;
}